import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';

export const ConnectionActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 9px;
`;

export const ConnectionActionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ButtonStyle = css`
  cursor: pointer;
  border: none;
  padding: 6px 15px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.medium};
  border-radius: 7px;
  white-space: nowrap;
`;

export const RowButton = styled.button`
  ${ButtonStyle}
  background: linear-gradient(264deg, rgba(0, 148, 121, 0.1) 0%, rgba(0, 24, 241, 0.1) 100%);
  color: #0018f1;

  &:disabled {
    background: linear-gradient(264deg, rgba(0, 148, 121, 0.05) 0%, rgba(0, 24, 241, 0.05) 100%);
    color: rgba(0, 24, 241, 0.3);
    cursor: auto;
  }
`;

export const StatusButton = styled.button<{ isRequest?: boolean }>`
  ${ButtonStyle}
  background: linear-gradient(264deg, rgba(127, 49, 255, 0.60) 0%, rgba(75, 3, 193, 0.60) 36.46%);
  color: #fff;
  ${({ isRequest }) => isRequest && 'background: rgba(0, 107, 131, 0.30);'}

  &:disabled {
    background: linear-gradient(264deg, rgba(127, 49, 255, 0.1) 0%, rgba(75, 3, 193, 0.1) 36.46%);
    ${({ isRequest }) => isRequest && 'background: rgba(0, 107, 131, 0.15);'}
    cursor: auto;
  }
`;

export const MutuallOrFollowingButton = styled.button<{}>`
  ${ButtonStyle}
  color: #FFF;
  background: rgba(0, 107, 131, 0.6);
  &:disabled {
    background: rgba(0, 107, 131, 0.15);
  }
`;

export const SendMessageBtn = styled.button`
  display: flex;
  align-items: center;
  text-align: right;
  color: #aa5c00;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: 1;

  border: none;
  border-radius: 7px;
  background: rgba(170, 92, 0, 0.1);
  padding: 6px 15px;

  cursor: pointer;
`;

export const CancelButton = styled.button`
  ${ButtonStyle}
  font-weight: ${FONTS.WEIGHTS.normal};
  background: linear-gradient(264deg, rgba(173, 0, 0, 0.9) 0%, rgba(206, 0, 0, 0.9) 100%);
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CloseIcon = styled(Image)`
  min-width: 14px;
  min-height: 14px;
  padding: 3px;
`;

export const RowRequestType = styled.div`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.medium};
  border-radius: 7px;
  background: rgba(226, 226, 226, 0.5);
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 6px 15px;
`;

export const RowDot = styled.div`
  color: #d4d4d4;
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.light};
  display: flex;
  align-items: center;
  justify-content: center;
`;
