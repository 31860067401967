import React, { useMemo, useState } from 'react';
import { useCreateReplie, useUser } from '@/common/hooks';
import { PostInputType, ReplyRepostComponent } from '../reply-repost/reply-repost.component';
import { ISubPost } from '@/common/types/post.type';

interface IReplyComponentProps {
  postId?: string;
  originalPostId: string;
  originalPost: ISubPost;
  userProfileId?: string;
  repliedPost?: ISubPost;
  onClickByPost?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  closeHandler: () => void;
  closeTread?: () => void;
  className?: string;
  isIdeaModal?: boolean;
  isIdeaPage?: boolean;
}

export const ReplyComponent = ({
  postId,
  originalPostId,
  originalPost,
  userProfileId,
  repliedPost,
  className,
  isIdeaModal,
  onClickByPost,
  closeHandler,
  isIdeaPage,
  closeTread
}: IReplyComponentProps) => {
  const [text, setText] = useState('');
  const [image, setImage] = useState<ArrayBuffer | string | null>(null);
  const user = useUser();

  const onSuccess = (isFirst: boolean) => {
    closeHandler();
    if (onClickByPost && isFirst) onClickByPost();
  };

  const { mutateAsync: createReply, isLoading } = useCreateReplie(onSuccess);

  const createHandler = () => {
    if ((image || text?.length) && !isLoading) {
      createReply({ postId, text, image, originalPostId });
    }
  };

  const placeholderText = useMemo(() => {
    if (!userProfileId) return '';

    if (userProfileId === originalPost.profileId && !repliedPost && !isIdeaModal) {
      return 'Reply to your post ..';
    }
    if (userProfileId !== originalPost.profileId && !repliedPost) {
      const firstName = (originalPost.newsFeed ? originalPost.newsFeed.newsSource : originalPost.profile)?.fullName?.split(' ')[0] || '';
      return `Reply to ${firstName}${firstName.endsWith('s') ? '’' : '’s'} post ..`;
    }
    if (!repliedPost || isIdeaModal) {
      return 'Reply here ..';
    }
    if (userProfileId === repliedPost?.profileId) {
      return 'Reply to your reply ..';
    }
    if (userProfileId !== repliedPost?.profileId) {
      const firstName = repliedPost?.profile?.fullName?.split(' ')[0];
      if (!firstName) {
        return 'Reply here ..';
      }
      return `Reply to ${firstName}${firstName.endsWith('s') ? '’' : '’s'} reply ..`;
    }
    return 'Reply here ..';
  }, [user, repliedPost, originalPost]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      createHandler();
    }
  };
  return (
    <ReplyRepostComponent
    isIdeaPage={isIdeaPage}
      placeholder={placeholderText}
      closeHandler={closeTread ?? closeHandler}
      createHandler={createHandler}
      repliedPost={repliedPost}
      text={text}
      image={image}
      setText={setText}
      setImage={setImage}
      onKeyDown={handleKeyDown}
      inputType={PostInputType.REPLY}
      isLoading={isLoading}
      className={className}
      isIdeaModal={isIdeaModal}
    />
  );
};
