import styled, { css } from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { BEAR_ANSWER, BULL_ANSWER, NEUTRAL_ANSWER } from '@/common/consts/idea.const';
import { SentimentType } from '@/common/components/question-result';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const SectionWrapper = styled(BoxAroundSection)<{ isAnon?: boolean }>`
  padding: 18px 20px 25px;
  background: transparent;
  width: 426px;
`;

const SubsectionWrapperStyle = css`
  width: 100%;
`;

export const SecurityCoverageWrapper = styled.div`
  ${SubsectionWrapperStyle}
`;

export const KeyDebatesWrapper = styled.div`
  ${SubsectionWrapperStyle}
  width: 350px;
`;

export const SubsectionHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SubsectionHeaderLeft = styled.div`
  width: 100%;
`;

export const SubsectionTitleWrapper = styled.div`
  display: flex;
  height: 30px;
  gap: 10px;
  align-items: center;
  img {
    object-fit: cover;
  }
`;

export const SubsectionTitle = styled.div<{ isAnon: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  letter-spacing: 0.32px;
  ${({ isAnon }) => isAnon && 'color: #7400CF;'}
`;

export const SubsectionDescription = styled.div<{ isAnon?: boolean }>`
  margin-top: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.extra_light};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: #515151;
  ${({ isAnon }) => isAnon && 'color: #c6c6c6;'}
  margin-left: 26px;
`;

export const AddButton = styled.button<{ isAnon?: boolean }>`
  color: #0018f1;
  background: #e9eaf5;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  ${({ isAnon }) => isAnon && 'background: #393939;color: #d6d6d6;'}
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-left: 26px;
`;

export const AddButtonIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

export const ListsWrapper = styled.div<{ isHidden: boolean }>`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  width: 350px;
  //padding-left: 26px;
`;

export interface IAnonProps {
  isAnon?: boolean;
}

export interface IIsAwaitingForResultProps {
  isAwaitingForResult?: boolean;
}

export const ListCaption = styled.div<IAnonProps & IIsAwaitingForResultProps>`
  padding: 4px 0;
  font-weight: ${FONTS.WEIGHTS.medium};
  letter-spacing: 0.7px;
  color: #7d7d7d;
  ${({ isAnon }) => isAnon && 'color: #9A9A9A;'}
  ${({ isAwaitingForResult }) => isAwaitingForResult && 'color: #929292;'}
`;

export const ListCaptionDark = styled.span<IAnonProps & IIsAwaitingForResultProps>`
  color: #2b2b2b;
  ${({ isAnon }) => isAnon && 'color: #cecece;'}
  ${({ isAwaitingForResult }) => isAwaitingForResult && 'color: #929292;'}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 350px;
  align-items: flex-start;
`;

export const ListDivider = styled.div`
  width: 100%;
  height: 0px;
`;

export const ListItemContainer = styled.div<{ isAwaitForResult: boolean }>`
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  width: 350px;
  ${({ isAwaitForResult }) => isAwaitForResult && 'cursor: auto;'}
`;

export const IdeaText = styled.div<{ answer: number }>`
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  ${({ answer }) => (answer === BEAR_ANSWER ? 'color: #4D0EB4;' : '')}
  ${({ answer }) => (answer === BULL_ANSWER ? 'color: #009289;' : '')};
`;

export const IdeasReasonsContainer = styled.div<{ answer: number }>`
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: ${FONTS.SIZES.xxl};
  ${({ answer }) => (answer === BEAR_ANSWER ? 'color: #4D0EB4;' : '')}
  ${({ answer }) => (answer === NEUTRAL_ANSWER ? 'color: #000;' : '')}
  ${({ answer }) => (answer === BULL_ANSWER ? 'color: #009289;' : '')}
`;

export const ReasonIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ReplyContainer = styled.div<{ isActive?: boolean; isAnon: boolean }>`
  display: flex;
  gap: 5px;
  min-width: 53px;
  width: 53px;
  align-items: center;
  & > span {
    line-height: 20px;
  }
  ${({ isActive, isAnon }) => (isAnon ? (isActive ? 'color: #B8B8B8;' : 'color: #989898;') : isActive ? 'color: #000;' : 'color: #AEAEAE;')}
`;

export const ReplyIcon = styled(Image)`
  width: 18px;
  height: 16px;
`;

export const ArrowImage = styled(Image)`
  width: 12px;
  height: 12px;
  margin-left: -10px;
`;

export const QuestionText = styled.div<{ type: SentimentType; isAnon: boolean }>`
  width: 265px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
  ${({ type, isAnon }) =>
    type === SentimentType.ALIGNED && !isAnon ? 'color: #7D7D7D;' : type === SentimentType.ALIGNED && isAnon ? 'color: #EAEAEA;' : ''};
  ${({ type, isAnon }) =>
    type === SentimentType.DISAGREED_VARIANT && !isAnon
      ? 'color: #4D0EB4;'
      : type === SentimentType.DISAGREED_VARIANT && isAnon
        ? 'color: #C598FF;'
        : ''};
  ${({ type, isAnon }) =>
    type === SentimentType.AGREED_VARIANT && !isAnon
      ? 'color: #009289;'
      : type === SentimentType.AGREED_VARIANT && isAnon
        ? 'color: #00BEB2;'
        : ''};

  ${({ type, isAnon }) =>
    type === SentimentType.AWAITING_FOR_RESULT && !isAnon
      ? 'color: #ABABAB;'
      : type === SentimentType.AWAITING_FOR_RESULT && isAnon
        ? 'color: #ABABAB;'
        : ''};
`;
