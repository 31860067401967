import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Loader } from '@/common/components/loader';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { NewsfeedRow } from '../newsfeed-row';
import { ReplyModalWrapper } from '@/common/components/profile/components/reply-modal';

import { useAllNewsfeeds, useFetchNewsfeeds } from '@/common/hooks';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import * as Styled from './newsfeed-list.styled';

interface IProps {
  userProfileId: string;
  quotePostHandler: (postId: string) => void;
  activeTab: NewsfeedNavigation;
  currentFilter: IFilters;
  scrollAfterSkip: () => void;
}

export const NewsfeedList: React.FC<IProps> = ({ userProfileId, quotePostHandler, activeTab, currentFilter, scrollAfterSkip }) => {
  // const { ref: loadingInViewRef, inView: isLoadingInView } = useInView({
  //   threshold: 0.1
  // });
  // const [isShowLoader, setIsShowLoader] = useState(true);
  // const { allPosts: posts, isLoading: isPostsLoading } = useAllNewsfeeds(userProfileId);
  // const { refetchList, fetchNextPageList, hasNextPageList, isFetchingNextPageList, isListLoading, isRefetching } = useFetchNewsfeeds(
  //   currentFilter,
  //   activeTab,
  //   userProfileId
  // );

  // React.useEffect(() => {
  //   refetchList();
  //   setIsShowLoader(true);
  // }, [currentFilter]);

  // React.useEffect(() => {
  //   if (!isPostsLoading && !isListLoading) {
  //     refetchList();
  //   }
  // }, []);

  // React.useEffect(() => {
  //   if (!isRefetching && isShowLoader) {
  //     setIsShowLoader(false);
  //   }
  // }, [isRefetching]);

  // React.useEffect(() => {
  //   if (!isLoadingInView) return;
  //   if (hasNextPageList) {
  //     fetchNextPageList();
  //   }
  // }, [isLoadingInView]);
  const { componentToView } = ReplyModalWrapper({});

  return (
    <Styled.NewsWrapper>
      {componentToView}
      <AnimatePresence mode="wait">
        {/* {!(isPostsLoading || isListLoading || (isRefetching && isShowLoader)) ? ( */}
        <motion.div
          transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Styled.News>
            {/* {posts?.length
                ? posts?.map((item, index) => (
                  <NewsfeedRow
                      key={`news-row-${item.id}`}
                      quotePostHandler={quotePostHandler}
                      userProfileId={userProfileId}
                      post={item}
                      isLast={!hasNextPageList && index === posts.length - 1}
                      activeTab={activeTab}
                      currentFilter={currentFilter}
                      scrollAfterSkip={scrollAfterSkip}
                    />
                  ))
                : 'Try tweaking the filters with the button above to see news articles here'} */}
            Coming soon ..
          </Styled.News>
        </motion.div>
        {/* ) : (
           <LoaderWrapper heightForMinus={238}>
             <Loader />
           </LoaderWrapper>
        )} */}
      </AnimatePresence>
      {/* <Styled.PaginationIntersection
        ref={loadingInViewRef}
        style={{ display: isFetchingNextPageList || isListLoading ? 'none' : 'block' }}
      /> */}
    </Styled.NewsWrapper>
  );
};
