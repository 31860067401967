import { useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { memoService, coverageService } from '../services';
import {
  FromTitleToText,
  IFilters,
  IMemo,
  IMemoDraft,
  IMemoPayload,
  IMemoState,
  IMemoThesis,
  IProcessLoadingStatus,
  TitleEnum
} from '../types/memo.type';
import { LIMIT, LIMIT_DRAFTS } from '../consts/memo.const';
import { getBase64 } from '../components/onboard/helpers';
import { initMemoState, initOptionalState, initProcessState } from '../components/memo/components/memo-add-form/memo-add-form.conts';

export const useMemoSector = () => {
  const { data, isLoading } = useQuery({
    refetchOnMount: true,
    queryKey: [QUERY_KEYS.MEMO],
    queryFn: () => memoService.getSectors(),
    onSuccess: async () => {}
  });
  return {
    data,
    isLoading
  };
};

export const useMemoRegion = () => {
  const { data: regions, isLoading: isRegionLoading } = useQuery({
    queryKey: [QUERY_KEYS.MEMO_REGIONS],
    queryFn: () => coverageService.getRegionsOnMemo()
  });

  return {
    regions,
    isRegionLoading
  };
};

export function useSubSectors(id: string) {
  return useQuery([QUERY_KEYS.SUB_SECTORS, id], () => coverageService.getSubSectors(id), {
    enabled: !!id
  });
}

export const useCreateMemo = () => {
  // const queryClient = useQueryClient();
  const createMemoMutation = useMutation({
    mutationFn: (memo: IMemoPayload) => memoService.createMemo(memo),
    onSuccess: (data) => {
      const memoId = data?.id;

      localStorage.setItem('memoId', memoId as string);
      // queryClient.setQueriesData([QUERY_KEYS.GET_MEMO], data);
    }
  });
  return {
    mutate: createMemoMutation.mutateAsync,
    isCreateLoading: createMemoMutation.isLoading
  };
};

export const useMemoLocalStorage = () => {
  const getOneMemo = useQuery({
    queryKey: [QUERY_KEYS.GET_MEMO],
    queryFn: () => memoService.getMemoLocalStorage(),
    onSuccess: async (data: void | IMemo) => {},
    keepPreviousData: false,
    cacheTime: 0
    // enabled: !!memoId
  });
  return {
    isLoading: getOneMemo.isLoading,
    isRefetching: getOneMemo.isRefetching,
    getOneMemo: getOneMemo.refetch,
    currentMemo: getOneMemo.data ?? ({} as IMemo),
    refetchMemo: getOneMemo.refetch
  };
};

export const useUpdateMemo = () => {
  const queryClient = useQueryClient();
  const useUpdateMemoMutation = useMutation(
    QUERY_KEYS.UPDATE_MEMO,
    async (data: IMemo) => {
      const id = localStorage.getItem('memoId');
      const updatedMemo = await memoService.updateMemo(data, id ?? '');
      return updatedMemo?.id ? updatedMemo : ({} as IMemo);
    },
    {
      onSuccess: (data: IMemo) => {
        queryClient.setQueriesData([QUERY_KEYS.GET_MEMO], data);
      }
    }
  );
  return useUpdateMemoMutation;
};

export const useGetMemoList = (currentFilter: IFilters) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch, isFetching } = useInfiniteQuery(
    [QUERY_KEYS.GET_MEMO_LIST, LIMIT, currentFilter],
    ({ pageParam = 0, signal }) =>
      memoService.getMemoList({
        page: pageParam + 1,
        limit: LIMIT,
        category: currentFilter.category,
        direction: currentFilter.direction,
        sectorId: currentFilter.sectorId,
        regionId: currentFilter.regionId,
        marketCap: currentFilter.marketCap,
        adtvCap: currentFilter.adtvCap,
        signal
      }),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length === lastPage?.maxPage) return;
        return allPages.length;
      },
      staleTime: 2000,
      refetchOnMount: true
    }
  );

  const memos = data?.pages.reduce((arr, curr) => arr.concat(curr?.memos ?? ([] as IMemo[])), [] as IMemo[]);

  const refetchIsLoading = isFetching && !isFetchingNextPage;

  return {
    memosList: memos,
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchIsLoading,
    refetchList: refetch
  };
};

export const useUserDrafts = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery(
    [QUERY_KEYS.GET_USER_DRAFTS],
    ({ pageParam = 0 }) => memoService.getUserDrafts({ page: pageParam + 1, limit: LIMIT_DRAFTS }),
    {
      getNextPageParam(lastPage, allPages) {
        if (allPages.length === lastPage?.maxPage) return;
        return allPages.length;
      }
    }
  );

  const drafts = data?.pages.reduce((arr, curr) => arr.concat(curr?.memos ?? ([] as IMemoDraft[])), [] as IMemoDraft[]);

  return {
    memosDrafts: drafts ?? [],
    hasNextPageDrafts: hasNextPage,
    isFetchingNextPageDrafts: isFetchingNextPage,
    isDraftLoading: isLoading,
    fetchNextPageDrafts: fetchNextPage,
    refetchDrafts: refetch
  };
};

export function useFilters(currentFilter: IFilters) {
  return useQuery([QUERY_KEYS.MEMOS_FILTERS, currentFilter], () => memoService.getFilters(currentFilter), {});
}

export const useMemosOnProfile = (profileId: string) =>
  useQuery([QUERY_KEYS.MEMOS_ON_PROFILE, profileId], () => memoService.getMemosOnProfile(profileId));

export const useRateMemo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [QUERY_KEYS.RATE_MEMO],
    async ({ memoId, rate }: { memoId: string; rate: number }) => memoService.rateMemo(memoId, rate),
    {
      onSuccess: (data) => {
        if (!data) return;
        queryClient.invalidateQueries([QUERY_KEYS.GET_MY_RATE, data.memoId]);
        queryClient.invalidateQueries([QUERY_KEYS.GET_MY_RATE_DATA, data.memoId]);
      }
    }
  );
};

export const useGetMyRate = (memoId: string) => useQuery([QUERY_KEYS.GET_MY_RATE, memoId], () => memoService.getMyRate(memoId));

export const useProcessMemoLink = () => useMutation((link: string) => memoService.processMemoLink(link));

type ProcessMemoDocumentProps = {
  onSuccess: () => void;
  onError: () => void;
};

export const useProcessMemoDocument = ({ onSuccess, onError }: ProcessMemoDocumentProps) => {
  const [data, setData] = useState<any>(null);
  const [memoState, setMemoState] = useState<IMemoState>(initMemoState);
  const [optionalState, setOptionalState] = useState<any>(initOptionalState);
  const [memoThesis, setMemoThesis] = useState<IMemoThesis[]>([]);
  const [processingStatus, setProcessingStatus] = useState<IProcessLoadingStatus>(initProcessState);
  const [securityName, setSecurityName] = useState('');
  const [isBlurred, setIsBlurred] = useState<boolean>(true);

  const { mutateAsync: processDocument } = useMutation(
    async ({ document, fileName }: { document: File; fileName?: string }) => {
      const response = await memoService.processMemoDocument(document, fileName);
      const documentUrl = (await getBase64(document))?.toString() ?? '';

      const setThesisByView = (view: string, text: string) => {
        setMemoThesis((prevState) => {
          const updatedState = [...prevState.map((it) => ({ ...it }))];
          const index = updatedState.findIndex((element) => element.title === view);
          if (index >= 0) {
            updatedState[index].text = text.toLowerCase() === 'empty response' ? '' : text;
          } else {
            updatedState.push({ sectionName: view, text: text.toLowerCase() === 'empty response' ? '' : text, imageUrls: [], title: view });
          }
          return updatedState;
        });
      };

      const checkObject = (result: any) => {
        if (result.thesisSummaryResponse) {
          const viewObject = FromTitleToText[TitleEnum.SUMMARY];
          const text = Object.values(JSON.parse(result.thesisSummaryResponse))
            .reduce<string>((prev: string, current: unknown) => {
              if ((current?.toString() ?? '').endsWith('\n\n')) {
                return `${prev ?? ''}${current ?? ''}`;
              }
              return `${prev ?? ''}${current ?? ''}\n\n`;
            }, '')
            ?.trim();
          setThesisByView(viewObject.view, text);
          setProcessingStatus((prev) => ({ ...prev, isSummaryProcessed: false }));
          setMemoState((prev) => ({
            ...prev,
            isProcessed: true,
            createdAt: result?.publishDate ? new Date(result?.publishDate) : undefined
          }));
        }
        if (result.variousResponse) {
          const object = result.variousResponse;
          setProcessingStatus((prev) => ({ ...prev, isBaseProcessed: false }));
          if (object.securityName) {
            setSecurityName(object.securityName);
          }
          setOptionalState((prev: any) => ({
            ...prev,
            ...(fileName ? { documentName: fileName, documentUrl } : {})
          }));
          setMemoState((prev) => ({
            ...prev,
            security: object.security?.toString() ?? '',
            direction: object.direction?.toString()?.toUpperCase() ?? '',
            priceTarget: object.priceTarget ?? 0
          }));
        }
        setData(result);
      };

      const reader = response.body?.getReader();
      const decoder = new TextDecoder('utf-8');
      let saveDecodedText = '';

      try {
        while (true && reader) {
          setIsBlurred(true);
          const { done, value } = await reader.read();
          if (done) break;
          try {
            saveDecodedText += decoder.decode(value, { stream: true });
            console.log(saveDecodedText, 'saveDecodedText');
            const result = JSON.parse(saveDecodedText);
            checkObject(result);
            saveDecodedText = '';
          } catch (err) {
            console.error(err);
          }
        }
      } catch {
        if (saveDecodedText.includes('Error streaming data - ')) {
          onError();
        }
      } finally {
        setIsBlurred(false);
      }
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      }
    }
  );

  return { processDocument, optionalState, memoState, isBlurred, securityName, memoThesis, data };
};

export const useCheckProcessStatus = () => useMutation((processId: string) => memoService.checkProcessStatus(processId));
