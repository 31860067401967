import React from 'react';
import Image from 'next/image';
import ShowPrev from '@styles/icons/blue-show-prev-icon.webp';
import * as Styled from './conversation-tread.styled';
import { IGetFiveProfilesFromTreadResponse } from '@/common/types/post.type';

interface IProps {
  userImages: IGetFiveProfilesFromTreadResponse[];
  countUserImages: number;
  noPadding?: boolean;
  isIdeaPageConversation?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isInModal?: boolean;
  isIdeaModal?: boolean;
}

export const ShowMorePrev: React.FC<IProps> = ({
  userImages,
  isIdeaModal,
  isIdeaPageConversation,
  noPadding,
  countUserImages,
  onClick,
  isInModal
}) => {
  const userImagesLength = userImages.length;
  const countNotShowImages = countUserImages - userImagesLength;

  return (
    <Styled.ShowMoreWrapper
      isIdeaPageConversation={isIdeaPageConversation}
      isIdeaModal={isIdeaModal}
      noPadding={noPadding}
      onClick={onClick}
      isInModal={isInModal}
    >
      <div>
        <Image src={ShowPrev} alt="Show Prev Icon" width={20} height={20} style={{ margin: '0 5px' }} />
        <Styled.ShowMoreParagraph>{userImagesLength ? 'Show more incl. replies from' : 'Show more'}</Styled.ShowMoreParagraph>
        {!!userImagesLength && (
          <Styled.ProfileIconsList>
            {userImages?.map((item, index) => (
              <Styled.ProfileItem
                key={`${item.id} ${index}`}
                imageUrl={item.imageUrl}
                profileId={item.id}
                isImageHidden={item.isImageHidden}
                borderRadius="50%"
                border="2px solid #fff"
              />
            ))}
          </Styled.ProfileIconsList>
        )}
        {countNotShowImages > 0 && (
          <p>
            + {countNotShowImages} {countNotShowImages > 1 ? 'others' : 'other'}
          </p>
        )}
      </div>
    </Styled.ShowMoreWrapper>
  );
};
