import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { CircularProgress, StackProps } from '@mui/material';
import * as Styled from './poll-answer-input.styled';
import { FlexComponent } from '@/common/components/flex';
import Checkmark from '@/styles/icons/ok-checkmark.svg';
import { EUnits, unitOptions } from '@/common/types/poll.types';
import { getMaxMinPollAnswer } from '@/common/components/create-modal/components/poll/poll.component';
import ArrowBottomRight from '@/styles/icons/arrow-bottom-right.svg';
import { RulesTitle, RulesTitleImage } from '../poll-vote/poll-vote.styled';

interface IProps {
  answer: number;
  setAnswer: Dispatch<SetStateAction<number | null | undefined>>;
  setShowRules?: Dispatch<SetStateAction<boolean>>;
  clickConfirmHandler: any;
  mt?: StackProps['mt'];
  unit?: EUnits;
  isDisabled?: boolean;
}

export const PollAnswerInputComponent = ({
  mt,
  unit,
  answer,
  setAnswer,
  setShowRules,
  clickConfirmHandler,
  isDisabled
}: IProps): ReactElement => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const unitValue = unitOptions?.find((el) => el.value === unit)?.label;
  const inputMinMax = getMaxMinPollAnswer(unit ?? '');

  const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isConfirmed) {
      setIsConfirmed(true);
    } else {
      clickConfirmHandler(event, answer);
    }
  };

  return (
    <FlexComponent gap="15px" alignItems="flex-start">
      <FlexComponent gap="10px" mt={mt}>
        <Styled.VoteInputContainer>
          <Styled.VoteInput
            min={inputMinMax.min}
            max={inputMinMax.max}
            type="number"
            placeholder="your estimate here"
            value={answer || ''}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              let { value } = e.target;
              if (value.includes('.')) {
                const [integerPart, decimalPart] = value.split('.');
                if (decimalPart.length > 3) {
                  value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                }
              }
              const numericValue = Number(value);
              if (numericValue >= inputMinMax.min && numericValue <= inputMinMax.max) {
                setAnswer(numericValue);
              }
            }}
          />
          <Styled.HiddenPlaceholder unitValue={unitValue}>Type your estimate here ..</Styled.HiddenPlaceholder>
        </Styled.VoteInputContainer>
        <Styled.VoteInputButton onClick={(event) => onClickButton(event)} isConfirm={isConfirmed} disabled={!answer || isDisabled}>
          <p>{isConfirmed ? 'confirm' : 'done'}</p>
          {isDisabled ? <CircularProgress size={12} color="inherit" /> : <Styled.VoteInputButtonIcon src={Checkmark} alt="check" />}
        </Styled.VoteInputButton>
      </FlexComponent>
      <div style={{ cursor: 'pointer' }}>
        <FlexComponent onClick={() => setShowRules && setShowRules((prev) => !prev)} mt="28px" gap="5px" alignItems="center">
          <RulesTitleImage src={ArrowBottomRight} alt="rules arrow" />
          <RulesTitle>view rules</RulesTitle>
        </FlexComponent>
      </div>
    </FlexComponent>
  );
};
