export const BUTTON_TEXT = {
  FOLLOWING: 'following',
  CONNECTED: 'connected',
  CONNECT: 'connect',
  MUTUAL_FOLLOWERS: 'mutual followers',
  CONNECTION_SENT: 'connection sent',
  FOLLOW_SENT: 'follow sent',
  FOLLOW_BACK: 'follow back',
  FOLLOW: 'follow'
};

export const IMAGE_ALT = {
  PAGINATION_ARROW: 'pagination arrow'
};

export const FOLLOW_TAKE = Number(process.env.FOLLOW_TAKE) || 20;
