import React from 'react';
import { useRouter } from 'next/router';
import { escapeRegExp } from 'lodash';
import * as Styled from './other-element-result.styled';
import { AlgoliaIndexes } from '../../search-page.const';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useSearchState } from '@/common/hooks/use-algolia';

interface IProp {
  text: string;
  itemType?: string;
  onClick: () => void;
  debateClick?: () => void;
  ticker?: string;
  searchText?: string;
}

export const HighlightedText = ({ text, searchText }: { text: string; searchText?: string }) => {
  const { pathname } = useRouter();
  if (!searchText) {
    return <span>{text}</span>;
  }

  const sanitizedSearchText = escapeRegExp(searchText);
  const parts = text.split(new RegExp(`(${sanitizedSearchText})`, 'gi'));

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          className={
            part.toLowerCase() === searchText.toLowerCase() ? (pathname === '/messages' ? 'highlightedMessage' : 'highlighted') : ''
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const OtherElementResult = ({ text, onClick, debateClick, ticker, searchText, itemType }: IProp) => {
  const { push } = useRouter();
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_, setSearchResult] = useSearchState();

  const clearData = () => {
    setSearchResult({});
  };

  const onItemClick = () => {
    clearData();
    if (itemType === AlgoliaIndexes.SECURITY) {
      push(
        {
          pathname: ROUTER_KEYS.SEARCH_PAGE,
          query: {
            type: AlgoliaIndexes.SECURITY,
            value: text,
            clickedId: ticker,
            clickedValue: text,
            isClickOnMention: true
          }
        },
        undefined,
        { shallow: true }
      );
    } else if (itemType === AlgoliaIndexes.KEY_DEBATES && debateClick) {
      debateClick();
    } else {
      onClick();
    }
  };

  return (
    <Styled.Wrapper onClick={onItemClick}>
      <Styled.TextWrapper>
        <span>
          <HighlightedText text={text} searchText={searchText} />
        </span>
        {!!ticker && <Styled.TickerWrapper>{itemType === 'privates' ? 'private' : ticker}</Styled.TickerWrapper>}
      </Styled.TextWrapper>
    </Styled.Wrapper>
  );
};
