import styled, { css } from 'styled-components';
import Image from 'next/image';
import { BEAR_ANSWER, BULL_ANSWER, NEUTRAL_ANSWER } from '@/common/consts/idea.const';
import { FONTS } from '@/theme';

export const ListItemContainer = styled.div<{ answer: number; isAnon: boolean }>`
  display: flex;
  gap: 20px;
  width: 350px;
  align-items: center;
  cursor: pointer;
  color: #000;
  ${({ answer, isAnon }) =>
    answer === NEUTRAL_ANSWER && !isAnon ? 'color: #000;' : answer === NEUTRAL_ANSWER && isAnon ? 'color: #EAEAEA;' : ''};
  ${({ answer, isAnon }) =>
    answer === BEAR_ANSWER && !isAnon ? 'color: #4D0EB4;' : answer === BEAR_ANSWER && isAnon ? 'color: #C598FF;' : ''};
  ${({ answer, isAnon }) =>
    answer === BULL_ANSWER && !isAnon ? 'color: #009289;' : answer === BULL_ANSWER && isAnon ? 'color: #00BEB2;' : ''};
`;

const TextStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  overflow: hidden;
`;

export const IdeaText = styled.div<{ isHorizon?: boolean; isFullWidth?: boolean }>`
  width: 280px;
  ${({ isFullWidth }) => isFullWidth && 'width: 310px;'}
  ${TextStyle}
  font-size: 14px;
  line-height: 14px;
  ${({ isHorizon }) => isHorizon && 'width: 95px;'}
  white-space: nowrap;
  overflow: visible;
  display: flex;
  & > span {
    height: 20px;
    padding: 2px 0;
  }
  & > p {
    margin: 2px 0 0 2px;
    font-size: 12px;
    line-height: 18px;
    ${({ isHorizon }) => isHorizon && 'line-height: 12px;padding: 2px 0 4px 0;'}
    @-moz-document url-prefix() {
      padding: 1px 0 5px 0;
    }
  }
`;

export const IdeasReasonsContainer = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 33px;
  ${({ isFullWidth }) => isFullWidth && 'width: 87px;'}
  & > span {
    line-height: 20px;
  }
  ${TextStyle}
`;

export const ReasonIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const ReplyContainer = styled.div<{ isActive?: boolean; isAnon: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 53px;
  width: 53px;
  & > span {
    line-height: 20px;
  }
  ${TextStyle}
  ${({ isActive, isAnon }) => (isAnon ? (isActive ? 'color: #B8B8B8;' : 'color: #989898;') : isActive ? 'color: #000;' : 'color: #AEAEAE;')}
`;

export const ReplyIcon = styled(Image)`
  width: 18px;
  height: 16px;
`;

export const ArrowImage = styled(Image)`
  width: 12px;
  height: 12px;
`;
