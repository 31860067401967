import styled, { AnyStyledComponent, css, keyframes } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';

const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export const SidebarContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 12;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

export const SidebarContainer = styled.div`
  position: relative;
  width: 196px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: 30px;
`;

export const RouteText = styled.div<{ isActive: boolean; isAnon: boolean }>`
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-top: 1px;
  margin-left: 30px;
  text-align: left;
  min-width: 95px;
  white-space: nowrap;
  color: ${({ isActive }) => (isActive ? 'black' : '#565656')};
`;

export const NotificAndMessagesCountContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 5px;
  align-items: center;
  gap: 4px;
`;

export const NotificAndMessagesCount = styled.div`
  color: #d92a2a;
  font-family: 'Roboto Flex';
  font-size: 12px;
  padding-right: 4px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  padding-top: 1px;
  margin-left: auto;
`;

export const SidebarBottomContainer = styled.div`
  width: 196px;
  margin-left: auto;
  height: fit-content;
  margin-right: 30px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  gap: 74px;
  align-items: start;
  position: relative;
`;

export const SidebarDot = styled.div<{ isAnon?: boolean }>`
  margin-left: auto;
  min-width: 24px;
  padding: 6px 3px 0 3px;
  color: #000;
  text-align: right;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  ${({ isAnon }) =>
    isAnon &&
    css`
      color: #9d21ff;
    `}
`;

export const SidebarDotBottom = styled.div<{ isAnon?: boolean }>`
  margin-top: 2px;
  margin-left: auto;
  min-width: 24px;
  margin-right: 3px;
  color: #000;
  text-align: right;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ isAnon }) => (isAnon ? '#9d21ff' : '#008FCC')};
`;

const ContainerStyle = css`
  width: 100%;
  height: 100%;
`;

export const RoutesContainer = styled.div`
  ${ContainerStyle}
  gap: 8px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
`;

const ButtonStyle = css`
  border: none;
  text-align: center;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.l};
  background: transparent;
  width: 196px;
  display: flex;
  padding: 6px 13px 10px 13px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const LabelItemOnHover = styled.div<{ isAnon?: boolean; isNewPost?: boolean }>`
  position: absolute;
  top: 5px;
  left: 40px;
  border-radius: 10px;
  background: rgba(245, 245, 245, 0.8);
  backdrop-filter: blur(5px);
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;
  padding: 10px;
  color: #616161;
  ${({ isNewPost }) => isNewPost && 'top: 28px;color: #000;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.xxl};
  letter-spacing: 0.64px;

  ${({ isAnon }) =>
    isAnon &&
    css`
      background: rgba(49, 49, 49, 0.8);
      color: #e8e8e8;
    `}
`;

export const RouteButton = styled.button<{ isActive?: boolean; marginBottom?: string }>`
  position: relative;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  gap: 10px;
  width: 196px;
  height: 36px;
  display: flex;
  padding: 6px 13px 10px 13px;
  gap: 10px;
  border-radius: 12px;

  background: inherit;
  border: none;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};

  ${({ isActive }) =>
    isActive &&
    css`
      background: #f8f8f8;
    `}
`;

export const NewItemCircle = styled.div`
  width: 6px;
  height: 6px;
  background: #d71e1e;
  position: absolute;
  top: 9px;
  left: -5px;
  border-radius: 50%;
`;

export const SidebarDivider = styled.div`
  width: 196px;
  margin-bottom: 11px;
  height: 1px;
  background-color: #f7f7f7;
`;

export const RouterDivider = styled.div<{ isAnon?: boolean }>`
  background: inherit;
`;

export const ModeLabel = styled(RouterDivider)<{ isCurrent?: boolean }>`
  ${({ isCurrent, isAnon }) => isAnon && isCurrent && 'color: #9D21FF;'}
  ${({ isCurrent, isAnon }) => isAnon && !isCurrent && 'color: #A0A0A0;'}
  ${({ isCurrent, isAnon }) => !isAnon && isCurrent && 'color: #008FCC;'}
  ${({ isCurrent, isAnon }) => !isAnon && !isCurrent && 'color: #A0A0A0;'}
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

export const LastRouterDivider = styled.div<{ isAnon: boolean }>`
  width: 60px;
  height: 10px;
  background: rgba(244, 244, 244, 0.75);
  ${({ isAnon }) => isAnon && 'background: rgba(49, 49, 49, 0.50);'}
`;

export const NewPostRouteButton = styled.button<{ isAnon?: boolean }>`
  margin-top: 42px;
  display: flex;
  padding: 6px 13px 10px 13px;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  color: #0062f5;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 12px;
  width: 196px;
  max-height: 36px;
  background: rgba(0, 98, 245, 0.06);
  cursor: pointer;
  border: none;
`;

export const NewPostText = styled.div<{ isAnon?: boolean }>`
  color: #0062f5;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export const Icon = styled(Image as AnyStyledComponent)<{ isActive?: boolean; isAnon: boolean; topPx?: number }>`
  position: absolute;
  top: ${({ topPx }) => (topPx ? `${11 + topPx}px` : '11px')};
  width: 14px;
  height: 14px;
  margin-left: 3px;
  margin-bottom: 1px;
  margin-right: 3px;
`;

export const CmdIcon = styled(Image)`
  width: 10px;
  height: 10px;
`;

export const KeyText = styled.p`
  color: #565656;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const KeyTextGradient = styled(KeyText)`
  color: #318aff;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const CmdIconGradient = styled(Image)`
  width: 10px;
  height: 10px;
`;

export const AnonActiveIcon = styled(Image as AnyStyledComponent)<{ isActive?: boolean; isAnon: boolean }>`
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-top: -15px;
  margin-bottom: -15px;
`;

export const Hidden = styled(Image)`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const NewPostIcon = styled(Image as AnyStyledComponent)`
  width: 20px;
  height: 20px;
  padding: 6px 4px 2px 4px;
  margin-right: 2px;
`;

export const NewPostIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 41, 187, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewMusingButton = styled.div`
  ${ContainerStyle}
  background: ${COLORS.sidebarNewMusingBackground};
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutButton = styled.div`
  background: ${COLORS.sidebarBackground};
  width: 38px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px -1px #dddddd;
  backdrop-filter: blur(2.5px);
  border-radius: 0px 7px 7px 0px;
`;

export const SlidingMessage = styled.div<{ top: number; left: number }>`
  border-radius: 50px;
  border: 1px solid #e4e4e4;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 6px 6px 30px 0px #ddd;
  backdrop-filter: blur(2.5px);
  padding: 12px 20px;
  display: flex;
  gap: 8px;
  position: absolute;
  align-items: flex-end;
  ${({ top, left }) => `top: ${top + 50}px; left: ${left}px;`}
  white-space: nowrap;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  outline: none;
`;

export const SlidingMessageComingSoon = styled.span`
  color: #007979;
  display: flex;
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  @-moz-document url-prefix() {
    line-height: ${FONTS.SIZES.m} !important;
  }
  align-items: flex-end;
  min-height: 0;
`;

export const SlidingMessageText = styled.span`
  color: #000;
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  align-items: flex-end;
  min-height: 0;
`;

export const ModeWrapper = styled.div<{ isAnon: boolean }>`
  height: fit-content;
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RealButton = styled.button`
  ${ButtonStyle}
  font-weight: ${FONTS.WEIGHTS.light};
  color: #0075a7;
  text-shadow: 2px 2px 4px rgba(0, 127, 167, 0.25);
  width: 84px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IModeSwapProps {
  isAnon?: boolean;
}

export const AnonButton = styled.button<IModeSwapProps>`
  ${ButtonStyle}
  font-weight: ${FONTS.WEIGHTS.light};
  color: #7900a4;
  text-shadow: 2px 2px 4px rgba(121, 0, 164, 0.2);
  width: 196px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnActiveButton = styled.button<{ isAnon?: boolean }>`
  ${ButtonStyle}
  position: relative;
  font-weight: ${FONTS.WEIGHTS.light};
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 13px;
  ${LabelItemOnHover} {
    top: 0px;
    left: 65px;
  }
  &:hover {
    ${LabelItemOnHover} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;

export const ActivateLabelWrapper = styled.div<{ isFlashing: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
  margin-left: 5px;

  & > * {
    color: #a0a0a0;
    text-align: center;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.s};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.m};
    ${({ isFlashing }) =>
      isFlashing
        ? css`
            animation: ${flash} 2s infinite;
          `
        : ''}
  }
`;

export const ActivateCircleWithLineIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  &::before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #a0a0a0;
    position: absolute;
    top: -20px;
  }
`;

export const ModeImg = styled(Image)`
  position: absolute;
  bottom: 12px;
  left: 14px;
  width: 14px;
  height: 14px;
`;

export const ModeImgWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

export const SidebarLogoStab = styled.div`
  width: 111px;
  height: 50px;
  background: #ededed;
`;

export const ButtonAnonBase = css`
  display: flex;
  position: relative;
  padding: 6px 13px 10px 13px;
  align-items: baseline;
  gap: 10px;
  width: 196px;
  height: 36px;
  border-radius: 12px;
  border: none;
  background: none;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;

  & > span {
    margin-top: 5px;
    margin-left: 30px;
  }
`;

export const ButtonAnon = styled.button<{ isAnon?: boolean }>`
  ${ButtonAnonBase}
  color: #565656;
  ${({ isAnon }) =>
    isAnon &&
    css`
      color: #7e1acc;
      background: rgba(157, 33, 255, 0.05);
    `}
`;

export const ButtonReal = styled.button<{ isAnon?: boolean }>`
  ${ButtonAnonBase}
  color: #565656;
  ${({ isAnon }) =>
    isAnon &&
    css`
      color: #0075a6;
      background: rgba(0, 143, 204, 0.05);
    `}
`;
