export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  CONNECTION_AND_FOLLOW_REQUESTS_SENT = 'CONNECTION_AND_FOLLOW_REQUESTS_SENT',
  CONNECTION_REQUEST_SENT = 'CONNECTION_REQUEST_SENT',
  CONNECTION_REQUEST_WAS_IGNORED = 'CONNECTION_REQUEST_WAS_IGNORED',
  CONNECTION_REQUEST_PENDING = 'CONNECTION_REQUEST_PENDING',
  FOLLOW_EACH_OTHER = 'FOLLOW_EACH_OTHER',
  OWN_FOLLOW = 'OWN_FOLLOW',
  USER_FOLLOW = 'USER_FOLLOW',
  FOLLOW_REQUEST_SENT = 'FOLLOW_REQUEST_SENT',
  FOLLOW_REQUEST_WAS_IGNORED = 'FOLLOW_REQUEST_WAS_IGNORED',
  FOLLOW_REQUEST_PENDING = 'FOLLOW_REQUEST_PENDING',
  UNASSOCIATED = 'UNASSOCIATED'
}

export interface IAssociationResponse {
  sourceUserId: string;
  targetUserId: string;
  connected: boolean;
  following: boolean;
  connectionRequestSent: boolean;
  followRequestSent: boolean;
  connectionRequestIgnored: boolean;
  followRequestIgnored: boolean;
  connectionRequestIgnoredTime: Date;
  followRequestIgnoredTime: Date;
}

export interface IConnectionsResponse {
  connection: IAssociationResponse;
  targetConnection: IAssociationResponse;
}

export interface ITargetIdPayload {
  targetId: string;
}

export interface IAnswerFollowRequestPayload {
  userId: string;
  accept: boolean;
  followBack?: boolean;
}

export interface IAnswerConnectionRequestPayload extends IAnswerFollowRequestPayload {
  allowToFollow?: boolean;
}
