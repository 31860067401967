import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const PostsWrapper = styled(BoxAroundSection)`
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  padding: 0px;
`;

export const HeaderWrapper = styled.div<{ isCurrent?: boolean }>`
  display: flex;
  width: 599px;
  /* padding: 0 69px 8px 64px; */
  padding-left: 12px;
  /* margin-left: ${({ isCurrent }) => (isCurrent ? '12px' : '28px')}; */
  align-items: center;
  height: 30px;
`;

export const ProfileIcon = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const Head = styled.h2<{ isAnon: boolean }>`
  margin-left: 15px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* ${({ isAnon }) => isAnon && 'color: #7400CF;'} */
`;

export const HeaderDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${COLORS.black};
  margin: 0 2px;
  border-radius: 50%;
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  gap: 15px;
`;

export const HeaderButton = styled.button<{ isActive: boolean; isAnon?: boolean }>`
  color: #7d7d7d;
  font-family: ${FAMILIES.robotoFlex};
  background: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  /* ${({ isActive, isAnon }) => isActive && !isAnon && 'color: #0084BD;'}
  ${({ isActive, isAnon }) => isActive && isAnon && 'color: #7400CF;'} */
  ${({ isActive }) => isActive && 'color: #000;'}
`;

export const AttentionIcon = styled(Image)`
  width: 18px;
  height: 18px;
`;

export const AttentionIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #fbfbfb;
  backdrop-filter: blur(2.5px);
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 62px;
  margin-top: 40px;
  padding-left: 1px;
  padding: 0 33px 0px 27px;
  margin-bottom: 14px;
  border-bottom: 1px solid transparent;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: ${COLORS.NEW_BORDER_COLOR};
    border: ${COLORS.NEW_BORDER};
  }
`;

export const Description = styled.div`
  color: #9e9e9e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.34px;
`;

export const AddNewButton = styled.button<{ isHome?: boolean }>`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(231, 231, 231, 0) 0%, #f5f5f5 100%);
  font-weight: ${WEIGHTS.light};
  font-size: ${({ isHome }) => (isHome ? SIZES.s : SIZES.m)};
  line-height: ${SIZES.m};
  cursor: pointer;
`;

export const SubHead = styled.div`
  position: sticky;
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: ${SIZES.l};
  line-height: ${SIZES.l};
  top: -${SIZES.xxl};
  padding: 2.5rem 0;
  margin: ${SIZES.xxl} 0;
  z-index: 100;
  background: linear-gradient(180deg, #fafafa 34.9%, rgba(250, 250, 250, 0.2) 100%);
`;

export const TrendingPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
`;

export const TrendingPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${SIZES.xxl};
`;

export const TrendingHeadWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  gap: 10px;
  font-family: ${FONTS.FAMILIES.ibmPlexSansHebrew};
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.s};
  letter-spacing: 0.6px;
`;

export const TrendingHeadTitle = styled.div`
  color: ${COLORS.trendingPostTitle};
`;

export const TrendingHeadSubtitle = styled.div`
  color: ${COLORS.trendingPostSubtitle};
`;

export const FeedTitle = styled.div`
  padding: 8px 0;
  margin: 50px 0;
  font-family: ${FONTS.FAMILIES.ibmPlexSansHebrew};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  letter-spacing: 0.6px;
  color: ${COLORS.sectionHomeTitle};
  text-shadow: 2px 2px 1px #e5e5e5;
`;

export const Posts = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-bottom: 9px;
  }
  & > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const Divider = styled.div<{ isAnon: boolean }>`
  width: calc(100%);
  background-color: #f5f5f5;
  ${({ isAnon }) => isAnon && 'background-color: #353535;'}
  height: 1px;
`;
export const NothingText = styled.div`
  margin-left: 27px;
  color: #868686;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${WEIGHTS.light};
  line-height: 20px;
`;

export const PaginationIntersection = styled.span`
  height: 1px;
  opacity: 0;
  width: 100%;
`;
