import React, { useState } from 'react';
import PostOption from '@styles/icons/post-option.svg';
import ViewDetailsArrow from '@styles/icons/view-details-debate-arrow.webp';
import HiddenDebateIcon from '@styles/icons/hidden-debate-icon.webp';
import DebateResultIcon from '@styles/icons/debate-result.svg';
import Image from 'next/image';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import * as Styled from './question-result.styled';
import { SentimentType } from './question-result.const';
import { getSentimentType } from '@/common/utils/sentiment';
import { IPostDebateResult } from '@/common/types/post.type';
import { useGetDebateDetails } from '@/common/hooks';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

interface IQuestionResultComponentProps {
  questionId?: string;
  ownerId?: string;
  isCurrentUserPost?: boolean;
  text?: string;
  type?: SentimentType;
  keyDebateResult?: IPostDebateResult;
  ownerFullName?: string;
  isHidden?: boolean;
  isAnon?: boolean;
  userName: string;
  userAnswer: number;
  allAnswer: number;
  isQuote?: boolean;
  isFaded?: boolean;
  optionHandler?: () => void;
  className?: string;
  onClickByPostText?: () => void;
}

export const QuestionResultComponent = ({
  questionId,
  ownerId,
  text,
  type,
  isCurrentUserPost,
  keyDebateResult,
  ownerFullName,
  isHidden,
  isAnon,
  userName,
  userAnswer,
  allAnswer,
  isQuote,
  isFaded,
  optionHandler,
  className,
  onClickByPostText
}: IQuestionResultComponentProps) => {
  const { push } = useRouter();
  const { mutateAsync, isLoading, data } = useGetDebateDetails();
  const [isClickOnViewDetails, setIsClickOnViewDetails] = useState(false);
  const min = Math.max(Math.min(userAnswer, allAnswer), 0);
  const max = Math.min(Math.max(userAnswer, allAnswer), 6);

  // const getUserName = () => userName?.split(' ').reduce((acc, val) => `${acc}${val[0]}.`, '') || '';
  const getUserName = () => {
    if (isCurrentUserPost) return 'You';
    return userName?.split(' ')[0];
  };

  const onClickByText = () => {
    if (onClickByPostText) {
      onClickByPostText();
    }
  };

  const onClickByViewDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsClickOnViewDetails((it) => !it);
    if (!data && !isLoading && questionId) {
      mutateAsync(questionId);
    }
  };

  const getResultView = () => {
    const isRightConsensus = !(min === userAnswer && min !== max);
    const isLeftConsensus = !(max === userAnswer && min !== max);
    return (
      <Styled.AnswerLineWrapper>
        <Styled.AnswerLineContainer>
          <Styled.AnswerLineLeftBg>
            <Styled.AnswerLineLeftBgStyle isFaded={isFaded} isQuote={isQuote} />
            <Styled.AnswerLineLength side="left">
              {min < 3.5 && (
                <Styled.AnswerLineLeft
                  isQuote={isQuote}
                  isFaded={isFaded}
                  left={((min - 1) / 2.5) * 100}
                  right={max >= 3.5 ? 0 : 100 - ((max - 1) / 2.5) * 100}
                  isAligned={type === SentimentType.ALIGNED}
                >
                  <Styled.AnswerLeftLineEnd
                    isQuote={isQuote}
                    isFaded={isFaded}
                    isConsensus={min !== userAnswer}
                    direction={min === userAnswer ? 'top' : 'bottom'}
                    side="left"
                    isAligned={type === SentimentType.ALIGNED}
                  >
                    {!isHidden && (
                      <Styled.AnswerLeftLineEndText
                        isQuote={isQuote}
                        isFaded={isFaded}
                        isConsensus={min !== userAnswer}
                        answer={min}
                        direction={min === userAnswer ? 'top' : 'bottom'}
                        type={type ?? getSentimentType(userAnswer, allAnswer)}
                      >
                        {min === userAnswer ? getUserName() : 'consensus'}
                      </Styled.AnswerLeftLineEndText>
                    )}
                  </Styled.AnswerLeftLineEnd>

                  {max <= 3.5 && (
                    <Styled.AnswerLeftLineEnd
                      isQuote={isQuote}
                      isFaded={isFaded}
                      isConsensus={isLeftConsensus}
                      direction={max === userAnswer && min !== max ? 'top' : 'bottom'}
                      side="right"
                      isAligned={type === SentimentType.ALIGNED}
                    >
                      {!isHidden && (
                        <Styled.AnswerLeftLineEndText
                          isQuote={isQuote}
                          isFaded={isFaded}
                          answer={max}
                          isConsensus={isLeftConsensus}
                          direction={max === userAnswer && min !== max ? 'top' : 'bottom'}
                          type={type ?? getSentimentType(userAnswer, allAnswer)}
                        >
                          {max === userAnswer && min !== max ? getUserName() : 'consensus'}
                        </Styled.AnswerLeftLineEndText>
                      )}
                    </Styled.AnswerLeftLineEnd>
                  )}
                </Styled.AnswerLineLeft>
              )}
            </Styled.AnswerLineLength>
          </Styled.AnswerLineLeftBg>
          <Styled.GreyDot />
          <Styled.AnswerLineRightBg>
            <Styled.AnswerLineRightBgStyle isFaded={isFaded} isQuote={isQuote} />
            <Styled.AnswerLineLength side="right">
              {max > 3.5 && (
                <Styled.AnswerLineRight
                  isQuote={isQuote}
                  isFaded={isFaded}
                  left={min <= 3.5 ? 0 : ((min - 3.5) / 2.5) * 100}
                  right={100 - ((max - 3.5) / 2.5) * 100}
                  isAligned={type === SentimentType.ALIGNED}
                >
                  <Styled.AnswerRightLineEnd
                    isQuote={isQuote}
                    isFaded={isFaded}
                    isConsensus={max !== userAnswer}
                    side="right"
                    direction={max === userAnswer ? 'top' : 'bottom'}
                    isAligned={type === SentimentType.ALIGNED}
                  >
                    {!isHidden && (
                      <Styled.AnswRightLineEndText
                        isQuote={isQuote}
                        isFaded={isFaded}
                        isConsensus={max !== userAnswer}
                        answer={max}
                        direction={max === userAnswer ? 'top' : 'bottom'}
                        type={type ?? getSentimentType(userAnswer, allAnswer)}
                      >
                        {max === userAnswer ? getUserName() : 'consensus'}
                      </Styled.AnswRightLineEndText>
                    )}
                  </Styled.AnswerRightLineEnd>

                  {min >= 3.5 && (
                    <Styled.AnswerRightLineEnd
                      isQuote={isQuote}
                      isFaded={isFaded}
                      isConsensus={isRightConsensus}
                      side="left"
                      direction={min === userAnswer && min !== max ? 'top' : 'bottom'}
                      isAligned={type === SentimentType.ALIGNED}
                    >
                      {!isHidden && (
                        <Styled.AnswRightLineEndText
                          isQuote={isQuote}
                          isFaded={isFaded}
                          isConsensus={isRightConsensus}
                          answer={min}
                          direction={min === userAnswer && min !== max ? 'top' : 'bottom'}
                          type={type ?? getSentimentType(userAnswer, allAnswer)}
                        >
                          {min === userAnswer && min !== max ? getUserName() : 'consensus'}
                        </Styled.AnswRightLineEndText>
                      )}
                    </Styled.AnswerRightLineEnd>
                  )}
                </Styled.AnswerLineRight>
              )}
            </Styled.AnswerLineLength>
          </Styled.AnswerLineRightBg>
        </Styled.AnswerLineContainer>
      </Styled.AnswerLineWrapper>
    );
  };

  const getDetailsTextView = () => {
    if (!keyDebateResult) return;
    return (
      <>
        <Styled.DetailsViewRowWrapper>
          <p>Of those you follow, compared to consensus:</p>
          <button type="button" onClick={onClickByViewDetails}>
            <Image
              src={ViewDetailsArrow}
              alt="View Details Arrow"
              width={8}
              height={8}
              style={{ transform: isClickOnViewDetails ? 'rotate(180deg)' : 'rotate(0)', marginRight: '5px' }}
            />
            {isClickOnViewDetails ? 'close details' : 'view details'}
          </button>
        </Styled.DetailsViewRowWrapper>
        <Styled.DetailsViewProcentage>
          <Styled.DetailsViewMoreNegativeProcentage>{keyDebateResult.moreNegative}%</Styled.DetailsViewMoreNegativeProcentage> are more
          negative <Styled.DetailsViewDot>•</Styled.DetailsViewDot>{' '}
          <Styled.DetailsViewAlignmentText>{keyDebateResult.aligned}% are aligned</Styled.DetailsViewAlignmentText>{' '}
          <Styled.DetailsViewDot>•</Styled.DetailsViewDot>{' '}
          <Styled.DetailsViewMorePositiveProcentage>{keyDebateResult.morePositive}%</Styled.DetailsViewMorePositiveProcentage> are more
          positive
        </Styled.DetailsViewProcentage>
      </>
    );
  };

  const onClickByCreator = () => {
    if (!ownerId) return;
    push(`${ROUTER_KEYS.PROFILE}?id=${ownerId}`);
  };

  return (
    <Styled.QuestionResultWrapper className={className}>
      {!!text && !!ownerFullName && (
        <Styled.ResultTitleWrapper isQuote={isQuote} isClicable={!!ownerId}>
          {!isQuote && (
            <Styled.DebateIcon>
              <Image src={DebateResultIcon} alt="Icon" width={18} height={18} />
            </Styled.DebateIcon>
          )}
          Debate Results
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickByCreator();
            }}
            type="button"
          >
            created by {ownerFullName}
          </button>
        </Styled.ResultTitleWrapper>
      )}
      {!!text && (
        <Styled.QuestionResultTitleContainer>
          <Styled.QuestionResultTitle
            isAnon={!!isAnon}
            isClickable={!!onClickByPostText}
            onClick={onClickByText}
            type={type ?? getSentimentType(userAnswer, allAnswer)}
            optionBtn={!!optionHandler}
            isQuote={isQuote}
            isFaded={isFaded}
          >
            {text}
          </Styled.QuestionResultTitle>
        </Styled.QuestionResultTitleContainer>
      )}

      {!!optionHandler && <Styled.OptionIcon src={PostOption} alt="option" />}
      {isHidden && (
        <Styled.HiddenAnswerBlockWrapper>
          <AnimatePresence>
            <Styled.DebateResultWrapper
              transition={{ ease: 'backInOut', delay: 2, duration: 1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {getResultView()}
              {getDetailsTextView()}
            </Styled.DebateResultWrapper>
          </AnimatePresence>
          <Styled.HiddenAnswerBlock>
            <Image src={HiddenDebateIcon} alt="Hidden Debate Icon" width={14} height={14} />
            <p>Debate results only available to those who voted</p>
          </Styled.HiddenAnswerBlock>
        </Styled.HiddenAnswerBlockWrapper>
      )}
      {!isHidden && getResultView()}

      {!!text && keyDebateResult && !isHidden && !isQuote && (
        <>
          {getDetailsTextView()}
          {isClickOnViewDetails && !!data && (
            <>
              <Styled.ViewDetailsTitle>Of those you follow, they voted as:</Styled.ViewDetailsTitle>
              <Styled.ViewDetailsItemWrapper isOtherText={data.moreStrongDisagree > data.strongDisagree.length}>
                <article>
                  <p>
                    strong
                    <br />
                    disagree
                  </p>
                </article>
                <div>
                  {data.strongDisagree.reverse().map((it, index) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.strongDisagree.length === 1}
                      index={data.strongDisagree.length - index}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.strongDisagree.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {data.moreStrongDisagree > data.strongDisagree.length && (
                    <p>+ others {data.moreStrongDisagree - data.strongDisagree.length}</p>
                  )}
                  {!data.strongDisagree.length && <p>no votes</p>}
                </div>
              </Styled.ViewDetailsItemWrapper>
              <Styled.ViewDetailsItemWrapper isOtherText={data.moreHighlyUnlikely > data.highlyUnlikely.length}>
                <article>
                  <p>
                    highly
                    <br />
                    unlikely
                  </p>
                </article>
                <div>
                  {data.highlyUnlikely.reverse().map((it, index) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.highlyUnlikely.length === 1}
                      index={data.highlyUnlikely.length - index}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.highlyUnlikely.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {data.moreHighlyUnlikely > data.highlyUnlikely.length && (
                    <p>+ others {data.moreHighlyUnlikely - data.highlyUnlikely.length}</p>
                  )}
                  {!data.highlyUnlikely.length && <p>no votes</p>}
                </div>
              </Styled.ViewDetailsItemWrapper>
              <Styled.ViewDetailsItemWrapper isOtherText={data.moreQuiteUnlikely > data.quiteUnlikely.length}>
                <article>
                  <p>
                    quite
                    <br />
                    unlikely
                  </p>
                </article>
                <div>
                  {data.quiteUnlikely.map((it, index) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.quiteUnlikely.length === 1}
                      index={data.quiteUnlikely.length - index}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.quiteUnlikely.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {data.moreQuiteUnlikely > data.quiteUnlikely.length && (
                    <p>+ others {data.moreQuiteUnlikely - data.quiteUnlikely.length}</p>
                  )}
                  {!data.quiteUnlikely.length && <p>no votes</p>}
                </div>
              </Styled.ViewDetailsItemWrapper>
              <Styled.ViewDetailsItemWrapper isReversed isOtherText={data.moreQuiteLikely > data.quiteLikely.length}>
                <div>
                  {data.moreQuiteLikely > data.quiteLikely.length && <p>{data.moreQuiteLikely - data.quiteLikely.length} others +</p>}
                  {data.quiteLikely.map((it) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.quiteLikely.length === 1}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.quiteLikely.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {!data.quiteLikely.length && <p>no votes</p>}
                </div>
                <article>
                  <p>
                    quite
                    <br />
                    likely
                  </p>
                </article>
              </Styled.ViewDetailsItemWrapper>
              <Styled.ViewDetailsItemWrapper isReversed isOtherText={data.moreHighlyLikely > data.highlyLikely.length}>
                <div>
                  {data.moreHighlyLikely > data.highlyLikely.length && <p>{data.moreHighlyLikely - data.highlyLikely.length} others +</p>}
                  {data.highlyLikely.map((it) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.highlyLikely.length === 1}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.highlyLikely.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {!data.highlyLikely.length && <p>no votes</p>}
                </div>
                <article>
                  <p>
                    highly
                    <br />
                    likely
                  </p>
                </article>
              </Styled.ViewDetailsItemWrapper>
              <Styled.ViewDetailsItemWrapper isReversed isOtherText={data.moreStrongLikely > data.strongLikely.length}>
                <div>
                  {data.moreStrongLikely > data.strongLikely.length && <p>{data.moreStrongLikely - data.strongLikely.length} others +</p>}
                  {data.strongLikely.map((it) => (
                    <Styled.UserImageComponentWrapper
                      isAlone={data.strongLikely.length === 1}
                      key={`${it.id}-list-item`}
                      borderRadius="50%"
                      border={data.strongLikely.length > 1 ? '2px solid #fff' : undefined}
                      imageUrl={it.imageUrl}
                      isImageHidden={it.isImageHidden}
                      profileId={it.id}
                    />
                  ))}
                  {!data.strongLikely.length && <p>no votes</p>}
                </div>
                <article>
                  <p>
                    strong
                    <br />
                    agree
                  </p>
                </article>
              </Styled.ViewDetailsItemWrapper>
            </>
          )}
        </>
      )}
    </Styled.QuestionResultWrapper>
  );
};
