import React from 'react';
import * as Styled from './submit-button.styled';

interface ISubmitButtonProps {
  isActive: boolean;
  children: any;
  onClick?: () => void;
  isPrimary?: boolean;
  isRealModal?: boolean;
  isLinked?: boolean;
}

export const SubmitButton = ({ isActive, children, onClick, isPrimary, isRealModal, isLinked }: ISubmitButtonProps) => (
  <Styled.SubmitButton
    $isPrimary={isPrimary}
    $isActive={isActive}
    disabled={!isActive}
    onClick={onClick}
    $isRealModal={isRealModal}
    $isLinked={isLinked}
  >
    {children}
  </Styled.SubmitButton>
);
