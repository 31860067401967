import React from 'react';
import ReplyActiveIcon from '@styles/icons/reply-active.webp';
import ReplyNotActiveIcon from '@styles/icons/reply-not-active.webp';
import ReplyActiveIconAnon from '@styles/icons/reply-active-anon.webp';
import ReplyNotActiveIconAnon from '@styles/icons/reply-not-active-anon.webp';
import { IdeaDTO } from '@/common/types';
import * as Styled from './idea-item-in-list.styled';
import ArrowRight from '@/styles/icons/arrow-right-new.webp';
import ArrowRightAnon from '@/styles/icons/arrow-right-new-anon.webp';
import { formatNumber } from '@/common/utils/post-statistics';
import { usePostStatistic } from '@/common/hooks';
import { replaceIfMoreThanMax } from '@/common/utils/max-length-output';

interface IProps {
  isAnon?: boolean;
  isFullWidth?: boolean;
  idea: IdeaDTO;
  answer: number;
  onClick?: () => void;
}

export const IdeaItemInListComponent = ({ isAnon, isFullWidth, idea, answer, onClick }: IProps) => {
  const { data: statistic } = usePostStatistic(idea.post);
  return (
    <Styled.ListItemContainer answer={answer} isAnon={!!isAnon} onClick={onClick}>
      <Styled.IdeaText isFullWidth={isFullWidth}>{replaceIfMoreThanMax(idea.text, isFullWidth ? 310 : 290, 14)}</Styled.IdeaText>

      {/* <Styled.IdeaText isFullWidth={isFullWidth} isHorizon>
        {!!idea.horizon && idea.horizon !== IdeaHorizon.SKIP ? (
          <>
            <span>{horizonText[idea.horizon]}</span> <p>months</p>
          </>
        ) : (
          ''
        )}
      </Styled.IdeaText>

      <Styled.IdeasReasonsContainer isFullWidth={isFullWidth}>
        {!!idea.reasons && !!idea.reasons.length && (
          <>
            {answer === BEAR_ANSWER && <Styled.ReasonIcon src={isAnon ? ReasonsBearAnon : ReasonsBear} alt="reasons" />}
            {answer === NEUTRAL_ANSWER && <Styled.ReasonIcon src={isAnon ? ReasonsNeutralAnon : ReasonsNeutral} alt="reasons" />}
            {answer === BULL_ANSWER && <Styled.ReasonIcon src={isAnon ? ReasonsBullAnon : ReasonsBull} alt="reasons" />}
            <span>
              {idea.reasons.length}
              {isFullWidth && ' reasons'}
            </span>
          </>
        )}
      </Styled.IdeasReasonsContainer> */}
      <Styled.ReplyContainer isAnon={!!isAnon} isActive={!!statistic?.replyCount}>
        {!statistic?.replyCount ? (
          <>
            <Styled.ReplyIcon src={isAnon ? ReplyNotActiveIconAnon : ReplyNotActiveIcon} alt="reply" />
            <span>reply</span>
          </>
        ) : (
          <>
            <Styled.ReplyIcon src={isAnon ? ReplyActiveIconAnon : ReplyActiveIcon} alt="reply" />
            <span>{formatNumber(statistic.replyCount)}</span>
          </>
        )}
      </Styled.ReplyContainer>
      <Styled.ArrowImage src={isAnon ? ArrowRightAnon : ArrowRight} alt="arrow" />
    </Styled.ListItemContainer>
  );
};
