import React, { useEffect, useMemo, useState } from 'react';
import ProfileWarning from '@styles/icons/arrow-right-warning.webp';
import ProfileCurrentRolesIcon from '@styles/icons/profile-current-roles.webp';
import ProfilePreviousRolesIcon from '@styles/icons/profile-previous-roles.webp';
import ProfileCurrentRolesIconAnon from '@styles/icons/profile-current-roles-anon.webp';
import ProfilePreviousRolesIconAnon from '@styles/icons/profile-previous-roles-anon.webp';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { AnimatePresence, motion } from 'framer-motion';
import {
  IEducationHistoryResponse,
  IEmployerHistoryResponse,
  UnfilledFields,
  WorkHistoryType,
  WrokHistoryResponse
} from '@/common/types/profile.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import * as Styled from './work-history.styled';
import { HistoryListItemComponent } from './history-list-item.component';
import { HidenSectionComponent } from '../hiden-section';
import { getUnfilledEducationProps, getUnfilledEmployerProps } from './work-history.utils';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { HeaderTitleUnderlineWrapper } from '@/common/components/page-wrapper/components/box-around-section';
import { getIsAnonUser } from '@/common/hooks';

interface IWorkHistoryComponentProps {
  isCurrent: boolean;
  isAnonProfile: boolean;
  isAnon?: boolean;
  history?: WrokHistoryResponse;
  isLoading: boolean;
}

interface IUnfilledHistoryFields {
  currentRoles: Record<string, UnfilledFields>;
  previousRoles: Record<string, UnfilledFields>;
}

export const WorkHistoryComponent = ({ history, isLoading, isCurrent, isAnonProfile, isAnon }: IWorkHistoryComponentProps) => {
  const { push } = useRouter();
  const [isHidden, setIsHidden] = useState(false);
  const [hiddenSectionText, setHiddenSectionText] = useState('Visible to connections only');
  useEffect(() => {
    if (!isLoading && history && history.isHidden) {
      setIsHidden(true);
    }

    if (!isLoading && history && !history.isHidden) {
      setIsHidden(false);
    }

    if (!isLoading && history && history.outputTextForHidden) {
      setHiddenSectionText(history.outputTextForHidden);
    }
  }, [history, isLoading]);

  const sortedHistory = useMemo(() => {
    if (!history) return history;
    const { currentRoles } = history;
    const sortedCurrentRoles = currentRoles.sort((a, b) => {
      if (a.isMain) return -1;
      if (b.isMain) return 1;
      return -1;
    });
    return { ...history, currentRoles: sortedCurrentRoles };
  }, [history]);

  const unfilledItems: IUnfilledHistoryFields = useMemo(() => {
    const result = { currentRoles: {}, previousRoles: {} };

    if (!isCurrent) return result;
    const getFields = (roles: (IEducationHistoryResponse | IEmployerHistoryResponse)[]) =>
      roles.reduce((data, role) => {
        let unfilledFields: any = {};
        if (role.type === WorkHistoryType.EMPLOYER) {
          unfilledFields = getUnfilledEmployerProps(role);
          if (role?.vertical === 'Publishing Sole Publisher') {
            delete unfilledFields.role;
          }
        } else if (role.type === WorkHistoryType.EDUCATION) {
          unfilledFields = getUnfilledEducationProps(role);
        }
        if (isAnonProfile) {
          delete unfilledFields.role;
          delete unfilledFields.startDate;
          delete unfilledFields.endDate;
        }
        if (!role.isPrevious) {
          delete unfilledFields.endDate;
        }

        return Object.keys(unfilledFields).length > 0 ? { ...data, [role.id]: unfilledFields } : data;
      }, {});

    result.currentRoles = getFields(sortedHistory?.currentRoles || []);
    result.previousRoles = getFields(sortedHistory?.previousRoles || []);

    return result;
  }, [sortedHistory]);

  const isAnonUser = getIsAnonUser();

  return (
    <>
      <AnimatePresence>
        {sortedHistory ? (
          (sortedHistory.currentRoles.length > 0 || (isHidden && !history?.isDoesntHaveCurrentRole)) && (
            <motion.div
              transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styled.WorkHistoryWrapper isAnon={isAnon}>
                <HeaderTitleUnderlineWrapper isAnon={isAnon}>
                  <Styled.WorkHistoryTitleWrapper>
                    <Styled.TitleHeaderWrapper>
                      <Image
                        src={isAnonUser ? ProfileCurrentRolesIconAnon : ProfileCurrentRolesIcon}
                        width={7}
                        height={16}
                        alt="profile-current-roles"
                        style={{ marginRight: '8.8px' }}
                      />
                      <Styled.WorkHistoryTitle isAnon={isAnonUser}>Current Roles</Styled.WorkHistoryTitle>
                    </Styled.TitleHeaderWrapper>
                    {Object.keys(unfilledItems.currentRoles).length > 0 && (
                      <Styled.CompleteFieldsBtn
                        onClick={() => push({ pathname: ROUTER_KEYS.EDIT_PROFILE, hash: `m${Object.keys(unfilledItems.currentRoles)[0]}` })}
                      >
                        complete missing fields
                        <Image width={12} height={12} src={ProfileWarning} alt="profile-warning" />
                      </Styled.CompleteFieldsBtn>
                    )}
                  </Styled.WorkHistoryTitleWrapper>
                </HeaderTitleUnderlineWrapper>

                <Styled.ListWrapper isHidden={isHidden}>
                  {isHidden ? (
                    <HidenSectionComponent text={hiddenSectionText} />
                  ) : (
                    sortedHistory.currentRoles.map((item, index) => (
                      <HistoryListItemComponent
                        key={`current-rolles-${item.id}-${index}`}
                        item={item}
                        unfilledFields={unfilledItems.currentRoles[item.id] || {}}
                        isCurrent={isCurrent}
                        isAnonProfile={isAnon}
                      />
                    ))
                  )}
                </Styled.ListWrapper>
              </Styled.WorkHistoryWrapper>
            </motion.div>
          )
        ) : (
          <Styled.WorkHistoryWrapper isAnon={isAnon}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </Styled.WorkHistoryWrapper>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isLoading && history ? (
          (history.previousRoles.length > 0 || (isHidden && !history.isDoesntHavePreviuseRole)) && (
            <motion.div
              transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styled.WorkHistoryWrapper isAnon={isAnon}>
                <HeaderTitleUnderlineWrapper isAnon={isAnon}>
                  <Styled.WorkHistoryTitleWrapper>
                    <Styled.TitleHeaderWrapper>
                      <Image
                        src={isAnonUser ? ProfilePreviousRolesIconAnon : ProfilePreviousRolesIcon}
                        width={16}
                        height={16}
                        alt="profile-previous-roles"
                      />
                      <Styled.WorkHistoryTitle isAnon={isAnonUser}>Previous Roles</Styled.WorkHistoryTitle>
                    </Styled.TitleHeaderWrapper>
                    {Object.keys(unfilledItems.previousRoles).length > 0 && (
                      <Styled.CompleteFieldsBtn
                        onClick={() =>
                          push({ pathname: ROUTER_KEYS.EDIT_PROFILE, hash: `m${Object.keys(unfilledItems.previousRoles)[0]}` })}
                      >
                        complete missing fields
                        <Image width={12} height={12} src={ProfileWarning} alt="profile-warning" />
                      </Styled.CompleteFieldsBtn>
                    )}
                  </Styled.WorkHistoryTitleWrapper>
                </HeaderTitleUnderlineWrapper>
                <Styled.ListWrapper isHidden={isHidden}>
                  {isHidden ? (
                    <HidenSectionComponent text={hiddenSectionText} />
                  ) : (
                    history.previousRoles.map((item, index) => (
                      <HistoryListItemComponent
                        isAnonProfile={isAnon}
                        key={`previoes-rolles-${item.id}-${index}`}
                        item={item}
                        unfilledFields={unfilledItems.previousRoles[item.id] || {}}
                        isCurrent={isCurrent}
                      />
                    ))
                  )}
                </Styled.ListWrapper>
              </Styled.WorkHistoryWrapper>
            </motion.div>
          )
        ) : (
          <Styled.WorkHistoryWrapper isAnon={isAnon}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </Styled.WorkHistoryWrapper>
        )}
      </AnimatePresence>
    </>
  );
};
