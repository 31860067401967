import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence } from 'framer-motion';
import {
  getIsAnonUser,
  useAdoratedPosts,
  useAllAdoratedPosts,
  useAllProfilePosts,
  useAllUserBookmarks,
  useDeletePost,
  useFetchBookmarks,
  useFetchProfilePosts,
  useSidebarWidth
} from '@/common/hooks';
import { Loader } from '@/common/components/loader';
import { PostRowComponent } from '../post-row/post-row.component';
import * as Styled from './posts.styled';
import { HidenSectionComponent } from '../hiden-section';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import AttentionIcon from '@/styles/icons/attention-circle.webp';
import { PostType, ProfilePostTabs } from '@/common/types/post.type';
import { useDialogs } from '@/common/hooks/use-direst-messages';
import { PostComponent } from '@/common/components/post';
import { BookmarkMessageItem } from '../bookmarks-adorations/components/bookmarks/message-item.component';
import { EBookmarkType } from '@/common/types/bookmark.types';
import { HeaderWithFilterWrapper } from '@/common/components/home-page/components/posts';
import { FlexComponent } from '@/common/components/flex';

interface IPostProps {
  isCurrent: boolean;
  profileName: string;
  profileId: string;
  userProfileId: string;
  quotePostHandler: (postId: string) => void;
  onClickByQuotePost: (postId: string, originalPostId: string) => void;
}

export const PostsComponent = ({ isCurrent, profileName, profileId, userProfileId, quotePostHandler, onClickByQuotePost }: IPostProps) => {
  const [postProfileTab, setPostProfileTab] = useState(ProfilePostTabs.POST);
  const postEndRef = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(false);
  const { ref: loadingInViewRef, inView: isLoadingInView } = useInView({
    threshold: 0.1
  });
  const { ref: bookmarksLoadingInViewRef, inView: isBookmarksLoadingInView } = useInView({
    threshold: 0.1
  });
  const { ref: loadingInViewRefLikes, inView: isLoadingInViewLikes } = useInView({
    threshold: 0.1
  });
  const sidebarWidth = useSidebarWidth();

  const { allPosts: posts } = useAllProfilePosts(profileId);
  const { fetchNextPageList, refetchList, isPostsHidden, hasNextPageList, isFetchingNextPageList, isListLoading } =
    useFetchProfilePosts(profileId);

  const { sb } = useDialogs();
  const { hasNextPage, isFetchingNextPage, isLoading, fetchNextPage, refetch } = useFetchBookmarks(profileId);
  const { allBookmarks, deleteBookmark } = useAllUserBookmarks(profileId);
  const {
    isLoading: isLoadingAdorations,
    hasNextPage: hasNextPageAdorations,
    fetchNextPage: fetchNextPageAdorations,
    isFetchingNextPage: isFetchingNextPageAdorations
  } = useAdoratedPosts(profileId);
  const { data: adorations } = useAllAdoratedPosts(profileId);
  const { mutateAsync: deletePost } = useDeletePost({
    profileId
  });

  useEffect(() => {
    if (!isCurrent) setPostProfileTab(ProfilePostTabs.POST);
  }, [isCurrent]);

  useEffect(() => {
    if (!isLoadingInView) return;
    if (hasNextPageList) {
      fetchNextPageList();
    }
  }, [isLoadingInView]);

  useEffect(() => {
    if (!isBookmarksLoadingInView) return;
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [isBookmarksLoadingInView]);

  useEffect(() => {
    if (hasNextPageAdorations && !isLoadingAdorations && !isLoadingInViewLikes && !isFetchingNextPageAdorations) {
      fetchNextPageAdorations();
    }
  }, [isLoadingInViewLikes]);

  useEffect(() => {
    if (isPostsHidden && !isListLoading) {
      setIsHidden(true);
    }
    if (!isPostsHidden && !isListLoading) {
      setIsHidden(false);
    }
  }, [isPostsHidden, isListLoading]);

  useEffect(() => {
    if (!isListLoading) {
      refetchList();
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const getClickHandler = (postId?: string, originalPostId?: string) => {
    if (!postId) return;
    return () => {
      onClickByQuotePost(originalPostId ?? postId, postId);
    };
  };

  const bookmarksCount = useMemo(
    () => allBookmarks?.filter((item) => item.type === EBookmarkType.MESSAGE || item.isBookmarked)?.length ?? 0,
    [allBookmarks]
  );

  const bookmarks = useMemo(
    () => allBookmarks?.filter((item) => item.type === EBookmarkType.MESSAGE || item.isBookmarked) ?? [],
    [allBookmarks]
  );

  const isAnon = getIsAnonUser();

  const isUserProfile = profileId === userProfileId;

  return (
    <Styled.PostsWrapper isAnon={isAnon}>
      <HeaderWithFilterWrapper pb={9} sidebarWidth={sidebarWidth!}>
        <Styled.HeaderWrapper isCurrent={isCurrent}>
          {/* <Styled.ProfileIcon src={isAnon ? ProfileIconAnon : ProfileIcon} alt="Profile" /> */}
          {!isCurrent && <Styled.Head isAnon={isAnon}>{`${profileName}’s Posts + Replies`}</Styled.Head>}
          {isCurrent && (
            <Styled.HeaderButtonWrapper>
              {Object.values(ProfilePostTabs).map((value) => (
                <Styled.HeaderButton
                  key={`click-button-key-${value}`}
                  onClick={() => setPostProfileTab(value)}
                  isActive={postProfileTab === value}
                  isAnon={isAnon}
                >
                  {value}
                </Styled.HeaderButton>
              ))}
            </Styled.HeaderButtonWrapper>
          )}
        </Styled.HeaderWrapper>
      </HeaderWithFilterWrapper>
      <Styled.DescriptionWrapper>
        <FlexComponent gap="10px" alignItems="center">
          <Styled.AttentionIconBox>
            <Styled.AttentionIcon src={AttentionIcon} alt="Attention" />
          </Styled.AttentionIconBox>
          <Styled.Description>
            {postProfileTab === ProfilePostTabs.POST ? (
              <>
                Everything should be viewed as personal opinions only which do not reflect investments
                <br /> or views of the member’s employer(s); nothing should be viewed as investment advice
              </>
            ) : (
              <>Visible only to you</>
            )}
          </Styled.Description>
        </FlexComponent>
      </Styled.DescriptionWrapper>
      {isHidden ? (
        <HidenSectionComponent text="Visible to followers only" />
      ) : (
        <>
          {postProfileTab === ProfilePostTabs.POST && (
            <AnimatePresence>
              {!(isListLoading && !isFetchingNextPageList) ? (
                <Styled.Posts
                  transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {posts?.map((item, index) => (
                    <PostRowComponent
                      key={`post-row${item.id}`}
                      userProfileId={userProfileId ?? undefined}
                      profileId={profileId}
                      quotePostHandler={quotePostHandler}
                      post={item}
                      openReplyModal={onClickByQuotePost}
                      isAnotherUserProfile={profileId !== userProfileId}
                      isLast={!hasNextPageList && index === posts.length - 1}
                      isProfile
                    />
                  ))}
                  {!posts?.length && (
                    <Styled.NothingText>
                      {isUserProfile ? 'Click the + icon in the toolbar to make a post' : 'No posts yet'}
                    </Styled.NothingText>
                  )}
                  <Styled.PaginationIntersection
                    ref={loadingInViewRef}
                    style={{ display: isFetchingNextPageList || isListLoading ? 'none' : 'block' }}
                  />
                  <span ref={postEndRef} />
                </Styled.Posts>
              ) : (
                <LoaderWrapper heightForMinus={238}>
                  <Loader />
                </LoaderWrapper>
              )}
              {isFetchingNextPageList && (
                <div style={{ marginTop: '25px' }}>
                  <Loader />
                </div>
              )}
            </AnimatePresence>
          )}

          {postProfileTab === ProfilePostTabs.BOOKMARK && (
            <AnimatePresence>
              {!isLoading ? (
                <Styled.Posts
                  transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {!!bookmarks?.length &&
                    bookmarks.map((bookmark, index) => {
                      const hasNextBookmark = bookmarksCount - 1 > index;
                      return bookmark.type === EBookmarkType.MESSAGE ? (
                        <React.Fragment key={`${bookmark.messageId}messages`}>
                          <BookmarkMessageItem bookmark={bookmark} sb={sb} userProfileId={userProfileId} deleteBookmark={deleteBookmark} />
                          {hasNextBookmark && <Styled.Divider isAnon={false} />}
                        </React.Fragment>
                      ) : (
                        bookmark.isBookmarked && (
                          <React.Fragment key={`bookmarks-${bookmark.id}`}>
                            {bookmark.originalPostId ? (
                              <div style={{ width: '540px', marginLeft: '27px' }}>
                                <PostComponent
                                  deletePost={deletePost}
                                  post={bookmark}
                                  parentPost={bookmark?.originalPost?.insight ? bookmark?.originalPost : undefined}
                                  postStatus={{ isInOwnTread: false }}
                                  userProfileId={userProfileId}
                                  quotePostHandler={quotePostHandler}
                                  onClickByPost={getClickHandler(bookmark.id, bookmark.originalPostId)}
                                  openReply={getClickHandler(bookmark.id, bookmark.originalPostId)}
                                />
                                {hasNextBookmark && <Styled.Divider isAnon={false} />}
                              </div>
                            ) : (
                              <PostRowComponent
                                onPostClick={getClickHandler(bookmark.id, bookmark.originalPostId)}
                                post={bookmark}
                                key={`bookmarks-${bookmark.id}`}
                                profileId={userProfileId}
                                quotePostHandler={quotePostHandler}
                                openReplyModal={getClickHandler(bookmark.id, bookmark.originalPostId)}
                                userProfileId={userProfileId}
                                isAnotherUserProfile={profileId !== userProfileId}
                                isLast={!hasNextBookmark}
                                isTreadHidden
                                isInBookmarkSection
                              />
                            )}
                          </React.Fragment>
                        )
                      );
                    })}
                  {!allBookmarks?.filter((it) => it.type === EBookmarkType.MESSAGE || !it.deletedAt)?.length && (
                    <Styled.NothingText>Click on the bookmark icon to save posts and replies.</Styled.NothingText>
                  )}
                  <Styled.PaginationIntersection
                    ref={bookmarksLoadingInViewRef}
                    style={{ display: isFetchingNextPage || isLoading ? 'none' : 'block' }}
                  />
                  <span ref={postEndRef} />
                </Styled.Posts>
              ) : (
                <LoaderWrapper heightForMinus={238}>
                  <Loader />
                </LoaderWrapper>
              )}
              {isFetchingNextPage && (
                <div style={{ marginTop: '25px' }}>
                  <Loader />
                </div>
              )}
            </AnimatePresence>
          )}

          {postProfileTab === ProfilePostTabs.LIKES && (
            <AnimatePresence>
              {!isLoadingAdorations ? (
                <Styled.Posts
                  transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {adorations?.map((post, i) =>
                    post.originalPostId ? (
                      <React.Fragment key={`addorations-${post.id}`}>
                        <div style={{ width: '540px', marginLeft: '27px' }}>
                          <PostComponent
                            deletePost={deletePost}
                            post={post}
                            parentPost={post?.originalPost?.insight ? post?.originalPost : undefined}
                            postStatus={{ isInOwnTread: false }}
                            userProfileId={profileId}
                            quotePostHandler={quotePostHandler}
                            onClickByPost={getClickHandler(post.id, post.originalPostId)}
                            openReply={getClickHandler(post.id, post.originalPostId)}
                            postType={PostType.Quote}
                          />
                        </div>
                        {adorations.length - 1 > i && <Styled.Divider isAnon={false} />}
                      </React.Fragment>
                    ) : (
                      <PostRowComponent
                        onPostClick={getClickHandler(post.id, post.originalPostId)}
                        post={post}
                        key={`adorations-${post.id}`}
                        profileId={profileId}
                        quotePostHandler={quotePostHandler}
                        isAnotherUserProfile={profileId !== userProfileId}
                        openReplyModal={getClickHandler(post.id, post.originalPostId)}
                        userProfileId={profileId}
                        isLast={adorations.length - 1 === i}
                        isTreadHidden
                        isInBookmarkSection
                      />
                    )
                  )}
                  {!adorations?.length && (
                    <Styled.NothingText>Click on the applause icon or heart icon to like posts and replies.</Styled.NothingText>
                  )}
                  <Styled.PaginationIntersection
                    ref={loadingInViewRefLikes}
                    style={{ display: isFetchingNextPageAdorations || isLoadingAdorations ? 'none' : 'block' }}
                  />
                </Styled.Posts>
              ) : (
                <LoaderWrapper heightForMinus={238}>
                  <Loader />
                </LoaderWrapper>
              )}
              {isFetchingNextPageAdorations && (
                <div style={{ marginTop: '25px' }}>
                  <Loader />
                </div>
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </Styled.PostsWrapper>
  );
};
