import styled from 'styled-components';
import { COLORS } from '@/theme';

export const LeftColumnWrapper = styled.div`
  position: relative;
  padding: 0;
  min-width: 600px;
  max-width: 600px;
  height: 100vh;

  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  background-color: ${COLORS.white};

  &::-webkit-scrollbar {
    display: none;
  }
`;
