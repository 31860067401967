import React, { useEffect, useState } from 'react';
import ConnectionCloseIcon from '@styles/icons/connection-close-icon.svg';
import {
  useAnswerConnectionRequest,
  useAnswerFollowRequest,
  useCachedConnection,
  useCancelConnectionRequest,
  useCancelFollowRequest,
  useCreateConnection,
  useCreateFollow,
  useDisconnect,
  useProfileVisibilitySettings,
  useUnfollow
} from '@/common/hooks';
import { ConnectionStatus } from '@/common/types/connection.type';
import { getConnectionStatus } from '@/common/utils/connection-status';
import * as Styled from './connection-actions.styled';

interface IConnectionActionsComponentProps {
  currentUserId: string;
  userId: string;
  isCurrentOrVisitedUserAnon: boolean;
  className?: string;
  handleSendMsgBtn: () => void;
}

export const ConnectionActionsComponent = ({
  currentUserId,
  userId,
  isCurrentOrVisitedUserAnon,
  className,
  handleSendMsgBtn
}: IConnectionActionsComponentProps) => {
  const [status, setStatus] = useState<ConnectionStatus | null>(null);
  const [isStatusClicked, setIsStatusClicked] = useState(false);
  const [isRequestClicked, setIsRequestClicked] = useState(false);

  const { data: ownConnection } = useCachedConnection(currentUserId, userId);
  const { data: userConnection } = useCachedConnection(userId, currentUserId);

  const { mutateAsync: createConnect } = useCreateConnection();
  const { mutateAsync: disconnect } = useDisconnect();
  const { mutateAsync: answerConnectionRequest } = useAnswerConnectionRequest();
  const { mutateAsync: cancelConnectionRequest } = useCancelConnectionRequest();

  const { mutateAsync: createFollow } = useCreateFollow();
  const { mutateAsync: unfollow } = useUnfollow();
  const { mutateAsync: answerFollowRequest } = useAnswerFollowRequest();
  const { mutateAsync: cancelFollowRequest } = useCancelFollowRequest();

  const { data: profileVisibility } = useProfileVisibilitySettings();

  useEffect(() => {
    setStatus(getConnectionStatus(ownConnection, userConnection));
  }, [userConnection, ownConnection]);

  useEffect(() => {
    setIsStatusClicked(false);
    setIsRequestClicked(false);
  }, [status]);

  const connectHandler = () => {
    createConnect({ targetId: userId });
  };

  const disconnectHandler = () => {
    disconnect({ targetId: userId });
  };

  const cancelConnectionRequestHandler = () => {
    cancelConnectionRequest({ targetId: userId });
  };

  const answerConnectionRequestHandler = (accept: boolean, allowToFollow?: boolean) => {
    answerConnectionRequest({ accept, userId, ...(allowToFollow && { allowToFollow }) });
  };

  const followHandler = () => {
    createFollow({ targetId: userId });
  };

  const unfollowHandler = () => {
    unfollow({ targetId: userId });
  };

  const cancelFollowRequestHandler = () => {
    cancelFollowRequest({ targetId: userId });
  };

  const answerFollowRequestHandler = (accept: boolean, followBack?: boolean) => {
    answerFollowRequest({ accept, userId, followBack });

    // if (followBack) {
    //   followHandler();
    // }
  };

  const closeStatusIconHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsStatusClicked(false);
  };

  const closeRequestIconHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsRequestClicked(false);
  };

  const getFollowStatusComponent = () => {
    // MUTUAL FOLLOWERS OR OWN FOLLOW
    if (ownConnection && ownConnection.following) {
      return (
        <>
          {!isStatusClicked && (
            <Styled.MutuallOrFollowingButton
              onClick={() => setIsStatusClicked((prev) => !prev)}
              disabled={isRequestClicked || isStatusClicked}
            >
              {userConnection?.following ? 'mutual followers' : 'following'}
            </Styled.MutuallOrFollowingButton>
          )}
          {isStatusClicked && (
            <Styled.CancelButton
              onClick={() => {
                unfollowHandler();
                setIsStatusClicked((prev) => !prev);
              }}
            >
              confirm unfollow <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeStatusIconHandler} />
            </Styled.CancelButton>
          )}
        </>
      );
    }

    // NO FOLLOW
    if (ownConnection && !ownConnection.following) {
      return (
        <Styled.RowButton onClick={followHandler} disabled={!!ownConnection?.followRequestIgnored}>
          {userConnection?.following ? 'follow back' : 'follow'}
        </Styled.RowButton>
      );
    }
  };

  return (
    <Styled.ConnectionActionsWrapper className={className}>
      {status === ConnectionStatus.CONNECTION_REQUEST_PENDING && (
        <>
          <Styled.ConnectionActionsRow>
            {getFollowStatusComponent()}
            <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
              message
            </Styled.SendMessageBtn>
          </Styled.ConnectionActionsRow>
          <Styled.ConnectionActionsRow>
            <Styled.RowRequestType>connection requested</Styled.RowRequestType>
            <Styled.RowButton onClick={() => answerConnectionRequestHandler(true)}>accept</Styled.RowButton>
            <Styled.RowButton onClick={() => answerConnectionRequestHandler(false)}>ignore</Styled.RowButton>
            {!!profileVisibility && !profileVisibility.whoCanFollow && (
              <Styled.RowButton onClick={() => answerConnectionRequestHandler(false, true)}>ignore but allow follow</Styled.RowButton>
            )}
          </Styled.ConnectionActionsRow>
        </>
      )}

      {status === ConnectionStatus.FOLLOW_REQUEST_PENDING && (
        <>
          <Styled.ConnectionActionsRow>
            {!ownConnection?.connectionRequestSent && !isCurrentOrVisitedUserAnon && (
              <Styled.RowButton onClick={connectHandler} disabled={!!ownConnection?.connectionRequestIgnored}>
                connect
              </Styled.RowButton>
            )}

            {!!ownConnection?.connectionRequestSent && !isCurrentOrVisitedUserAnon && (
              <>
                {!isRequestClicked && (
                  <Styled.StatusButton
                    onClick={() => setIsRequestClicked((prev) => !prev)}
                    isRequest
                    disabled={isStatusClicked || isRequestClicked}
                  >
                    connection requested
                  </Styled.StatusButton>
                )}
                {isRequestClicked && (
                  <Styled.CancelButton
                    onClick={() => {
                      cancelConnectionRequestHandler();
                      setIsRequestClicked((prev) => !prev);
                    }}
                  >
                    cancel connection request <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeRequestIconHandler} />
                  </Styled.CancelButton>
                )}
              </>
            )}
            <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
              message
            </Styled.SendMessageBtn>
          </Styled.ConnectionActionsRow>
          <Styled.ConnectionActionsRow>
            <Styled.RowRequestType>follow requested</Styled.RowRequestType>
            <Styled.RowButton onClick={() => answerFollowRequestHandler(true)}>accept</Styled.RowButton>
            {!ownConnection?.following && (
              <Styled.RowButton onClick={() => answerFollowRequestHandler(true, true)}>accept + follow back</Styled.RowButton>
            )}
            <Styled.RowButton onClick={() => answerFollowRequestHandler(false)}>ignore</Styled.RowButton>
          </Styled.ConnectionActionsRow>
        </>
      )}

      {status === ConnectionStatus.CONNECTION_REQUEST_SENT && (
        <Styled.ConnectionActionsRow>
          {getFollowStatusComponent()}
          {!isRequestClicked && (
            <Styled.StatusButton
              onClick={() => setIsRequestClicked((prev) => !prev)}
              isRequest
              disabled={isStatusClicked || isRequestClicked}
            >
              connection requested
            </Styled.StatusButton>
          )}
          {isRequestClicked && (
            <Styled.CancelButton
              onClick={() => {
                cancelConnectionRequestHandler();
                setIsRequestClicked((prev) => !prev);
              }}
            >
              cancel connection request <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeRequestIconHandler} />
            </Styled.CancelButton>
          )}
          <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
            message
          </Styled.SendMessageBtn>
        </Styled.ConnectionActionsRow>
      )}

      {status === ConnectionStatus.FOLLOW_REQUEST_SENT && (
        <Styled.ConnectionActionsRow>
          {!isRequestClicked && (
            <Styled.StatusButton
              onClick={() => setIsRequestClicked((prev) => !prev)}
              isRequest
              disabled={isStatusClicked || isRequestClicked}
            >
              follow requested
            </Styled.StatusButton>
          )}
          {isRequestClicked && (
            <Styled.CancelButton
              onClick={() => {
                cancelFollowRequestHandler();
                setIsRequestClicked((prev) => !prev);
              }}
            >
              cancel follow request <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeRequestIconHandler} />
            </Styled.CancelButton>
          )}
          {!isCurrentOrVisitedUserAnon && (
            <Styled.RowButton onClick={connectHandler} disabled={!!ownConnection?.connectionRequestIgnored || isRequestClicked}>
              connect
            </Styled.RowButton>
          )}
          <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
            message
          </Styled.SendMessageBtn>
        </Styled.ConnectionActionsRow>
      )}

      {status === ConnectionStatus.CONNECTION_AND_FOLLOW_REQUESTS_SENT && (
        <Styled.ConnectionActionsRow>
          {!isStatusClicked && (
            <Styled.StatusButton
              onClick={() => setIsStatusClicked((prev) => !prev)}
              isRequest
              disabled={isStatusClicked || isRequestClicked}
            >
              follow requested
            </Styled.StatusButton>
          )}
          {isStatusClicked && (
            <Styled.CancelButton
              onClick={() => {
                cancelFollowRequestHandler();
                setIsStatusClicked((prev) => !prev);
              }}
            >
              cancel follow request <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeStatusIconHandler} />
            </Styled.CancelButton>
          )}
          {!isRequestClicked && (
            <Styled.StatusButton
              onClick={() => setIsRequestClicked((prev) => !prev)}
              isRequest
              disabled={isStatusClicked || isRequestClicked}
            >
              connection requested
            </Styled.StatusButton>
          )}
          {isRequestClicked && (
            <Styled.CancelButton
              onClick={() => {
                cancelConnectionRequestHandler();
                setIsRequestClicked((prev) => !prev);
              }}
            >
              cancel connection request <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeRequestIconHandler} />
            </Styled.CancelButton>
          )}
          <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
            message
          </Styled.SendMessageBtn>
        </Styled.ConnectionActionsRow>
      )}

      {status === ConnectionStatus.CONNECTED && (
        <Styled.ConnectionActionsRow>
          {!isStatusClicked && (
            <Styled.MutuallOrFollowingButton onClick={() => setIsStatusClicked((prev) => !prev)} disabled={isStatusClicked}>
              connected
            </Styled.MutuallOrFollowingButton>
          )}
          {isStatusClicked && (
            <Styled.CancelButton onClick={disconnectHandler}>
              confirm disconnect <Styled.CloseIcon src={ConnectionCloseIcon} alt="close" onClick={closeStatusIconHandler} />
            </Styled.CancelButton>
          )}
          <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
            message
          </Styled.SendMessageBtn>
        </Styled.ConnectionActionsRow>
      )}

      {status &&
        [
          ConnectionStatus.FOLLOW_EACH_OTHER,
          ConnectionStatus.OWN_FOLLOW,
          ConnectionStatus.USER_FOLLOW,
          ConnectionStatus.UNASSOCIATED
        ].includes(status) && (
          <Styled.ConnectionActionsRow>
            {getFollowStatusComponent()}
            {!isCurrentOrVisitedUserAnon && (
              <Styled.RowButton onClick={connectHandler} disabled={!!ownConnection?.connectionRequestIgnored || isStatusClicked}>
                connect
              </Styled.RowButton>
            )}
            <Styled.SendMessageBtn type="button" onClick={handleSendMsgBtn}>
              message
            </Styled.SendMessageBtn>
          </Styled.ConnectionActionsRow>
        )}
    </Styled.ConnectionActionsWrapper>
  );
};
