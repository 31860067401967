import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 5px;
  height: fit-content;
  flex-wrap: wrap;
  /* width: 100%; */
  max-width: 387px;
`;

// export const ListBlock = styled.div`
//   display: flex;
//   align-items: center;
//   align-content: flex-start;
//   gap: 3px;
//   min-height: 18px;
//   flex-wrap: nowrap;
//   width: 100%;
//   overflow-block: hidden;
//   overflow: hidden;
//   /* max-width: 331px; */
// `;

export const ListBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
`;

export const Title = styled.p`
  display: flex;
  padding: 3px 5px;
  margin-right: 5px;
  align-items: flex-start;
  gap: 10px;
  color: ${COLORS.lighterBlue};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`;
