import React from 'react';
import * as Styled from './left-column.styled';

interface ILeftColumnComponentProps {
  children: React.ReactNode;
  className?: string;
  ref?: React.MutableRefObject<HTMLDivElement | null>;
}

export const LeftColumnComponent = ({ children, className, ref }: ILeftColumnComponentProps) => (
  <Styled.LeftColumnWrapper ref={ref} className={className}>
    {children}
  </Styled.LeftColumnWrapper>
);
