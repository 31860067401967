import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { BasicNewsfeed } from '../basic-newsfeed';
import { ReplyComponent } from '@/common/components/post/components/reply';
import { ConversationTreadComponent } from '@/common/components/profile/components/post-row/components/conversation-tread';
import { ReplyDivider } from '@/common/components/profile/components/post-row/components/conversation-tread/conversation-tread.styled';

import { useFetchTread, usePostStatistic, useTreadByPostId } from '@/common/hooks';
import { IPost, IPostWithPostOrOriginalPost, ISubPost } from '@/common/types/post.type';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import * as Styled from './newsfeed-row.styled';

interface IProps {
  post: IPost;
  quotePostHandler: (postId: string) => void;
  closeModal?: () => void;
  userProfileId: string;
  isPublish?: boolean;
  isLast?: boolean;
  isInQuoteModal?: boolean;
  isTreadOpen?: boolean;
  isInModal?: boolean;
  isTreadHidden?: boolean;
  onPostClick?: () => void;
  openTreadOnMemo?: boolean;
  isTreanding?: boolean;
  activeTab: NewsfeedNavigation;
  currentFilter?: IFilters;
  scrollAfterSkip?: () => void;
}

export const NewsfeedRow = ({
  post,
  quotePostHandler,
  closeModal,
  userProfileId,
  isPublish,
  isLast,
  isInQuoteModal,
  isTreadOpen,
  isInModal,
  isTreadHidden,
  onPostClick,
  openTreadOnMemo,
  isTreanding,
  activeTab,
  scrollAfterSkip,
  currentFilter
}: IProps) => {
  const [isClickOnPost, setIsClickOnPost] = useState(!!post.tread.replies.length);

  const [repliedPost, setRepliedPost] = useState<null | ISubPost>(null);
  const [openReply, setOpenReply] = useState(false);
  const [isTreadFetched, setIsTreadFetched] = useState(false);

  const { hasNext, replies } = useTreadByPostId(post.id);

  const { data: statistic } = usePostStatistic(post.id);
  useEffect(() => {
    if (typeof openTreadOnMemo === 'undefined') return;
    setIsClickOnPost(openTreadOnMemo);
  }, [openTreadOnMemo]);

  const { mutateAsync: featchTread, isLoading: isTreadLoading } = useFetchTread(post.id);

  useEffect(() => {
    if (isClickOnPost && !isTreadLoading && hasNext && !replies?.length && !isTreadFetched) {
      featchTread();
      setIsTreadFetched(true);
    }
  }, [isClickOnPost]);

  useEffect(() => {
    if (isTreadOpen) {
      setIsClickOnPost(true);
    }
  }, [isTreadOpen]);

  const openReplyClick = (postItem?: IPostWithPostOrOriginalPost, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event?.stopPropagation();
    if (postItem === (repliedPost ?? undefined) && openReply) {
      setRepliedPost(null);
      setOpenReply(false);
      return;
    }
    setRepliedPost(postItem ?? null);
    setOpenReply(true);
  };

  const onClickByPost = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onPostClick) {
      onPostClick();
      return;
    }
    setIsClickOnPost((prev) => !prev);
    event?.preventDefault();
  };

  const isNotDisplayPostRow = useMemo(
    () =>
      (!!(statistic?.replyCount ?? post.replyCount ?? 0) && !!post.deletedAt) ||
      (post.isRepost && !!post.post?.deletedAt) ||
      !((post.post && post.isRepost) || (post.post && post.isRepost === false) || (!post.post && post.isRepost === null)),
    [statistic?.replyCount, post.replyCount, post.deletedAt, post.post, post.isRepost]
  );

  return (
    <Styled.TwoTreadsWrapper isNotDisplay={isNotDisplayPostRow}>
      <Styled.PostWrapper>
        {!post.post && (
          <BasicNewsfeed
            post={post}
            userProfileId={userProfileId}
            onClickByPost={onClickByPost}
            openReply={(e) => openReplyClick(undefined, e)}
            isInModal={isInModal}
            quotePostHandler={quotePostHandler}
            statistic={statistic}
            isTreadOpen={isClickOnPost}
            isPublish={isPublish}
            activeTab={activeTab}
            currentFilter={currentFilter}
            scrollAfterSkip={scrollAfterSkip}
          />
        )}
      </Styled.PostWrapper>
      {!isTreadHidden && (
        <ConversationTreadComponent
          replies={replies}
          isTreanding={isTreanding}
          quotePostHandler={quotePostHandler}
          openReply={openReplyClick}
          userProfileId={userProfileId}
          isClickOnPost={isClickOnPost || !!openTreadOnMemo}
          isInModal={isInModal}
          postId={post.id}
          closeModal={closeModal}
        />
      )}
      <AnimatePresence>
        {openReply &&
          statistic &&
          (statistic.replyCount <= 0 ? (
            <motion.div transition={{ ease: 'backInOut', delay: 0.2, duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <ReplyDivider />
              <ReplyComponent
                userProfileId={userProfileId}
                repliedPost={repliedPost ?? undefined}
                postId={repliedPost?.id}
                originalPostId={post.id}
                originalPost={post}
                onClickByPost={onClickByPost}
                closeHandler={() => setOpenReply(false)}
              />
            </motion.div>
          ) : (
            <>
              <ReplyDivider />
              <ReplyComponent
                userProfileId={userProfileId}
                repliedPost={repliedPost ?? undefined}
                postId={repliedPost?.id}
                originalPostId={post.id}
                originalPost={post}
                onClickByPost={onClickByPost}
                closeHandler={() => setOpenReply(false)}
              />
            </>
          ))}
      </AnimatePresence>
      {!isLast && !isPublish && <Styled.PostDivider isDefaultWidth={isTreanding || isInQuoteModal} />}
    </Styled.TwoTreadsWrapper>
  );
};
