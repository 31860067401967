import React from 'react';

import IncludeIcon from '@/styles/icons/include-filter.png';
import ExcludeIcon from '@/styles/icons/exclude-filter.png';
import PlusIcon from '@/styles/icons/plus-filter.png';
import PlusAnonIcon from '@/styles/icons/plus-filter-anon.webp';

import { IFilters, INewsfeedListFilter, ITag, ITagFilter, NewsfeedCategory } from '@/common/types/newsfeed.type';
import * as Styled from './newsfeed-filter.styled';

const DATE_ARRAY = ['today', 'yesterday', 'this week', 'last week', 'this month', 'last month'];

export const NewsfeedFilterCategory: React.FC<INewsfeedListFilter> = ({
  tagList,
  tagName,
  currentFilter,
  allowedFilters,
  displayTagName,
  isFilterLoading,
  isAnon,
  updateFilters
}) => {
  const includeIds = currentFilter.filter((it) => it.include).map((it) => it.id);
  const excludeIds = currentFilter.filter((it) => !it.include).map((it) => it.id);
  const allowedFiltersIds = allowedFilters?.map((it) => it.id);

  const handleClick = (tag: ITag) => {
    updateFilters(tagName as keyof IFilters, { ...tag, include: true });
  };

  const handleTimeClick = (id: string) => {
    updateFilters(tagName as keyof IFilters, { id, include: true } as unknown as ITagFilter);
  };

  const getActiveIcon = (isIncluded: boolean, isExcluded: boolean) => (isIncluded ? IncludeIcon : isExcluded ? ExcludeIcon : PlusIcon);

  // const getActiveIcon = (isIncluded: boolean, isExcluded: boolean) =>
  //   isIncluded
  //     ? isAnon
  //       ? IncludeAnonIcon
  //       : IncludeIcon
  //     : isExcluded
  //     ? isAnon
  //       ? ExcludeAnonIcon
  //       : ExcludeIcon
  //     : isAnon
  //     ? PlusAnonIcon
  //     : PlusIcon;
  return (
    <Styled.CategoryContainer>
      <Styled.FilterTitle isAnon={isAnon}>{displayTagName ?? tagName}</Styled.FilterTitle>
      {tagName !== NewsfeedCategory.TIME &&
        tagList?.map((tag) => {
          const isIncluded = includeIds.includes(tag.id);
          const isExcluded = excludeIds.includes(tag.id);
          const isDisabled = allowedFiltersIds && !allowedFiltersIds.includes(tag.id) && !isExcluded && !isIncluded;
          return (
            <Styled.ButtonItem
              key={tag.id}
              isAnon={isAnon}
              isInclude={isIncluded}
              isExclude={isExcluded}
              isDisabled={false}
              disabled={isFilterLoading}
              onClick={() => handleClick(tag)}
            >
              {tag.shortName}
              {/* {isDisabled ? (
                <Styled.DisabledIcon src={isAnon ? PlusAnonIcon : PlusIcon} alt="disabled-icon" />
              ) : ( */}
              <Styled.ButtonIcon src={getActiveIcon(isIncluded, isExcluded)} isAnon={isAnon} isExclude={isExcluded} alt="button-icon" />
            </Styled.ButtonItem>
          );
        })}
      {tagName === NewsfeedCategory.TIME &&
        DATE_ARRAY.map((date) => {
          const isIncluded = includeIds.includes(date);
          const isExcluded = excludeIds.includes(date);
          const isDisabled = allowedFiltersIds && !allowedFiltersIds.includes(date) && !isExcluded && !isIncluded;
          return (
            <Styled.ButtonItem
              key={date}
              isAnon={isAnon}
              isInclude={isIncluded}
              isExclude={isExcluded}
              isDisabled={false}
              disabled={isFilterLoading}
              onClick={() => handleTimeClick(date)}
            >
              {date}
              {/* {isDisabled ? (
                <Styled.DisabledIcon src={isAnon ? PlusAnonIcon : PlusIcon} alt="disabled-icon" />
              ) : ( */}
              <Styled.ButtonIcon src={getActiveIcon(isIncluded, isExcluded)} isAnon={isAnon} isExclude={isExcluded} alt="button-icon" />
            </Styled.ButtonItem>
          );
        })}
    </Styled.CategoryContainer>
  );
};
