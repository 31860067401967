import styled from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS, FONTS } from '@/theme';
import { FAMILIES } from '@/theme/fonts.const';

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  margin-top: 30px;
`;

export const PaginationIntersection = styled.div`
  height: 1px;
  opacity: 0;
  width: 100%;
`;

export const News = styled(motion.div)`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 100%;
  color: #868686;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
`;
