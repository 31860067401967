import styled, { css } from 'styled-components';
import { COLORS, FONTS } from '@/theme';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const WorkHistoryWrapper = styled(BoxAroundSection)<{ isAnon?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${COLORS.black};
  background: transparent;
  width: 426px;
  padding: 18px 20px 25px 20px;
  margin-bottom: 10px;
`;

export const WorkHistoryTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  img {
    object-fit: cover;
  }
`;

export const TitleHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  width: fit-content;
`;

export const WorkHistoryTitle = styled.h3<{ isAnon: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.m};
  ${({ isAnon }) => isAnon && 'color: #7400CF;'}
`;

export const ListWrapper = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 350px;
  padding-left: 26px;
`;

export const ListItemDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
`;

export const ListItemWrapper = styled.div<{ isHasLeftPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const LocationAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const ListItemSeparator = styled.div<{
  isAnon?: boolean;
  isCurrent: boolean;
  isInRole?: boolean;
  isInDate?: boolean;
  isEducation?: boolean;
}>`
  display: inline-block;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-weight: ${FONTS.WEIGHTS.light};
  ${({ isInRole }) => isInRole && `font-weight: ${FONTS.WEIGHTS.medium};`}
  line-height: 13px;
  padding: 3px 0;
  width: 6px;
  @-moz-document url-prefix() {
    line-height: 0.85rem;
  }
  color: ${COLORS.iconColor};
  ${({ isInDate }) =>
    isInDate &&
    `
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.s};
  margin: 0px 1px;
  padding: 0;
  `}
  ${({ isCurrent, isEducation }) => (!isCurrent || isEducation) && 'color: #d4d4d4;'}
  ${({ isAnon }) => isAnon && 'color: #8b8b8b;'}
`;

export const ListItemTitle = styled.div<{ isAnon?: boolean; isRole: boolean; isCurrent: boolean; isEducation: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: 20px;

  ${({ isRole }) => isRole && `font-weight: ${FONTS.WEIGHTS.medium};`}
  ${({ isCurrent, isEducation }) => (!isCurrent || isEducation) && 'color: #464646;'}
  ${({ isAnon }) => isAnon && 'color: #CECECE;'}

  ${ListItemSeparator} {
    padding: 0px 7px;
  }
`;

export const DotItemElement = styled.div`
  color: ${COLORS.iconColor};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.m};
  display: flex;
  align-items: center;
`;

export const ListItemDateAndLocationContainer = styled.div<{
  isCurrent: boolean;
  isLocatin?: boolean;
  isEducation: boolean;
  isAnon?: boolean;
}>`
  color: ${COLORS.graniteGray};
  display: flex;
  column-gap: 5px;
  align-items: center;

  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.normal};
  ${({ isLocatin }) => isLocatin && `margin-top: -2px;font-weight: ${FONTS.WEIGHTS.normal};`}
  ${({ isAnon }) => isAnon && 'color: #9D9D9D;'}
  ${({ isAnon, isCurrent, isEducation }) => !isAnon && (!isCurrent || isEducation) && 'color: #828282;'}
`;

export const ListItemRangeContainer = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const ListItemDescriptionContainer = styled.div<{ isAnon?: boolean; isCurrent: boolean; isEducation: boolean; isWarning?: boolean }>`
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: ${({ isWarning }) => (isWarning ? '13px' : '22px')};
  word-wrap: break-word;
  ${({ isCurrent, isEducation }) => (!isCurrent || isEducation) && 'color: #272727;'}
  ${({ isAnon }) => isAnon && 'color: #CECECE;'}
`;

export const ListItemLineWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const ListItemCoverageContainer = styled.div<{ isAnon?: boolean; isCurrent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 7px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  font-weight: ${FONTS.WEIGHTS.light};
  color: #1e1e1e;
  ${({ isCurrent }) => !isCurrent && 'color: #272727;'}
  ${({ isAnon }) => isAnon && 'color: #8b8b8b;'}
`;

export const CoveragePadding = styled.span<{ isAnon?: boolean }>`
  color: #585858;
  font-size: 13px;
  line-height: 13px;
  padding: 3px 0;
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #585858;
  ${({ isAnon }) => isAnon && 'color: #9D9D9D;'}
`;

export const ListItemCoveregeSectionTitle = styled.span<{ isAnon?: boolean }>`
  display: flex;
  color: #828282;
  ${({ isAnon }) => isAnon && 'color: #b7b7b7;'}
  align-self: flex-start;
  font-size: 13px;
  line-height: 13px;
  min-width: 55px;
  max-width: 55px;
  padding: 3px 0;
`;

export const ListCoverageSectionTitleWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

export const ListCoverageSectionWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

export const ListItemCoveregeSectionItem = styled.button<{ isAnon?: boolean }>`
  color: #0016d8;
  font-weight: ${FONTS.WEIGHTS.light};
  ${({ isAnon }) => isAnon && `color: #93BEFF;font-weight: ${FONTS.WEIGHTS.extra_light};`}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  line-height: 13px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 3px 0;

  display: flex;
  padding: 7px 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(227, 231, 255, 0);
  background: rgba(239, 241, 255, 0.5);
`;

export const CompleteFieldsBtn = styled.button`
  display: flex;
  align-self: flex-start;
  color: ${COLORS.profileWarningText};
  border: 1px dashed ${COLORS.profileWarningBorder};
  background-color: ${COLORS.profileWarningBackground};
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 7px 15px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.normal};
  text-transform: none;
  cursor: pointer;
`;

export const Warning = styled.span<{ lowercase?: boolean; isDate?: boolean }>`
  white-space: pre-wrap;
  display: inline-block;
  ${({ lowercase }) => !lowercase && 'text-transform: capitalize;'}
  color: ${COLORS.warningColor};
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  ${({ isDate }) =>
    isDate &&
    css`
      font-size: 12px;
      line-height: 12px;
    `}
`;

export const AddMissingField = styled.button<{ isLocation?: boolean; isAnon?: boolean }>`
  border: 0;
  background: transparent;
  ::focus-visible {
    outline: 0;
  }

  cursor: pointer;
  color: #0016d8;
  font-weight: 300;
  ${({ isAnon }) => isAnon && 'color: #93BEFF;font-weight: 200;'}
  font-family: 'Roboto Flex';
  font-size: 13px;
  font-style: normal;
  line-height: 13px;

  ${({ isLocation }) =>
    isLocation &&
    css`
      font-family: 'Roboto Flex';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 100% */
    `}
`;
