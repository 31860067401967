import styled, { css } from 'styled-components';
import Image from 'next/image';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const ContainerFilters = styled.div`
  margin-top: 7px;
  display: flex;
  width: 100%;
  max-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  gap: 10px;
`;

export const Container = styled.div<{ gap?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  min-width: 250px;
  max-width: 250px;
  padding-right: 2px;

  ${({ gap }) => gap && `gap: ${gap}`}
`;

export const MemoFilterDivider = styled.div<{ isAnon?: boolean }>`
  background-color: #f6f6f6;
  /* ${({ isAnon }) => isAnon && 'background-color: #3D3D3D;'} */
  height: 1px;
  margin-top: 4px;
  /* margin-bottom: 5px; */
  width: 100%;
`;

export const ContainerTitleFilter = styled.div`
  border-radius: 15px;
  background: red;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  height: 28px;
`;

export const ContainerTitle = styled.div<{ isFilter?: boolean | undefined }>`
  border-radius: 15px;
  background: ${({ isFilter }) => (isFilter ? COLORS.contIsFilter : COLORS.bcTitleFilter)};
  padding: 7px 12px;
  display: flex;
  align-items: center;
  height: 28px;
`;

export const TitleFilter = styled.h3`
  border-radius: 8px;
  padding: 7px 2px 7px 0;
  color: #474747;
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-weight: ${WEIGHTS.medium};
  line-height: 14px;
`;

export const ContainerItem = styled.div`
  height: 28px;
`;

export const ButtonFilter = styled.button<{ active?: boolean; isAnon: boolean; width?: string }>`
  border-radius: 8px;
  color: #717171;
  border: 1px solid rgba(228, 228, 228, 0.75);
  background: rgba(228, 228, 228, 0.4);
  padding: 7px 7px 7px 9px;
  ${({ isAnon, active }) => !isAnon && active && 'border: none;padding: 7px 12px;'};
  ${({ isAnon, active }) => !isAnon && active && 'background: #D4E0FF;'};
  ${({ isAnon, active }) => !isAnon && active && 'color: #003FE1;'};
  ${({ isAnon, active }) => isAnon && !active && 'border: none;padding: 7px 12px;'};
  ${({ isAnon, active }) => isAnon && !active && 'background: rgba(130, 130, 130, 0.40);'};
  ${({ isAnon, active }) => isAnon && !active && 'color: #DBDBDB'};
  ${({ isAnon, active }) => isAnon && active && 'border: none;padding: 7px 12px;'};
  ${({ isAnon, active }) => isAnon && active && 'background: #0047FF;'};
  ${({ isAnon, active }) => isAnon && active && 'color: #fff;'};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-weight: ${WEIGHTS.light};
  line-height: 14px;
  cursor: pointer;

  ${({ width }) => width && `width: ${width}`}
`;

export const ButtonFilterDisabled = styled.button<{ isAnon: boolean; width?: string }>`
  padding: 7px 7px 7px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(197, 197, 197, 0.8);
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-weight: ${WEIGHTS.light};
  line-height: 14px;
  border: none;
  border-radius: 6px;

  background: rgba(228, 228, 228, 0.1);
  cursor: pointer;
  ${({ isAnon }) =>
    isAnon &&
    css`
      background: rgba(228, 228, 228, 0.2);
      color: #818181;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;

export const IconPlus = styled(Image)`
  width: 10px;
  height: 10px;
  margin-left: 7px;
`;

export const IconCheck = styled(Image)`
  width: 10px;
  height: 10px;
  margin-left: 7px;
`;
