import React, { FC, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Styled from './posts.styled';
import { PostRowComponent } from '@/common/components/profile/components/post-row';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { useAllHomePosts, useFetchHomePosts } from '@/common/hooks';
import { IFilters } from '@/common/types/newsfeed.type';

interface IProps {
  userProfileId: string;
  quotePostHandler: (postId: string) => void;
  openReplyModal: (newPostId: string, searchId: string) => void;
  isShowLoader: boolean;
  setIsShowLoader: (it: boolean) => void;
  isAll: boolean;
  currentFilter: IFilters;
}

export const FollowingPostsComponent: FC<IProps> = ({
  isAll,
  isShowLoader,
  currentFilter,
  userProfileId,
  setIsShowLoader,
  quotePostHandler,
  openReplyModal
}) => {
  const { ref: loadingInViewRef, inView: isLoadingInView } = useInView({
    threshold: 0.1
  });

  const { allPosts: posts, isLoading } = useAllHomePosts(isAll, userProfileId);
  const { fetchNextPageList, refetchList, hasNextPageList, isFetchingNextPageList, isListLoading, isRefetchingList } = useFetchHomePosts(
    isAll,
    currentFilter,
    userProfileId
  );

  useEffect(() => {
    if (!isRefetchingList && isShowLoader) {
      setIsShowLoader(false);
    }
  }, [isRefetchingList]);

  useEffect(() => {
    if (!isLoading && !isListLoading) {
      refetchList();
    }
  }, []);

  useEffect(() => {
    if (!isLoadingInView) return;
    if (hasNextPageList) {
      fetchNextPageList();
    }
  }, [isLoadingInView]);

  return (
    <>
      <AnimatePresence mode="wait">
        {!(isLoading || isListLoading || (isRefetchingList && isShowLoader)) ? (
          <motion.div
            transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {posts && posts.length > 0 ? (
              <Styled.Posts>
                {posts.map((item, index) => (
                  <PostRowComponent
                    isHome={!item.isRepliedPostView}
                    quotePostHandler={quotePostHandler}
                    key={`post-row-${item.id}`}
                    userProfileId={userProfileId}
                    post={item}
                    openReplyModal={openReplyModal}
                    isLast={!hasNextPageList && index === posts.length - 1}
                  />
                ))}
              </Styled.Posts>
            ) : (
              <Styled.NoPostsText>There is no content matching your filter (see right)</Styled.NoPostsText>
            )}
          </motion.div>
        ) : (
          <LoaderWrapper heightForMinus={238}>
            <Loader />
          </LoaderWrapper>
        )}
        {isFetchingNextPageList && (
          <div style={{ marginTop: '25px' }}>
            <Loader />
          </div>
        )}
      </AnimatePresence>
      <Styled.PaginationIntersection
        ref={loadingInViewRef}
        style={{ display: isFetchingNextPageList || isLoading || isListLoading || isRefetchingList ? 'none' : 'block' }}
      />
    </>
  );
};
