import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import LockedProfile from '@styles/icons/locked-profile.webp';
import MutualAssociationsIcon from '@styles/icons/mutual-associations-icon.webp';
import SettingsIcon from '@styles/icons/settings.png';
import LinkedinIcon from '@styles/icons/linkedin-icon.webp';
import TwitterIcon from '@styles/icons/twitter-icon.webp';
import BioWorkHistoryArrow from '@styles/icons/edit-bio-work-history-arrow.webp';
import { useConnectionsById, useCreateConnection, useCreateFollow, useMutualAssociations, useUpdateFollowerById, useUser } from '@hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { IProfile, UnfilledFields, WorkHistoryType } from '@/common/types/profile.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { FollowsModalComponent, FollowsModalType } from './follows-modal.component';
import * as Styled from './basic-status.styled';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { getUnfilledEmployerProps } from '../work-history/work-history.utils';
import { HistoryListItemComponent } from '../work-history/history-list-item.component';
import { formatFollowersCount } from '@/common/utils/format-followers-count';

interface IBasicStatusProps {
  profile: IProfile;
  isCurrent: boolean;
  currentUserId: string;
  userId: string;
  isAnonUser: boolean;
  isAnonProfile: boolean;
}

export const BasicStatusComponent = ({ profile, isCurrent, currentUserId, userId, isAnonUser, isAnonProfile }: IBasicStatusProps) => {
  const { push, pathname } = useRouter();
  const [followersOpen, setFollowersOpen] = useState(false);
  useUser();

  const [followingOpen, setFollowingOpen] = useState(false);

  const { mutateAsync: updateFollower, isLoading: updateFollowerIsLoading } = useUpdateFollowerById(profile.id || '');

  const { mutateAsync: followById, isLoading: followIsLoading } = useCreateFollow();
  const { mutateAsync: connectById, isLoading: connectIsLoading } = useCreateConnection();

  const { data: mutualAssociations } = useMutualAssociations(userId, isCurrent);
  const { data: connections, refetch, isLoading: isConnectionsLoading } = useConnectionsById(userId, 'user');

  useEffect(() => {
    refetch();
  }, [isCurrent, currentUserId, userId]);

  useEffect(() => {
    if (followersOpen) {
      refetch();
    }
  }, [followersOpen]);

  useEffect(() => {
    if (followingOpen) {
      refetch();
    }
  }, [followingOpen]);

  const hadleCloseModal = () => {
    setFollowersOpen(false);
    setFollowingOpen(false);
  };

  const getAnotherMemberFollowersCount = (amount: number) => {
    if (amount > 1000) return '> 1K';

    return '< 1K';
  };

  const handleSendMsgBtn = () => {
    if (pathname !== ROUTER_KEYS.MESSAGES) {
      push({ pathname: ROUTER_KEYS.MESSAGES, query: { id: profile.id!, fullname: profile.fullName, imageUrl: profile.imageUrl } });
    }
  };

  const onClickBySettings = () => {
    if (isCurrent) {
      push(ROUTER_KEYS.VISIBILITY_SETTINGS);
    }
  };

  const unfilledItem: UnfilledFields = useMemo(() => {
    const role = profile.currentEmployer;
    let unfilledFields: any = {};
    unfilledFields = getUnfilledEmployerProps({
      ...role,
      type: WorkHistoryType.EMPLOYER
    });
    if (role?.vertical === 'Publishing Sole Publisher') {
      delete unfilledFields.role;
    }
    if (isAnonProfile) {
      delete unfilledFields.role;
      delete unfilledFields.startDate;
      delete unfilledFields.endDate;
    }
    if (!role.isPrevious) {
      delete unfilledFields.endDate;
    }

    return unfilledFields;
  }, [profile.currentEmployer]);

  const linkedInFollowerCount = profile.linkedInFollowerCount ?? 0;
  const twitterFollowerCount = profile.twitterFollowerCount ?? 0;

  return (
    <Styled.BasicStatusProfileWrapper>
      <Styled.BasicStatusContainer>
        <Styled.UserNameContainer>
          {isCurrent && (
            <Styled.SettingsButton onClick={onClickBySettings}>
              <p>settings</p>
              <Image src={SettingsIcon} alt="Settings Icon" width={14} height={14} />
            </Styled.SettingsButton>
          )}
          <Styled.HeaderUnderlineOnBasicProfile>
            <Styled.UserNameWithImageContainer>
              <Styled.UserName isAnon={isAnonProfile}>{profile?.fullName}</Styled.UserName>
            </Styled.UserNameWithImageContainer>
          </Styled.HeaderUnderlineOnBasicProfile>
          {isCurrent ? (
            <Styled.CurrentUserSubInfo>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => push(ROUTER_KEYS.EDIT_PROFILE)}>
                <Styled.EditBioLink>Edit your bio and roles</Styled.EditBioLink>
                <Image src={BioWorkHistoryArrow} width={12} height={12} alt="edit-bio-work-history-arrow" />
              </div>
              <Styled.CurrentUserSubInfoTest>Only you can see your following count and those you follow</Styled.CurrentUserSubInfoTest>
            </Styled.CurrentUserSubInfo>
          ) : (
            <Styled.AssociationsContainer>
              <Styled.ConnectionActions
                currentUserId={currentUserId}
                userId={userId}
                isCurrentOrVisitedUserAnon={isAnonUser || isAnonProfile}
                handleSendMsgBtn={handleSendMsgBtn}
              />
            </Styled.AssociationsContainer>
          )}
        </Styled.UserNameContainer>
        <AnimatePresence>
          {!isConnectionsLoading && connections ? (
            <motion.div
              transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styled.UserInfoWrapper>
                <Styled.UserInfoContainer>
                  <Styled.UserImage imageUrl={profile.imageUrl} borderRadius="50%" isImageHidden={profile.isImageHidden} />
                  <Styled.FollowersAndBioContainer>
                    {isCurrent ? (
                      <Styled.FollowersContainer>
                        <Styled.FollowersItemContainer onClick={() => connections.followers && setFollowersOpen(true)} isClickable>
                          <span>Followers</span>
                          <span>{connections.followers}</span>
                        </Styled.FollowersItemContainer>
                        <Styled.FollowersItemContainer onClick={() => connections.follows && setFollowingOpen(true)} isClickable>
                          <span>Following</span>
                          <span>{connections.follows}</span>
                        </Styled.FollowersItemContainer>
                      </Styled.FollowersContainer>
                    ) : (
                      <Styled.FollowersItemContainer onClick={() => connections.followers && setFollowersOpen(true)} isClickable>
                        <span>Followers</span>
                        <span>{getAnotherMemberFollowersCount(connections.followers || 0)}</span>
                        {profile.whoCanFollow === false && <Styled.LockedProfileIcon src={LockedProfile} alt="locked" />}
                        {(!!mutualAssociations || mutualAssociations === 0) && (
                          <Styled.MutualAssociationsContainer>
                            <Styled.MutualAssociationsIcon src={MutualAssociationsIcon} alt="icon" />
                            <span>{`followed by ${mutualAssociations} member${mutualAssociations === 1 ? '' : 's'} you follow`}</span>
                          </Styled.MutualAssociationsContainer>
                        )}
                      </Styled.FollowersItemContainer>
                    )}

                    <Styled.OtherWebsitesFollowers>
                      {twitterFollowerCount !== 0 && (
                        <Styled.OtherFollowersItem>
                          {!isAnonUser && <Styled.OtherFollowersIcon src={TwitterIcon} alt="twitter-icon" />}
                          <Styled.FollowersCountContainer>
                            {isAnonUser ? (
                              <Styled.OtherFollowersText>Twitter followers</Styled.OtherFollowersText>
                            ) : (
                              <Styled.OtherFollowersText>followers</Styled.OtherFollowersText>
                            )}
                            <Styled.OtherFollowersCount>{formatFollowersCount(twitterFollowerCount)}</Styled.OtherFollowersCount>
                          </Styled.FollowersCountContainer>
                        </Styled.OtherFollowersItem>
                      )}
                      {!isAnonUser && linkedInFollowerCount !== 0 && (
                        <Styled.OtherFollowersItem>
                          <Styled.OtherFollowersIcon src={LinkedinIcon} alt="linkedin-icon" />
                          <Styled.FollowersCountContainer>
                            <Styled.OtherFollowersText>followers</Styled.OtherFollowersText>
                            <Styled.OtherFollowersCount>{formatFollowersCount(linkedInFollowerCount)}</Styled.OtherFollowersCount>
                          </Styled.FollowersCountContainer>
                        </Styled.OtherFollowersItem>
                      )}
                    </Styled.OtherWebsitesFollowers>
                  </Styled.FollowersAndBioContainer>
                </Styled.UserInfoContainer>
                <Styled.UserBioInfo>
                  {profile.bio !== null ? (
                    <Styled.BioContainer>{profile.bio}</Styled.BioContainer>
                  ) : (
                    isCurrent && (
                      <Styled.BioContainer>
                        <button onClick={() => push(ROUTER_KEYS.EDIT_PROFILE)}>+ add bio</button>
                      </Styled.BioContainer>
                    )
                  )}
                </Styled.UserBioInfo>
              </Styled.UserInfoWrapper>
            </motion.div>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </AnimatePresence>
      </Styled.BasicStatusContainer>
      <HistoryListItemComponent
        key={`current-employer-${profile.currentEmployer.id}`}
        item={profile.currentEmployer}
        unfilledFields={unfilledItem}
        isCurrent={isCurrent}
        isHasLeftPadding
      />

      <FollowsModalComponent
        profileId={profile.id ?? ''}
        open={followersOpen}
        closeHandler={() => hadleCloseModal()}
        followFunction={followById}
        connectFunction={connectById}
        followIsLoading={followIsLoading || updateFollowerIsLoading}
        connectIsLoading={connectIsLoading || updateFollowerIsLoading}
        updateFollower={updateFollower}
        type={FollowsModalType.FOLLOWERS}
      />

      {!!isCurrent && (
        <FollowsModalComponent
          profileId={profile.id ?? ''}
          open={followingOpen}
          closeHandler={() => hadleCloseModal()}
          followFunction={followById}
          connectFunction={connectById}
          followIsLoading={followIsLoading || updateFollowerIsLoading}
          connectIsLoading={connectIsLoading || updateFollowerIsLoading}
          updateFollower={updateFollower}
          type={FollowsModalType.FOLLOWING}
        />
      )}
    </Styled.BasicStatusProfileWrapper>
  );
};
