import { JumpTo, SectionStatusType } from '../types';

export * as APP_KEYS from './app-keys.const';
export * as QUESTION from './question';
export * as POST from './post.const';

export const ANIMATION_TIMEOUT = 400;

export const MODAL_SLIDE_TIMEOUT = 600;
export const MODAL_SLIDE_TIMEOUT_CLOSE = 500;
export const POST_LIMIT = 10;

export const APP_WIDTH = 1000;
export const APP_WIDTH_MODAL = 1440;
export const APP_MIN_MARGIN_LEFT = 65;
export const APP_MIN_MARGIN_LEFT_MODAL = 30;
export const APP_MIN_MARGIN_RIGHT = 100;
export const APP_TOP_PADDING = 0;

export const SHOW_POSTS = !!Number(process.env.NEXT_PUBLIC_SHOW_POSTS);
export const SHOW_SWITCH_LOGIN_METHOD = !!Number(process.env.NEXT_PUBLIC_SHOW_SWITCH_LOGIN_METHOD);

export const USERNAME_MATCH = /^[a-zA-Z0-9-]{5,25}$/;
export const FULL_NAME_MATCH = /^([\w‘' `-]+\s)+[\w'`‘-]+$/;

export const INIT_JUMP_TO_STATUS: SectionStatusType = {
  [JumpTo.ABOUT]: { isLoading: true, isEmpty: false },
  [JumpTo.KEY_DEBATES]: { isLoading: true, isEmpty: false },
  [JumpTo.SECURITY_COVERAGE]: { isLoading: true, isEmpty: false },
  [JumpTo.MEMOS]: { isLoading: true, isEmpty: false }
};
