import styled from 'styled-components';
import { FONTS } from '@/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`;

export const NewMusingInputsComponentContainer = styled.div`
  margin-top: 40px;
  padding-left: 40px;
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const ModalTitle = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.medium};
  color: #000;
  margin-bottom: 30px;
  line-height: 16px;
`;
