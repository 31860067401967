import styled, { css } from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const MentionTextContainer = styled.div<{ isAnon?: boolean; isSearchPage?: boolean }>`
  color: #262626;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 20px;
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  width: 100%;
  & > span {
    width: 100%;
  }

  & .highlighted {
    font-weight: ${FONTS.WEIGHTS.medium};

    ${({ isSearchPage }) =>
      isSearchPage &&
      css`
        font-weight: ${FONTS.WEIGHTS.semiBold};
      `}
  }

  & .highlightedMessage {
    color: #ef8f00;
  }

  ${({ isSearchPage }) =>
    isSearchPage &&
    css`
      font-weight: ${FONTS.WEIGHTS.light};
    `}
`;

export const Mention = styled.span<{ isQuote?: boolean; isFaded?: boolean; isAnon?: boolean }>`
  width: 100%;
  color: ${COLORS.mention};
  ${({ isAnon }) => isAnon && 'color: #93BEFF;'}
  ${({ isQuote }) => isQuote && `color: ${COLORS.mentionQuote};`}
  ${({ isFaded }) => isFaded && `color: ${COLORS.mentionFaded};`}
  cursor: pointer;

  & > span {
    width: 100%;
  }

  & .highlightedMessage {
    color: #ef8f00;
  }
`;

export const Link = styled.a`
  color: ${COLORS.blue};
`;
