import styled, { css } from 'styled-components';
import { FONTS } from '@/theme';

export const JumpTyWrapper = styled.div<{ isAnon?: boolean }>`
  padding: 22px 0 0 0;
  border-radius: 10px;
  background: transparent;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  min-width: 400px;
  ${({ isAnon }) => isAnon && 'min-width: 400px;background: transparent;'}
  width: fit-content;
  margin-bottom: 28px;
`;

const TextStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.m};
  border: none;
  background-color: transparent;
`;

export const JumpToIconWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 10px;

  & > span {
    ${TextStyle}
    padding-bottom: 3px;
    height: 17px;
    color: #a4a4a4;
  }
`;

export const JumpToButton = styled.button<{ isActive: boolean; isAnon?: boolean }>`
  ${TextStyle}
  cursor: pointer;
  color: rgba(0, 24, 241, 0.5);
  font-weight: ${FONTS.WEIGHTS.normal};
  padding-bottom: 3px;

  ${({ isActive, isAnon }) => isActive && !isAnon && 'color: #0018F1;padding-bottom: 2px;border-bottom: 1px solid #0018F1;'}
  ${({ isActive, isAnon }) => !isActive && isAnon && 'color: #6D96FF;'}
  ${({ isActive, isAnon }) => isActive && isAnon && 'color: #B0C6FF;padding-bottom: 2px;border-bottom: 1px solid #B0C6FF;'}
`;
