import { NO_MARKET_VALUE_TEXT } from '@/common/consts/memo.const';
import { CaseType, IMemoSection, TitleEnum } from '@/common/types/memo.type';

export const getSectionTitle = (memoSection: IMemoSection) => {
  if (memoSection.type === CaseType.BASE_CASE) {
    return '';
  }

  return 'section';
};

export const getSectionText = (memoSection: IMemoSection) => {
  const memoReturn = memoSection.return;

  if (memoSection.sectionName === TitleEnum.EXIT_VALUATION) {
    return 'Exit Value: Financial Estimates + Valuation';
  }

  if (memoSection.type === CaseType.BEAR_CASE) {
    return 'Bear Case';
    // return memoReturn > 0 ? `${title}  +${memoReturn}%` : `${title}  ${memoReturn}%`;
  }

  if (memoSection.type === CaseType.BASE_CASE && memoReturn) {
    if (!memoReturn) {
      return NO_MARKET_VALUE_TEXT;
    }
    return '';
  }

  if (memoSection.type === CaseType.BASE_CASE && !memoReturn) {
    return NO_MARKET_VALUE_TEXT;
  }

  return memoSection?.section?.replace(':', ' ') || '';
};
