import Image from 'next/image';
import styled from 'styled-components';
import { MentionTextComponent } from '@/common/components/post/components/mention-text';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const MessageContainer = styled.div`
  width: 535px;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MessageTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const TileHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
`;

export const HiddenImage = styled.div`
  background: rgba(217, 217, 217, 0.3);
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockImage = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${COLORS.black};

  img {
    display: block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    object-fit: cover;
  }
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const InboxImage = styled(Image)`
  margin-left: 7px;
  margin-top: 10px;
  width: 16px;
  height: 16px;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0px 3px 0px;
  gap: 8px;
`;

export const UserFullName = styled.span`
  font-family: ${FAMILIES.robotoFlex};
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2e;
`;

export const UserRoleInfo = styled.span`
  color: #a0a0a0;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
`;

export const MessageText = styled(MentionTextComponent)`
  width: 100%;
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  white-space: pre-wrap;
`;

export const NothingText = styled.div`
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const PaginationIntersection = styled.div`
  width: 100%;
  height: 1px;
  background-color: transparent;
`;

export const Divider = styled.div<{ isAnon: boolean }>`
  width: calc(100%);
  background-color: #e7e7e7;
  ${({ isAnon }) => isAnon && 'background-color: #353535;'}
  height: 1px;
`;

export const MessageImage = styled.img`
  max-width: 100%;
  border-radius: 5px;
`;

export const MessageBody = styled.div``;

export const ReactionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

export const ReactionText = styled.span`
  color: #d40000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 9px;
`;

export const ReactionContainer = styled.div<{ extraMargin?: number }>`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-right: ${({ extraMargin }) => extraMargin || 0}px;
`;

export const MessageReactions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const MessageDate = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 10px;
  line-height: 0.7;
  font-weight: ${WEIGHTS.light};
  color: #9b9b9b;
  margin-top: 9px;
`;

export const Dot = styled.span`
  color: #dbdbdb;
  margin: 0 2px;
`;

export const ClapIcon = styled(Image)`
  width: 18px;
  height: 16px;
  cursor: pointer;
`;

export const LikeIcon = styled(Image)`
  width: 18px;
  height: 16px;
  cursor: pointer;
`;

export const BookmarkIcon = styled(Image)`
  width: 12.8px;
  height: 16px;
  cursor: pointer;
`;

export const BookmarkText = styled.span`
  color: #888;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 9px;
  padding-left: 1px;
`;

export const OwnReactionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;
