import React, { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PaginationArrow from '@/styles/icons/blue-bottom-arrow.webp';
import * as Styled from './basic-status.styled';
import FollowModalButton from './components/follow-modal-button';
import { BUTTON_TEXT, FOLLOW_TAKE, IMAGE_ALT } from './consts/follows-modal.consts';
import PrivateUser from '@/styles/icons/private-user-logo.webp';
import { useAllFollowers, useAllFollowing, useAuth, useFollowersById, useFollowingById } from '@/common/hooks';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { findOutputVertical } from '@/common/utils/vertical-output';
import CloseIcon from '@/styles/icons/close-modal-pointer.png';

export enum FollowsModalType {
  FOLLOWERS = 'followers',
  FOLLOWING = 'following'
}

interface IFollowsModalComponentProps {
  open: boolean;
  profileId: string;
  closeHandler: () => void;
  followFunction: any;
  connectFunction: any;
  updateFollower: any;
  followIsLoading: boolean;
  connectIsLoading: boolean;
  type: FollowsModalType;
}

interface ISetFollowButtonText {
  connected: boolean;
  following: boolean;
  follower: boolean;
  followingRequestSent: boolean;
  followingRequestIgnored: boolean;
}

interface ISetConnectedButtonText {
  connected: boolean;
  connectionRequestSent: boolean;
  connectionRequestIgnored: boolean;
}

export const FollowsModalComponent = ({
  open,
  profileId,
  closeHandler,
  followFunction,
  connectFunction,
  followIsLoading,
  connectIsLoading,
  type,
  updateFollower
}: IFollowsModalComponentProps) => {
  const { user } = useAuth();
  const currentId = user && user.id;

  const isFollowersModal = type === FollowsModalType.FOLLOWERS;

  const { allData: list } = isFollowersModal ? useAllFollowers(profileId) : useAllFollowing(profileId);
  const {
    fetchNextPageList,
    isFetchingNextPageList,
    isListLoading: isLoading,
    count,
    metaData,
    refetchList
  } = isFollowersModal ? useFollowersById(profileId, FOLLOW_TAKE) : useFollowingById(profileId, FOLLOW_TAKE);

  const paginationFunction = () => {
    if (!isFetchingNextPageList) {
      fetchNextPageList();
    }
  };

  useEffect(() => {
    if (open) {
      refetchList();
    }
  }, [open]);

  const followUserById = async (
    id: string,
    following: boolean,
    followingRequestSent: boolean,
    followingRequestIgnored: boolean,
    soursProfileId: string
  ): Promise<void> => {
    if (!followingRequestSent && !following && !followingRequestIgnored && !followIsLoading) {
      await followFunction({ targetId: id });
      await updateFollower({ id: soursProfileId, isFollower: type === FollowsModalType.FOLLOWERS });
    }
  };
  const connectUserById = async (
    id: string,
    connected: boolean,
    connectionRequestSent: boolean,
    connectionRequestIgnored: boolean,
    soursProfileId: string
  ): Promise<void> => {
    if (!connected && !connectionRequestSent && !connectionRequestIgnored && !connectIsLoading) {
      await connectFunction({ targetId: id });
      await updateFollower({ id: soursProfileId, isFollower: type === FollowsModalType.FOLLOWERS });
    }
  };
  const setFollowerButtonActiveStatus = ({
    connected,
    follower,
    following,
    followingRequestSent,
    followingRequestIgnored
  }: ISetFollowButtonText) => {
    switch (true) {
      case connected:
        return false;
      case following && follower:
        return false;
      case followingRequestSent:
        return false;
      case following:
        return false;
      case followingRequestIgnored:
        return false;
      default:
        return true;
    }
  };

  const setConnectionButtonActiveStatus = ({ connected, connectionRequestSent, connectionRequestIgnored }: ISetConnectedButtonText) => {
    switch (true) {
      case connected:
        return false;
      case connectionRequestSent:
        return false;
      case connectionRequestIgnored:
        return false;
      default:
        return true;
    }
  };
  const setFollowerButtonText = (connected: boolean, follower: boolean, following: boolean, followingRequestSent: boolean) => {
    switch (true) {
      case connected:
        return BUTTON_TEXT.MUTUAL_FOLLOWERS;
      case following && follower:
        return BUTTON_TEXT.MUTUAL_FOLLOWERS;
      case followingRequestSent:
        return BUTTON_TEXT.FOLLOW_SENT;
      case following:
        return BUTTON_TEXT.FOLLOWING;
      case follower:
        return BUTTON_TEXT.FOLLOW_BACK;
      default:
        return BUTTON_TEXT.FOLLOW;
    }
  };
  const setConnectionButtonText = (connected: boolean, connectionRequestSent: boolean) => {
    switch (true) {
      case connected:
        return BUTTON_TEXT.CONNECTED;
      case connectionRequestSent:
        return BUTTON_TEXT.CONNECTION_SENT;
      default:
        return BUTTON_TEXT.CONNECT;
    }
  };

  const setFollowerButtonDisabledStatus = ({
    connected,
    follower,
    following,
    followingRequestSent,
    followingRequestIgnored
  }: ISetFollowButtonText) => {
    switch (true) {
      case connected:
        return false;
      case following && follower:
        return false;
      case followingRequestSent:
        return true;
      case following:
        return false;
      case followingRequestIgnored:
        return true;
      default:
        return false;
    }
  };

  const setConnectionButtonDisabledStatus = ({ connected, connectionRequestSent, connectionRequestIgnored }: ISetConnectedButtonText) => {
    switch (true) {
      case connected:
        return false;
      case connectionRequestSent:
        return true;
      case connectionRequestIgnored:
        return true;
      default:
        return false;
    }
  };

  return (
    <Styled.FollowsModalWrapper open={open} closeHandler={closeHandler} childTopPostion={15} isCreateModal>
      {!isLoading ? (
        <>
          <Styled.FollowsModalCounter>
            <p>{`${count} ${type[0].toUpperCase() + type.slice(1)}`}</p>
            <Styled.CloseButton onClick={closeHandler}>
              <Styled.CloseImage src={CloseIcon} alt="close-icon" />
              close
            </Styled.CloseButton>
          </Styled.FollowsModalCounter>
          <Styled.FollowsModalUsersContainer>
            {list?.length !== 0 &&
              list?.map(
                ({
                  id,
                  fullName,
                  imageUrl,
                  follower,
                  following,
                  connected,
                  connectionRequestSent,
                  followingRequestSent,
                  followingRequestIgnored,
                  connectionRequestIgnored,
                  userId,
                  role,
                  vertical,
                  isImageHidden,
                  isAnon
                }) => (
                  <Styled.FollowModalUserContainer key={id}>
                    <Link href={`profile?id=${id}`}>
                      <div>
                        {!isImageHidden ? (
                          <Styled.ImageWrapper imageUrl={imageUrl} borderRadius="30px" />
                        ) : (
                          <Styled.HiddenImage>
                            <img src={PrivateUser.src} alt={fullName} />
                          </Styled.HiddenImage>
                        )}
                        <Styled.FollowModalUserInfo>
                          <Styled.FollowModalUsername>{fullName}</Styled.FollowModalUsername>
                          <Styled.FollowModalRole>
                            {findOutputVertical(vertical ?? '')}
                            {` ${role === 'null' || !role ? '' : role}`}
                          </Styled.FollowModalRole>
                        </Styled.FollowModalUserInfo>
                      </div>
                    </Link>
                    {currentId !== userId && (
                      <Styled.FollowModalUserButtons>
                        <FollowModalButton
                          active={setFollowerButtonActiveStatus({
                            connected,
                            follower,
                            following,
                            followingRequestSent,
                            followingRequestIgnored
                          })}
                          disabled={setFollowerButtonDisabledStatus({
                            connected,
                            follower,
                            following,
                            followingRequestSent,
                            followingRequestIgnored
                          })}
                          onClick={() => followUserById(userId, following, followingRequestSent, followingRequestIgnored, id)}
                        >
                          {setFollowerButtonText(connected, follower, following, followingRequestSent)}
                        </FollowModalButton>
                        {!isAnon && !(!!user && user.isAnon) && (
                          <FollowModalButton
                            active={setConnectionButtonActiveStatus({ connected, connectionRequestSent, connectionRequestIgnored })}
                            disabled={setConnectionButtonDisabledStatus({ connected, connectionRequestSent, connectionRequestIgnored })}
                            onClick={() => connectUserById(userId, connected, connectionRequestSent, connectionRequestIgnored, id)}
                          >
                            {setConnectionButtonText(connected, connectionRequestSent)}
                          </FollowModalButton>
                        )}
                      </Styled.FollowModalUserButtons>
                    )}
                  </Styled.FollowModalUserContainer>
                )
              )}
          </Styled.FollowsModalUsersContainer>

          {!!metaData?.nextPage && (
            <Styled.PaginationContainer onClick={paginationFunction}>
              <Image src={PaginationArrow} width={14} height={14} alt={IMAGE_ALT.PAGINATION_ARROW} />
              <p>{`view more ${type}`}</p>
            </Styled.PaginationContainer>
          )}
        </>
      ) : (
        <LoaderWrapper padding={50}>
          <Loader />
        </LoaderWrapper>
      )}
    </Styled.FollowsModalWrapper>
  );
};
