import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { PageWrapperComponent } from '../page-wrapper';
import { BoxAroundSection } from '../page-wrapper/components/box-around-section';
import { FAMILIES } from '@/theme/fonts.const';
import { RightColumnStyle } from '../page-wrapper/components/right-column/right-column.styled';
import { LeftColumnComponent } from '../page-wrapper/components/left-column';

export const PollPageWrapper = styled(PageWrapperComponent as AnyStyledComponent)``;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
`;

export const LeftColumn = styled(LeftColumnComponent)``;

export const CmdIcon = styled(Image)`
  width: 12px;
  height: 12px;
`;

export const KeyText = styled.p`
  color: #0094d3;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export const RightColumn = styled.div`
  ${RightColumnStyle}
  width: 250px;
  background-color: transparent;
  margin-left: 2px;
  padding: 49px 0 0 0;
  display: flex;
  flex-direction: column;
  border: none;
`;

export const SearchWrapper = styled.div<{ marginTop?: boolean }>`
  display: flex;
  padding: 8px;
  height: 49px;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
  align-self: stretch;
  border-radius: 20px;
  border: 1.5px solid rgba(0, 148, 211, 0.75);
  background: rgba(246, 246, 246, 0.5);
`;

export const SearchIcon = styled(Image)`
  width: 30px;
  height: 30px;
`;

export const SearchInput = styled.input`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  &::placeholder {
    opacity: 1;
    color: #4d4d4d;
  }
`;

export const PollFilterWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  border-radius: 20px;
`;

export const PollFilterTitle = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.38px;
`;

export const PollFilterText = styled.div`
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
`;

export const PollFilterRowContainer = styled.div`
  display: flex;
  max-width: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const PollFilterRow = styled.div`
  display: flex;
  width: 250px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
`;

export const PollFilterLine = styled.div`
  background-color: #f6f6f6;
  height: 1px;
  width: 100%;
`;

export const PollFilterDivider = styled.div`
  width: 350px;
  height: 1px;
  background-color: #f6f6f6;
`;

export const PollFilterRowTitle = styled.div`
  display: flex;
  padding: 7px 5px 7px 0px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #474747;
  text-transform: lowercase;
`;

export const PollFilterRowItem = styled.div<{ isActive?: boolean; isExcludeActive?: boolean; isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 7px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 6px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  border: 1px solid rgba(228, 228, 228, 0.1);
  background: rgba(228, 228, 228, 0.2);
  color: #717171;
  padding: 7px 7px 7px 9px;
  ${({ isActive, isDisabled }) =>
    isActive &&
    !isDisabled &&
    css`
      padding: 7px 7px 7px 9px;
      border: 1px solid transparent;

      background: rgba(226, 234, 255, 0.6);
      color: #003fe1;
    `}
  ${({ isExcludeActive, isDisabled }) =>
    isExcludeActive &&
    !isDisabled &&
    css`
      color: #363636;
      padding: 7px 7px 7px 9px;
      border: 1px solid transparent;
      background: rgba(220, 220, 220, 0.5);
      & > img {
        padding: 1px;
      }
    `}
    ${({ isDisabled }) =>
    isDisabled &&
    css`
      border-radius: 8px;
      border: 1px solid transparent;
      color: rgba(197, 197, 197, 0.8);
      background: rgba(228, 228, 228, 0.1);
    `};
`;

export const Hidden = styled(Image)`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
`;

export const FilterButtonImage = styled(Image)`
  width: 10px;
  height: 10px;
`;

export const SectionDivider = styled.div`
  width: 324px;
  height: 1px;
  background: #e8e8e8;
  margin-left: 46px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 0.7px;
`;

export const HomePageDivider = styled.div`
  background-color: ${COLORS.dividerColor};
  margin: 2.5rem 7.5rem 2.5rem 7rem;
  height: 3px;
`;

export const titleSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  letter-spacing: 0.3px;
`;

export const descriptionSectionStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.descriptionColorInBlock};
`;

export const AddButton = styled.button`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  color: #0018f1;
  background: #e9eaf5;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddButtonIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

export const SentimentSwingsWrapper = styled(BoxAroundSection)``;

export const HeaderContainer = styled.div`
  padding: 15px 0;
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: -46px;
    width: 1px;
    height: 101vh;
    background-color: #efefef;
    z-index: 2;
  }
`;

export const Title = styled.div<{ isAnon?: boolean }>`
  ${titleSectionStyle}
  color: ${({ isAnon }) => (isAnon ? '#7400CF' : COLORS.black)};
  font-size: 15px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 30px;
  width: fit-content;
`;

export const AddPollButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 556px;
  height: 49px;
  margin: 0 17px;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1.5px solid #9aa4f9;
  background: rgba(209, 213, 255, 0.35);
`;

export const AddPolText = styled.p`
  color: #0018f1;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const AddPolIcon = styled(Image)`
  width: 30px;
  height: 30px;
`;

export const HeadPoll = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 27px;
`;

export const HeadWrapperPoll = styled.div`
  display: flex;
  width: 600px;
  padding-left: 0;
  align-items: center;
  height: 30px;
  width: 100%;
  padding-right: 32px;

  & > span {
    margin-left: auto;
    color: #808080;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.m};
    letter-spacing: -0.28px;
    padding-top: 2px;
    margin-right: 1px;
  }
`;

export const PollCreateButton = styled.button`
  display: flex;
  height: 20px;
  padding-top: 2px;
  align-items: center;
  gap: 6px;
  color: #0056f6;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.32px;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 20px;
`;

export const PollCreateButtonIcon = styled(Image)`
  width: 12px;
  height: 12px;
  margin-bottom: 2px;
`;
