import {
  sectorsNotShowDependsOnJobVertical,
  subSectorNotShowDependsOnJobVertical,
  jobVerticalMin,
  VerticalArrays,
  RolesArrays
} from '@/common/components/onboard/components/vertical-role-modal';
import { getLocationName } from '@/common/consts/location.const';
import { ISubSector } from '@/common/types/coverage.type';
import { IEducationHistoryResponse, IEmployerHistoryResponse, ILocation, ILocationRegion } from '@/common/types/profile.type';

export const calculateTimeRange = (startDate: string, endDate?: string): string | null => {
  if (!startDate) return null;
  const day = startDate?.split('T')[0].split('-')[2];
  const start = new Date(startDate);
  if (day === '03') return null;
  const dayEnd = endDate?.split('T')[0].split('-')[2];
  if (dayEnd === '03') return null;
  const end = endDate ? new Date(endDate) : new Date();

  const milliseconds = Math.abs(end.getTime() - start.getTime());
  const years = milliseconds / (1000 * 60 * 60 * 24 * 365.25);
  return years.toFixed(1);
};
export const getLocation = (location?: ILocation, locationRegion?: ILocationRegion): string => {
  if (location && locationRegion) {
    return locationRegion.name === 'US' || locationRegion.name === 'Canada'
      ? `${location.name}, ${locationRegion.name}`
      : `${getLocationName(location.name)}`;
  }
  return locationRegion?.name ?? '';
};

const checkVertical = (
  vertical: string,
  subSector: ISubSector | undefined | null
): Partial<Record<'subVertical' | 'vertical' | 'subSector', boolean>> => {
  if (vertical?.startsWith('Non-Finance') && !subSector) return { subSector: true };
  if (vertical === 'Publishing Sole Publisher') return {};
  const foundVertical = VerticalArrays.find((v) => vertical?.startsWith(v.name));
  if (!vertical || !foundVertical) return { vertical: true, subVertical: true };

  const foundSubVertical = foundVertical.verticals?.find((el) => vertical.endsWith(el));
  if (!foundSubVertical && !vertical.startsWith('Non-Finance')) {
    return { subVertical: true };
  }
  return {};
};

export const getUnfilledEmployerProps = (
  employer: IEmployerHistoryResponse
): Partial<Record<keyof IEmployerHistoryResponse | 'subVertical', boolean>> => {
  let unfilledProps: Partial<Record<keyof IEmployerHistoryResponse | 'subVertical', boolean>> = {};

  const { role, startDate, subSector, endDate, vertical } = employer;
  const r = RolesArrays.find((item) => item.relation.includes(vertical))?.roles;
  if (!role) unfilledProps.role = true;
  if (!r?.includes(role || '') && vertical !== 'Non-Finance') unfilledProps.role = true;
  if (!startDate) unfilledProps.startDate = true;
  if (!endDate) unfilledProps.endDate = true;
  const unfilledVertical = checkVertical(vertical, subSector);

  unfilledProps = { ...unfilledProps, ...unfilledVertical };

  return unfilledProps;
};

export const getUnfilledEducationProps = (education: IEducationHistoryResponse) => {
  const unfilledProps: Partial<Record<keyof IEducationHistoryResponse, boolean>> = {};

  const { degree, startDate, endDate } = education;

  if (!degree) unfilledProps.degree = true;
  if (!startDate) unfilledProps.startDate = true;
  if (!endDate) unfilledProps.endDate = true;

  return unfilledProps;
};

export const getUnfilledCoverages = (employer: IEmployerHistoryResponse, isVerticalMissed: boolean) => {
  let unfilledProps: Partial<Record<keyof IEmployerHistoryResponse, boolean>> = {};

  const { regions, sectors, subSectors, companies, role } = employer;

  if (!isVerticalMissed) {
    if (regions?.length === 0 && !employer.vertical?.startsWith('Non-Finance')) unfilledProps.regions = true;

    if (sectors?.length === 0 && !sectorsNotShowDependsOnJobVertical.includes(employer.vertical) && role !== 'Management') {
      unfilledProps.sectors = true;
      unfilledProps.subSectors = true;
    }

    if (subSectors?.length === 0 && subSectorNotShowDependsOnJobVertical.includes(employer.vertical) && role !== 'Management') {
      const { subSectors: currentSubSectors, ...rest } = unfilledProps;
      unfilledProps = rest;
    }

    if (companies?.length === 0 && jobVerticalMin.includes(employer.vertical) && role !== 'Management') unfilledProps.companies = true;
  }
  return unfilledProps;
};
