import React, { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useProfile, useProfileByUserId, useAuth, useAuthProfile, useSidebarWidth } from '@hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { SHOW_POSTS } from '@/common/consts';
import * as Styled from './profile.styled';
import { PostsComponent } from './components/posts';
import { quotePostModalWrapper } from './components/new-quote-modal/new-quote-modal-wrapper.component';
import { ReplyModalWrapper } from './components/reply-modal';
import { LoaderWrapper } from '../page-wrapper';
import { Loader } from '../loader';
import { ProfileRightColumnComponent } from './profile-right-column.component';

export const ProfileComponent = () => {
  const { isUserLoading, user } = useAuth();
  const { query } = useRouter();
  const { id } = query;
  const sidebarWidth = useSidebarWidth();

  const {
    data: profile,
    refetch: refetchProfile,
    isLoading: isProfileLoading
  } = id ? useProfile(id.toString()) : useProfileByUserId(user?.id, 'PROFILE');

  const { data: userProfileId, isLoading: isUserProfileLoading } = useAuthProfile();

  const ref = useRef<HTMLDivElement | null>(null);
  const leftContainerRef = useRef<HTMLDivElement | null>(null);

  const isCurrent = useMemo(() => profile?.userId === user?.id, [profile?.userId, user?.id]);

  const scrollToTop = () => {
    if (leftContainerRef.current) {
      leftContainerRef.current.scrollTo(0, 0);
    }
  };

  const { openReplyModal, componentToView: componentToViewReplyModal } = ReplyModalWrapper({});

  const { quotePostHandler, componentToView } = quotePostModalWrapper();

  return (
    <Styled.ProfilePageWrapper
      contentLeftMargin="16px"
      isLoading={isUserLoading || isProfileLoading || !profile || isUserProfileLoading || !ref.current}
      sidebarActive={isCurrent ? 'Profile' : ''}
      sidebarProfileHandler={() => scrollToTop()}
      ref={ref}
      disableScroll
    >
      {componentToView}
      {componentToViewReplyModal}
      <AnimatePresence>
        {!!profile && profile.id && !!user && !!userProfileId ? (
          <motion.div
            transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Styled.ColumnsWrapper>
              <Styled.LeftColumnDivider sidebarWidth={sidebarWidth} />
              <Styled.LeftColumn ref={leftContainerRef}>
                {SHOW_POSTS && (
                  <PostsComponent
                    isCurrent={isCurrent}
                    profileId={profile.id}
                    profileName={profile.fullName.split(' ')[0]}
                    userProfileId={userProfileId}
                    quotePostHandler={quotePostHandler}
                    onClickByQuotePost={openReplyModal}
                  />
                )}
              </Styled.LeftColumn>
              <Styled.LeftColumnDivider sidebarWidth={sidebarWidth + 600} />
              <ProfileRightColumnComponent profile={profile} refetchProfile={refetchProfile} isProfileLoading={isProfileLoading} />
            </Styled.ColumnsWrapper>
          </motion.div>
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </AnimatePresence>
    </Styled.ProfilePageWrapper>
  );
};
