import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import Plus from '@styles/icons/submit-idea-plus.webp';
import ArrowTop from '@styles/icons/idea-modal-arrow-top.svg';
import ArrowBottom from '@styles/icons/idea-modal-arrow-bottom.svg';
import ArrowTopRight from '@styles/icons/arrow-top-right.svg';
import StarIcon from '@styles/icons/rated-compelling.png';
import * as Styled from './idea-modal.styled';
import CloseIcon from '@/styles/icons/close-modal-icon.webp';
import { CaseType, IMemo } from '@/common/types/memo.type';
import { FlexComponent } from '@/common/components/flex';
import { formatDate, isToday } from '@/common/utils/day-month-year';
import { getFormatedNumber } from '@/common/utils/get-formate-number';
import { useMemosPostId } from '@/common/hooks/use-publish-memo';
import { PostRowComponent } from '@/common/components/profile/components/post-row';
import { quotePostModalWrapper } from '@/common/components/profile/components/new-quote-modal/new-quote-modal-wrapper.component';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { NO_MARKET_VALUE_TEXT } from '@/common/consts/memo.const';
import { ImagesContainer, ImagesPlus } from '../memo-first-section';
import { Loader } from '@/common/components/loader';

interface IProps {
  closeHandler: () => void;
  open: boolean;
  item: IMemo;
  onNext?: () => void;
  onPrevious?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  withoutPagination?: boolean;
}

const getRegionName = (name: string): string => {
  if (name === 'North America') return 'N America';
  return name;
};

function removeFirstNewlineIfMultiple(input: string): string {
  const newlineCount = (input?.match(/\n/g) || [])?.length;
  if (newlineCount <= 1) {
    return input;
  }
  return input.replace('\n', '');
}

export const IdeaModalComponent = ({
  closeHandler,
  isFirst,
  isLast,
  open,
  item: memo,
  onNext,
  onPrevious,
  withoutPagination
}: IProps): ReactElement | null => {
  const router = useRouter();
  const [isScrollDisabled] = useState<boolean>(false);
  const previousMemoIdRef = useRef<string | undefined>(undefined);
  const [isLoadingNewMemo, setIsLoadingNewMemo] = useState<boolean>(false);

  const { data: memosPostById, isLoading: isMemoPostLoading, isRefetching } = useMemosPostId(memo?.id, open);

  const { componentToView, quotePostHandler } = quotePostModalWrapper();
  const [isOpenReply, setIsOpenReply] = useState<boolean>(false);
  const [isWriteReply, setIsWriteReply] = useState<boolean>(false);

  useEffect(() => {
    if (previousMemoIdRef.current !== memo?.id) {
      setIsLoadingNewMemo(true);
      previousMemoIdRef.current = memo?.id;
    }
  }, [memo?.id]);

  useEffect(() => {
    if (!isMemoPostLoading && !isRefetching) {
      setIsLoadingNewMemo(false);
    }
  }, [isMemoPostLoading, isRefetching]);

  useEffect(() => {
    setIsWriteReply(false);
    setIsOpenReply(false);
  }, [memo]);

  if (isMemoPostLoading || isLoadingNewMemo) {
    return (
      <Styled.IdeaModalWrapper
        open={open}
        closeHandler={closeHandler}
        childTopPostion={15}
        isScrollDisabled={isScrollDisabled}
        isCreateModal
      >
        <FlexComponent flex={1} paddingBottom="35px" justifyContent="center" alignItems="center">
          <Loader />
        </FlexComponent>
      </Styled.IdeaModalWrapper>
    );
  }

  const summary = memosPostById?.summary;
  const isShort = memo?.direction === 'SHORT';
  const bearCaseSection = memosPostById?.memosSection.find(
    (section) => section?.memoSection?.type === CaseType.BEAR_CASE && section?.memoSection?.return < 0
  );
  const profile = summary && summary[0]?.profile;
  const currency = memo?.currency;
  const baseCaseSection = memosPostById?.memosSection?.find((section) => section?.memoSection?.type === CaseType.BASE_CASE);
  const baseCaseReturn = baseCaseSection?.memoSection?.return!;
  const displayText = baseCaseSection?.memoSection?.text;
  const emptyValue = <Styled.EmptyTextValue>—</Styled.EmptyTextValue>;
  const isRepliesExist = summary?.length && summary[0]?.tread.replies.length !== 0;
  const isLinkOrDocumentExists = memo?.link || memo?.documentUrl;

  const onOpenReplies = () => {
    setIsOpenReply((prev) => !prev);
    if (isRepliesExist) {
      setIsWriteReply(false);
    } else {
      setIsWriteReply(true);
    }
  };

  const onWriteReplyIconClick = () => {
    setIsWriteReply(true);
    setIsOpenReply(true);
  };

  const memoDate = memo?.writedAt ? new Date(new Date(memo.writedAt).setDate(new Date(memo.writedAt).getDate() - 1)) : undefined;
  const regionName = getRegionName(baseCaseSection?.memo?.region?.name!);
  const currencyRender = <span>{currency}</span>;

  return (
    <Styled.IdeaModalWrapper open={open} closeHandler={closeHandler} childTopPostion={15} isScrollDisabled={isScrollDisabled} isCreateModal>
      <>
        <FlexComponent alignItems="flex-start" justifyContent="space-between">
          <div>
            <Styled.FlexComponentBox paddingBottom="2px" gap="5px" alignItems="baseline">
              <Styled.CompanyText isColored isShort={isShort}>
                {baseCaseSection?.memoSection?.companyName!}
              </Styled.CompanyText>
              <Styled.DirectionText isColored isShort={isShort}>
                {isShort ? 'Short' : 'Long'}
              </Styled.DirectionText>
              {/* {bearCaseSection && (
                <FlexComponent gap="2px" alignItems="baseline">
                  <Styled.RiskXText isColored isShort={isShort}>
                    {bearCaseSection?.memoSection?.risk}X
                  </Styled.RiskXText>
                  <Styled.RiskText isColored isShort={isShort}>
                    R/R
                  </Styled.RiskText>
                </FlexComponent>
              )} */}
            </Styled.FlexComponentBox>
            {/* <FlexComponent marginTop="8px" gap="10px" alignItems="baseline">
              {Boolean(baseCaseSection?.memoSection?.priceTarget) && (
                <Styled.PriceTargetText>
                  {currency} {baseCaseSection?.memoSection?.priceTarget} price target
                </Styled.PriceTargetText>
              )}
              {!!baseCaseReturn && (
                <Styled.PriceTargetPercentsText>
                  {baseCaseReturn > 0 ? `+${baseCaseReturn}` : baseCaseReturn}% {baseCaseReturn > 0 ? 'upside' : 'downside'}
                </Styled.PriceTargetPercentsText>
              )}
            </FlexComponent> */}
            <FlexComponent marginTop="2px" gap="5px">
              {baseCaseSection?.memo?.subSector?.name && (
                <Styled.SubSectorText>{baseCaseSection?.memo?.subSector?.name}</Styled.SubSectorText>
              )}
              {baseCaseSection?.memo?.subSector?.name && <Styled.DotText>•</Styled.DotText>}
              <Styled.SubSectorText>{regionName}</Styled.SubSectorText>
            </FlexComponent>
          </div>
          <Styled.CloseButton onClick={closeHandler}>
            <Styled.CloseButtonImage src={CloseIcon} alt="close-icon" />
          </Styled.CloseButton>
        </FlexComponent>
        <Styled.Divider mt={10} />
        <FlexComponent marginTop="10px" gap="5px" alignItems="baseline">
          <Styled.ThesisTitle>Thesis Summary</Styled.ThesisTitle>
          <Styled.WrittenByText>
            {memo?.writedAt
              ? isToday(memoDate!)
                ? `written ${formatDate(memo.writedAt, undefined, false)}`
                : `originally written ${formatDate(memo.writedAt, undefined, false)}`
              : `written ${formatDate(memo?.createdAt)}`}
          </Styled.WrittenByText>
          {memo?.rating && memo.rating === 3 && (
            <FlexComponent position="relative" gap="5px" ml="auto" py="3px" alignItems="baseline">
              <Styled.StarIcon src={StarIcon} alt="Rating" />
              <Styled.RatingTitle>rated compelling</Styled.RatingTitle>
            </FlexComponent>
          )}
        </FlexComponent>
        <Styled.ThesisText>{removeFirstNewlineIfMultiple(displayText!)}</Styled.ThesisText>
        {!!summary?.length && (
          <Styled.ActionsBox marginTop="0px" leftMargin>
            <PostRowComponent
              post={summary[0]}
              profileId={memo?.profileId}
              userProfileId={profile?.id ?? undefined}
              isPublish
              isTreadHidden
              isIdeaModal
              openTreadOnMemo={!!summary[0]?.tread?.replies?.length}
              customOpenReply={onWriteReplyIconClick}
              quotePostHandler={quotePostHandler}
            />
          </Styled.ActionsBox>
        )}
        <Styled.OptionsButton onClick={() => router.push(`${ROUTER_KEYS.MEMO_PUBLISH}/${memo?.id}`)} marginLeft="auto" minWidth="540px">
          <Styled.ArrowIconSmall src={ArrowTopRight} alt="Arrow top" />
          {isLinkOrDocumentExists ? 'read extended summary + view original document' : 'read extended summary'}
        </Styled.OptionsButton>
        <Styled.Divider mt={16} />
        <FlexComponent gap="49px" marginTop="6px">
          <Stack minWidth="228px">
            <FlexComponent gap="49px">
              <Stack gap="8px" minWidth="228px">
                <Styled.Row>
                  <Styled.OptionalTitle>{memo?.author ? 'original author' : 'author'}</Styled.OptionalTitle>
                  <Styled.OptionalText>{memo?.author ? memo?.author : memo?.isAnon ? 'anonymous ' : profile?.fullName}</Styled.OptionalText>
                </Styled.Row>
              </Stack>
              {/* {memo?.author && (
                <Stack gap="8px" minWidth="228px">
                  <Styled.Row>
                    <Styled.OptionalTitle>Original author</Styled.OptionalTitle>
                    <Styled.OptionalText>{memo?.author}</Styled.OptionalText>
                  </Styled.Row>
                </Stack>
              )} */}
            </FlexComponent>
            <Styled.Row>
              <Styled.OptionalTitle>share price</Styled.OptionalTitle>
              <Styled.OptionalText>
                {currencyRender} {memo?.sharePrice ? memo?.sharePrice?.toFixed(2) : NO_MARKET_VALUE_TEXT}
              </Styled.OptionalText>
            </Styled.Row>
            <Styled.Row>
              <Styled.OptionalTitle>ytd range</Styled.OptionalTitle>
              <Styled.OptionalText>
                {currencyRender} {memo?.yearLow?.toFixed(2)} - {memo?.yearHigh?.toFixed(2)}
              </Styled.OptionalText>
            </Styled.Row>
            {/* <Styled.Row>
              <Styled.OptionalTitle>Short Interest</Styled.OptionalTitle>
              <Styled.OptionalText>{memo?.shortInterest ? `${memo?.shortInterest}%` : emptyValue}</Styled.OptionalText>
            </Styled.Row> */}
          </Stack>
          <Stack>
            <Styled.Row>
              <Styled.PublishText>as of publish date {formatDate(memo?.createdAt, true)}</Styled.PublishText>
            </Styled.Row>
            <Styled.Row>
              <Styled.OptionalTitle>market cap</Styled.OptionalTitle>
              <Styled.OptionalText>
                {currencyRender} {memo?.marketCap ? getFormatedNumber(memo?.marketCap) : NO_MARKET_VALUE_TEXT}
              </Styled.OptionalText>
            </Styled.Row>
            <Styled.Row>
              <Styled.OptionalTitle>adtv approx.</Styled.OptionalTitle>
              <Styled.OptionalText>
                {currencyRender} {memo?.adtvApprox ? getFormatedNumber(memo?.adtvApprox) : NO_MARKET_VALUE_TEXT}
              </Styled.OptionalText>
            </Styled.Row>
          </Stack>
        </FlexComponent>
        <Styled.Divider mt={6} />
        {(isRepliesExist || isWriteReply) && <Styled.ReplyTitle>Replies to this thesis summary:</Styled.ReplyTitle>}
        {!isRepliesExist && !isWriteReply && !isRefetching && (
          <FlexComponent mt="16px">
            <Styled.AddReplyButtonButton onClick={onOpenReplies}>
              <ImagesContainer>
                <ImagesPlus src={Plus} alt="Add reply" />
              </ImagesContainer>
              reply to this thesis
            </Styled.AddReplyButtonButton>
          </FlexComponent>
        )}
        {!!summary?.length && (!!isRepliesExist || isWriteReply) && (
          <Styled.ActionsBox marginTop="7px">
            <PostRowComponent
              post={summary[0]}
              profileId={memo?.profileId}
              isReplyOpen={isWriteReply || isOpenReply}
              userProfileId={profile?.id ?? undefined}
              isHidePostStats
              isPublish
              isIdeaModal
              isDefaultStats
              openTreadOnMemo={!!isWriteReply || !!isOpenReply || !!isRepliesExist}
              customCloseHandler={() => {
                setIsWriteReply(false);
                setIsOpenReply(false);
              }}
              quotePostHandler={quotePostHandler}
            />
          </Styled.ActionsBox>
        )}
        <Styled.BottomContainer withoutPagination={withoutPagination}>
          {!withoutPagination && (
            <FlexComponent gap="14px">
              <Styled.ControlButton onClick={onPrevious} disabled={isFirst}>
                <Styled.ArrowIcon isDisabled={isFirst} src={ArrowTop} alt="Arrow top" />
                previous idea
              </Styled.ControlButton>
              <Styled.ControlButton onClick={onNext} justifyContent="flex-end" disabled={isLast}>
                next idea <Styled.ArrowIcon isDisabled={isLast} src={ArrowBottom} alt="Arrow bottom" />
              </Styled.ControlButton>
            </FlexComponent>
          )}
        </Styled.BottomContainer>
        {componentToView}
      </>
    </Styled.IdeaModalWrapper>
  );
};
