import React, { MouseEvent, useState } from 'react';
import RepliedPostIcon from '@styles/icons/replied-post-preview-icon.webp';
import { useRouter } from 'next/router';
import * as Styled from './replied-post.preview.styled';
import { ConvertFromMentionTypeToAlgoliaType, ISubPost, MentionType } from '@/common/types/post.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { LinkPreviewComponent } from '../link-preview';
import { FONTS } from '@/theme';

interface IProps {
  post: ISubPost;
}

export const RepliedPostPreview: React.FC<IProps> = ({ post }) => {
  const [isPostCollapsed, setIsPostCollapsed] = useState(true);

  const { push } = useRouter();
  const mentionClick = (text: string, type: MentionType, value: string) => {
    if (type === MentionType.USER) {
      push({
        pathname: ROUTER_KEYS.PROFILE,
        query: {
          id: value
        }
      });
      return;
    }
    const algoliaType = ConvertFromMentionTypeToAlgoliaType[type];
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        type: algoliaType,
        value: text.slice(1),
        clickedId: value,
        clickedValue: text.slice(1),
        isClickOnMention: true
      }
    });
  };

  const onClickByMoreButton = (e: MouseEvent) => {
    e.stopPropagation();
    setIsPostCollapsed(false);
  };

  const onClickClose = (e: MouseEvent) => {
    e.stopPropagation();
    setIsPostCollapsed(true);
  };

  const getMentonsArray = (text: string, ctx: CanvasRenderingContext2D) => {
    const pattern = /!\[(.*?)\{(.*?)\|(.*?)\}\]/g;
    const mentionsJSX = [];
    let lastIndex = 0;
    let match;

    const fontSize = 12;
    ctx.font = `${FONTS.WEIGHTS.light} ${fontSize}px ${FONTS.FAMILIES.robotoFlex}`;

    // eslint-disable-next-line no-cond-assign
    while ((match = pattern.exec(text)) !== null) {
      const [, mentionText, mentionType, mentionValue] = match;
      const mentionTextWithoutTrigger = mentionText.slice(1);
      const jsxElement = (
        <Styled.Mention
          onClick={(e) => {
            e.stopPropagation();
            mentionClick(mentionText, mentionType as MentionType, mentionValue);
          }}
          key={`${mentionText}-${lastIndex}`}
        >
          {mentionTextWithoutTrigger}
        </Styled.Mention>
      );

      const subText = text.slice(lastIndex, match.index);

      mentionsJSX.push({ outputElement: subText, text: subText, width: ctx.measureText(subText).width, isMention: false });
      mentionsJSX.push({
        outputElement: jsxElement,
        text: mentionTextWithoutTrigger,
        width: ctx.measureText(mentionTextWithoutTrigger).width,
        isMention: true
      });

      lastIndex = pattern.lastIndex;
    }
    const lastSubText = text?.slice(lastIndex);
    mentionsJSX.push({ outputElement: lastSubText, text: lastSubText, width: ctx.measureText(lastSubText).width, isMention: false });
    return mentionsJSX;
  };

  const getText = (text: string) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return text;
    const mentionsJSX = getMentonsArray(text, ctx);

    let textWidth = mentionsJSX.reduce((sum, { width }) => width + sum, 0);
    if (!isPostCollapsed) {
      return mentionsJSX.map(({ outputElement }) => outputElement);
    }
    const maxWidth = 400;
    const moreButtonAndDotsWidth = 50;

    if (textWidth > maxWidth) {
      let truncatedText = mentionsJSX;
      let left = 0;
      let right = mentionsJSX.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedText = mentionsJSX.slice(0, middle);
        textWidth = truncatedText.reduce((sum, { width }) => width + sum, 0);

        if (textWidth > maxWidth - moreButtonAndDotsWidth) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }
      truncatedText = mentionsJSX.slice(0, right);

      const truncatedTextWidth = truncatedText.reduce((sum, { width }) => width + sum, 0);

      if (
        truncatedTextWidth > maxWidth - moreButtonAndDotsWidth ||
        (truncatedTextWidth < maxWidth - moreButtonAndDotsWidth && !!mentionsJSX[right] && mentionsJSX[right].isMention) ||
        (truncatedTextWidth < maxWidth - moreButtonAndDotsWidth && !mentionsJSX[right])
      ) {
        truncatedText.push({ outputElement: '..', text: '..', width: 15, isMention: false });
        truncatedText.push({
          outputElement: (
            <button type="button" onClick={onClickByMoreButton}>
              more
            </button>
          ),
          text: ' more',
          width: 25,
          isMention: false
        });
        return truncatedText.map(({ outputElement }) => outputElement);
      }

      if (truncatedTextWidth < maxWidth - moreButtonAndDotsWidth && mentionsJSX[right] && !mentionsJSX[right].isMention) {
        const subText = mentionsJSX[right].text;
        let truncatedSubText = subText;
        let subTextLeft = 0;
        let subTextRight = truncatedSubText.length - 1;

        while (subTextLeft <= subTextRight) {
          const middle = Math.floor((subTextLeft + subTextRight) / 2);
          truncatedSubText = subText.slice(0, middle);
          textWidth = truncatedTextWidth + ctx.measureText(truncatedSubText).width;

          if (textWidth > maxWidth - moreButtonAndDotsWidth) {
            subTextRight = middle - 1;
          } else {
            subTextLeft = middle + 1;
          }
        }

        truncatedText.push({
          outputElement: truncatedSubText,
          text: truncatedSubText,
          width: textWidth - truncatedTextWidth,
          isMention: false
        });
        truncatedText.push({ outputElement: '.. ', text: '.. ', width: 15, isMention: false });
        truncatedText.push({
          outputElement: (
            <button type="button" onClick={onClickByMoreButton}>
              more
            </button>
          ),
          text: ' more',
          width: 25,
          isMention: false
        });
        return truncatedText.map(({ outputElement }) => outputElement);
      }
    }

    if (!!post.linkPreviews.length || !!post.imageUrl) {
      const outputJSX = mentionsJSX;
      outputJSX.push({
        outputElement: (
          <button type="button" onClick={onClickByMoreButton}>
            more
          </button>
        ),
        text: ' more',
        width: 25,
        isMention: false
      });
      return outputJSX.map(({ outputElement }) => outputElement);
    }

    return mentionsJSX.map(({ outputElement }) => outputElement);
  };

  return (
    <Styled.CollapsedDisplayWrapper isCollapsed={isPostCollapsed}>
      <Styled.IconWrapper src={RepliedPostIcon} alt="Replied Post Icon" />
      <Styled.RepliedPostInfoWrapper>
        <Styled.RepliedPostTextWrapper onClick={onClickClose} isCollapsed={isPostCollapsed}>
          {getText(post.text)}
        </Styled.RepliedPostTextWrapper>
        {!isPostCollapsed && post.imageUrl && <Styled.RepliedPostImage src={post.imageUrl} alt="Replied Post Image" />}
        {!isPostCollapsed &&
          !!post.linkPreviews.length &&
          post.linkPreviews.map((it, index) => <LinkPreviewComponent data={it} key={`link-preview-${post.id}-${index}`} />)}
      </Styled.RepliedPostInfoWrapper>
    </Styled.CollapsedDisplayWrapper>
  );
};
