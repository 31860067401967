import React from 'react';
import RepostIcon from '@styles/icons/repost-tile.webp';
import * as Styled from './repost.styled';
import { DateTimeOutput } from '@/common/components/post/components/date';

interface IProps {
  fullName: string;
  date: Date;
  onClickByFullName: () => void;
  isInModal?: boolean;
}

export const RepostTileComponent = ({ fullName, date, onClickByFullName, isInModal }: IProps) => (
  <Styled.Wrapper isInModal={isInModal}>
    <Styled.Icon src={RepostIcon} alt="Repost" />
    <Styled.FulNameAndDateWrapper>
      <Styled.FullNameText onClick={onClickByFullName}>{fullName}</Styled.FullNameText>
      <DateTimeOutput date={date} isRepost />
    </Styled.FulNameAndDateWrapper>
  </Styled.Wrapper>
);
