import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useAuth,
  useProfileIdeas,
  useAuthProfile,
  getIsNonFinanceUser,
  getIsAnonUser,
  useQuerySentimentProfileQuestions,
  useWorkHistory
} from '@hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { INIT_JUMP_TO_STATUS } from '@/common/consts';
import { BasicStatusComponent } from './components/basic-status';
import * as Styled from './profile.styled';
import { WorkHistoryComponent } from './components/work-history';
import { KeyDebatesSectionComponent, SecuritySectionComponent } from './components/security-coverage-key-debates-section';
import { LoaderWrapper } from '../page-wrapper';
import { Loader } from '../loader';
import { MemoSectionComponent } from './components/memo-section';
import { JumpToComponent } from './components/jump-to';
import { JumpTo, SectionStatusType } from '@/common/types';
import { IProfile } from '@/common/types/profile.type';
import { useMemosOnProfile } from '@/common/hooks/use-memo';

interface IProps {
  profile?: IProfile;
  isProfileLoading: boolean;
  refetchProfile: () => void;
}
export const ProfileRightColumnComponent: React.FC<IProps> = ({ profile, isProfileLoading, refetchProfile }) => {
  const { user } = useAuth();
  const isFirstRender = useRef(true);
  const { query, push, pathname } = useRouter();
  const { id, activeSectionKey } = query;
  const profileId = profile?.id || '';

  const { data: ideas, refetch, isLoading: isIdeasLoading } = useProfileIdeas(profileId);
  const { data: memosOnProfile, isLoading } = useMemosOnProfile(profileId);
  const { data: questions, isLoading: isQuestionsLoading } = useQuerySentimentProfileQuestions(profileId);
  const { data: history, isLoading: isHistoryLoading } = useWorkHistory(profileId);

  const { isLoading: isUserProfileLoading } = useAuthProfile();

  const leftContainerRef = useRef<HTMLDivElement | null>(null);
  const rightContainerRef = useRef<HTMLDivElement | null>(null);
  const [jumpToStatus, setJumpToStatus] = useState<SectionStatusType>(INIT_JUMP_TO_STATUS);
  const [activeSection, setActiveSection] = useState<JumpTo>(JumpTo.ABOUT);
  const isNonFinanceUser = getIsNonFinanceUser();

  const isCurrent = useMemo(() => profile?.userId === user?.id, [profile?.userId, user?.id]);

  const scrollToTop = () => {
    if (leftContainerRef.current && rightContainerRef.current) {
      leftContainerRef.current.scrollTo(0, 0);
      rightContainerRef.current.scrollTo(0, 0);
    }
  };

  const onClickByActivateSectionButton = (item: JumpTo) => {
    setActiveSection(item);
    push(
      {
        pathname,
        query: { activeSectionKey: item, id }
      },
      undefined,
      { shallow: true }
    );
  };

  const isQuestionNotEmpty =
    !!questions?.aligned?.length ||
    !!questions?.variantAgreed?.length ||
    !!questions?.variantDisagreed?.length ||
    !!questions?.awaitingForResult?.length;

  useEffect(() => {
    if (!activeSectionKey) return;
    Object.values(JumpTo).forEach((item) => item === activeSectionKey && setActiveSection(item));
  }, []);

  useEffect(() => {
    if (id) {
      onClickByActivateSectionButton(JumpTo.ABOUT);
      refetchProfile();
    }
  }, [id]);

  useEffect(() => {
    const activeTabStatus = jumpToStatus[activeSection];
    if (activeTabStatus?.isEmpty && !activeTabStatus?.isLoading) {
      onClickByActivateSectionButton(JumpTo.ABOUT);
    }
  }, [jumpToStatus, activeSection]);

  useEffect(() => {
    if (isFirstRender.current) return;
    scrollToTop();
    onClickByActivateSectionButton(JumpTo.ABOUT);
    if (profile?.id) {
      refetch();
    }
  }, [profile?.id]);

  useEffect(() => {
    if (!ideas) return;
    const isEmpty = (!ideas.ideas?.length && !ideas.isHidden) || (ideas.isIdeasDoesntExist && ideas.isHidden);
    if (!isIdeasLoading && isEmpty) {
      setJumpToStatus((prev) => ({
        ...prev,
        [JumpTo.SECURITY_COVERAGE]: {
          isLoading: false,
          isEmpty: true
        }
      }));
    }
    if (!isIdeasLoading && !isEmpty) {
      setJumpToStatus((prev) => ({
        ...prev,
        [JumpTo.SECURITY_COVERAGE]: {
          isLoading: false,
          isEmpty: false
        }
      }));
    }
  }, [ideas]);

  useEffect(() => {
    if (!questions) return;
    const isEmpty = (!isQuestionNotEmpty && !questions.isHidden) || (questions.isQuestionDoesntExist && questions.isHidden);
    if (!isQuestionsLoading && isEmpty) {
      setJumpToStatus((prev) => ({ ...prev, [JumpTo.KEY_DEBATES]: { isLoading: false, isEmpty: true } }));
    }
    if (!isQuestionsLoading && !isEmpty) {
      setJumpToStatus((prev) => ({ ...prev, [JumpTo.KEY_DEBATES]: { isLoading: false, isEmpty: false } }));
    }
  }, [questions]);

  useEffect(() => {
    if (!memosOnProfile) return;
    if (!isLoading && (memosOnProfile.longMemos.length || memosOnProfile.shortMemos.length)) {
      setJumpToStatus((prev) => ({
        ...prev,
        [JumpTo.MEMOS]: {
          isLoading: false,
          isEmpty: false
        }
      }));
    }
    if (!isLoading && !(memosOnProfile.longMemos.length || memosOnProfile.shortMemos.length)) {
      setJumpToStatus((prev) => ({
        ...prev,
        [JumpTo.MEMOS]: {
          isLoading: false,
          isEmpty: true
        }
      }));
    }
  }, [memosOnProfile]);

  useEffect(() => {
    if (!profile || isProfileLoading) return;
    setJumpToStatus((prev) => ({
      ...prev,
      [JumpTo.ABOUT]: {
        isLoading: false,
        isEmpty: false
      }
    }));
  }, [profile]);

  const isAnon = getIsAnonUser();

  return (
    <AnimatePresence>
      {!isUserProfileLoading && !isProfileLoading && !isIdeasLoading && ideas && profile && user ? (
        <motion.div
          transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Styled.RightColumn ref={rightContainerRef}>
            <Styled.RightColumnWrapper>
              <JumpToComponent activeSection={activeSection} scrollToSection={onClickByActivateSectionButton} jumpToStatus={jumpToStatus} />
              {activeSection === JumpTo.ABOUT && (
                <BasicStatusComponent
                  key={`profile${profile.id}`}
                  isCurrent={isCurrent}
                  profile={profile}
                  currentUserId={user.id}
                  userId={profile.userId}
                  isAnonProfile={isAnon}
                  isAnonUser={!!profile?.isAnon}
                />
              )}
              {activeSection === JumpTo.ROLES && (
                <WorkHistoryComponent
                  history={history ?? undefined}
                  isLoading={isHistoryLoading}
                  isCurrent={isCurrent}
                  isAnonProfile={!!profile?.isAnon}
                />
              )}
              {activeSection === JumpTo.SECURITY_COVERAGE && (
                <SecuritySectionComponent
                  isIdeasLoading={isIdeasLoading}
                  ideas={ideas.ideas}
                  isIdeasHidden={ideas.isHidden}
                  isIdeasDoesntExist={!!ideas.isIdeasDoesntExist}
                  ideasOutputTextForHidden={ideas.outputTextForHidden}
                  isCurrent={isCurrent}
                />
              )}
              {activeSection === JumpTo.KEY_DEBATES && (
                <KeyDebatesSectionComponent
                  isCurrent={isCurrent}
                  questions={questions ?? undefined}
                  isQuestionsLoading={isQuestionsLoading}
                />
              )}
              {activeSection === JumpTo.MEMOS && (
                <MemoSectionComponent
                  memosOnProfile={memosOnProfile ?? undefined}
                  isLoading={isLoading}
                  isActive={activeSection === JumpTo.MEMOS}
                  isCurrent={isCurrent}
                  isNonFinanceUser={isNonFinanceUser}
                />
              )}
            </Styled.RightColumnWrapper>
          </Styled.RightColumn>
        </motion.div>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </AnimatePresence>
  );
};
