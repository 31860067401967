import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { QUERY_KEYS, ROUTER_KEYS } from '../consts/app-keys.const';
import { profileService } from '../services/profile.service';
import { useUpdateSetupStep } from './use-update-setup-step';
import { FilterType, IEditProfile, IFollowsDataResponse, IPostBasicInfo, IProfile, ProfileVisibilitySettings } from '../types/profile.type';
import { setCachedConnections } from './use-connection';
import { useUser } from './use-user';
import { useAllLikesOnPost } from './use-like';
import { useAllClapsOnPost } from './use-clap';
import { useAllRepostsOnPost } from './use-post';
import { IUser } from '../types/user.type';

export const useAuthProfile = () => useQuery([QUERY_KEYS.PROFILE, 'auth'], () => profileService.getAuthProfileId(), { staleTime: 600 });

export const useProfile = (id: string) => {
  const { push } = useRouter();
  return useQuery({
    queryKey: [QUERY_KEYS.PROFILE, id],
    queryFn: () => profileService.getProfile(id),
    enabled: !!id,

    onError: () => {
      push(ROUTER_KEYS.PROFILE);
    }
  });
};

export const useProfileByUserId = (id?: string, key?: string, callback?: (data?: IProfile) => void) =>
  useQuery({
    queryKey: [QUERY_KEYS.PROFILE, id, key],
    queryFn: () => profileService.getProfileByUserId(id),
    onSuccess: (data) => {
      if (!data) return undefined;
      if (callback) {
        callback(data);
      }
      return {
        ...data,
        employer: data.employer?.map((e) => ({
          ...e,
          startDate: e?.startDate ? new Date(e?.startDate) : null,
          endDate: e?.endDate ? new Date(e?.endDate) : null
        }))
      };
    },
    staleTime: 500,
    refetchOnMount: 'always'
  });

export const useProfileByUserIdEditProfile = (id?: string, callback?: (data?: IProfile) => void) =>
  useQuery([QUERY_KEYS.EDIT_PROFILE, id], async () => {
    if (!id) return undefined;
    const data = await profileService.getProfileByUserId(id);
    if (data) {
      if (callback) {
        callback(data);
      }
      return {
        ...data,
        employer: data.employer?.map((e) => ({
          ...e,
          startDate: e?.startDate ? new Date(e?.startDate) : null,
          endDate: e?.endDate ? new Date(e?.endDate) : null
        }))
      };
    }
    return data;
  });

export const useDeleteEducation = () =>
  useMutation({ mutationKey: [QUERY_KEYS.PROFILE], mutationFn: (id: string) => profileService.removeEducation(id) });

export const useDeleteEmployer = () =>
  useMutation({ mutationKey: [QUERY_KEYS.PROFILE], mutationFn: (id: string) => profileService.removeEmployer(id) });

export const useDeleteProfile = () =>
  useMutation({ mutationKey: [QUERY_KEYS.PROFILE], mutationFn: (id: string) => profileService.removeProfile(id) });

export const useDeleteProfileOnPull = (setupStep?: number) => {
  const { mutateAsync: updateSetupStep } = useUpdateSetupStep();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.PROFILE],
    mutationFn: async (id: string) => {
      const data = await profileService.removeProfileOnPull(id);
      return data;
    },
    onSuccess: () => {
      updateSetupStep(setupStep ?? 2);
      queryClient.invalidateQueries(QUERY_KEYS.CURRENT_STATUS);
    }
  });
};

export const useConnectionsById = (id: string, type: string) => {
  const queryClient = useQueryClient();

  return useQuery([QUERY_KEYS.CONNECTION_BY_ID, id, type], () => profileService.getConnections({ id, type }), {
    enabled: true,
    onSuccess: (data) => {
      if (data) {
        setCachedConnections(queryClient, { connection: data.connection, targetConnection: data.userConnection });
      }
    }
  });
};

export const useAllFollowers = (profileId: string) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId], () =>
    queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId])
  );
  return {
    allData: posts,
    setAllData: (data: IFollowsDataResponse[], newProfileId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId ?? newProfileId], data);
      queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId ?? newProfileId]);
    },
    setNewData: (data: IFollowsDataResponse[], newProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId ?? newProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId ?? newProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId ?? newProfileId]);
    }
  };
};

export const useFollowersById = (id: string, take?: number) => {
  const { setAllData, setNewData } = useAllFollowers(id);
  const {
    data: pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch
  } = useInfiniteQuery(
    [QUERY_KEYS.PROFILE, 'followers', id, take],
    ({ pageParam = 0 }) => profileService.getFollowersById(id, pageParam + 1, take),
    {
      enabled: false,
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * Number(take) >= (Number(lastPage?.count) ?? 0)) return;
        return allPages.length;
      },
      onSuccess: (data) => {
        if (!data) return;
        const pageNumber = data.pages.length;
        const lastPage = data.pages[pageNumber - 1];
        if (!lastPage) return;
        const { data: followersData } = lastPage;
        if (pageNumber === 1) {
          setAllData(followersData);
          return;
        }
        setNewData(followersData, id);
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch,
    count: Number(pages?.pages?.at(0)?.count) || 0,
    metaData: pages?.pages[pages?.pages && pages.pages.length > 0 ? pages.pages.length - 1 : 0]?.metaData
  };
};

export const useAllFollowing = (profileId?: string) => {
  const queryClient = useQueryClient();
  const { data: posts } = useQuery([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId], () =>
    queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId])
  );
  return {
    allData: posts,
    setAllData: (data: IFollowsDataResponse[], newProfileId?: string) => {
      queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId ?? newProfileId], data);
      queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId ?? newProfileId]);
    },
    setNewData: (data: IFollowsDataResponse[], newProfileId: string, isBefore?: boolean) => {
      const oldPosts = queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId ?? newProfileId]);
      const filterOldPosts = (oldPosts ?? []).filter(({ id }) => !data.find(({ id: newId }) => id === newId));
      const newPosts = [...(isBefore ? data : []), ...(filterOldPosts ?? []), ...(!isBefore ? data : [])];
      queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId ?? newProfileId], newPosts);
      queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId ?? newProfileId]);
    }
  };
};

export const useFollowingById = (id: string, take?: number) => {
  const { setAllData, setNewData } = useAllFollowing(id);
  const {
    data: pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch
  } = useInfiniteQuery(
    [QUERY_KEYS.PROFILE, 'following', id, take],
    ({ pageParam = 0 }) => profileService.getFollowingById(id, pageParam + 1, take),
    {
      enabled: false,
      getNextPageParam(lastPage, allPages) {
        if (allPages.length * Number(take) >= (Number(lastPage?.count) ?? 0)) return;
        return allPages.length;
      },
      onSuccess: (data) => {
        if (!data) return;
        const pageNumber = data.pages.length;
        const lastPage = data.pages[pageNumber - 1];
        if (!lastPage) return;
        const { data: followersData } = lastPage;
        if (pageNumber === 1) {
          setAllData(followersData);
          return;
        }
        setNewData(followersData, id);
      }
    }
  );

  return {
    hasNextPageList: hasNextPage,
    isFetchingNextPageList: isFetchingNextPage,
    isListLoading: isLoading,
    fetchNextPageList: fetchNextPage,
    refetchList: refetch,
    count: Number(pages?.pages?.at(0)?.count) || 0,
    metaData: pages?.pages[pages?.pages && pages.pages.length > 0 ? pages.pages.length - 1 : 0]?.metaData
  };
};

export const useUpdateFollowerById = (profileId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    [],
    ({ id, isFollower }: { id: string; isFollower: boolean }) => profileService.getFollowerById(id, isFollower, profileId),
    {
      onSuccess: (data) => {
        if (!data) return;

        const followers = queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId]);
        const newFollowers = followers?.map((follower) => {
          if (follower.id === data.id) return data;
          return follower;
        });
        queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId], newFollowers);
        queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWERS_ALL, profileId]);

        const following = queryClient.getQueryData<IFollowsDataResponse[]>([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId]);
        const newFollowing = following?.map((follower) => {
          if (follower.id === data.id) return data;
          return follower;
        });
        queryClient.setQueryData([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId], newFollowing);
        queryClient.invalidateQueries([QUERY_KEYS.PROFILE_FOLLOWING_ALL, profileId]);
      }
    }
  );
};

export const useMutualAssociations = (userId: string, isCurrent: boolean) =>
  useQuery([QUERY_KEYS.MUTUAL_ASSOCIATION, userId], () => (isCurrent ? 0 : profileService.getMutualAssociations(userId)));

export const useWorkHistory = (id: string) =>
  useQuery({ queryKey: [QUERY_KEYS.WORK_HISTORY, id], queryFn: () => profileService.getWorkHistory(id) });

export const useProfileVisibilitySettings = () =>
  useQuery({
    queryKey: [QUERY_KEYS.VISIBILITY_SETTINGS],
    queryFn: () => profileService.getProfileVisibilitySettings(),
    enabled: true
  });

export const useUpdateProfileVisibilitySettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.VISIBILITY_SETTINGS],
    mutationFn: (data: ProfileVisibilitySettings) => profileService.updateProfileVisibilitySettings(data),
    onSuccess: (data) => {
      if (!data) return;
      queryClient.setQueryData(QUERY_KEYS.VISIBILITY_SETTINGS, data);
    }
  });
};

export const useProfileVisibility = () =>
  useMutation({
    mutationKey: [QUERY_KEYS.PERMISSIONS],
    mutationFn: (profileId: string) => profileService.getPermissionsByProfileId(profileId)
  });

export const useBasicPostInfo = () => {
  const queryClient = useQueryClient();
  const info = queryClient.getQueryData<IPostBasicInfo>([QUERY_KEYS.BASIC_POST_INFO]);

  return info;
};

export const useCheckedBasicPostInfo = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const basicInfo = useBasicPostInfo();

  const {
    data,
    isLoading: isInfoLoading,
    mutateAsync: getBasicInfo
  } = useMutation({
    mutationKey: [QUERY_KEYS.BASIC_POST_INFO],
    mutationFn: () => profileService.getPostBasicInfo(),
    onSuccess: (result) => {
      if (!result) return;
      queryClient.setQueryData([QUERY_KEYS.BASIC_POST_INFO], result);
    }
  });

  useEffect(() => {
    if (!basicInfo) {
      getBasicInfo();
      return;
    }
    if (user && basicInfo && basicInfo.userId !== user.id) {
      getBasicInfo();
    }
  }, [user, basicInfo]);

  return { isInfoLoading, info: basicInfo ?? data };
};

export const useUpdateProfile = (onSuccess?: (data?: IUser | void) => void) =>
  useMutation({
    mutationKey: [QUERY_KEYS.PROFILE_UPDATE],
    mutationFn: (data: IEditProfile) => profileService.editProfile(data),
    onSuccess
  });

export const useUpdateProfileInfoInActionsModal = (postId: string) => {
  const { setUpdateData: updateLikes } = useAllLikesOnPost(postId, false);
  const { setUpdateData: updateClaps } = useAllClapsOnPost(postId, false);
  const { setUpdateData: updateReposts } = useAllRepostsOnPost(postId, false);

  return useMutation([QUERY_KEYS.POST, 'MODAL', postId], (profileId: string) => profileService.getUserInActionModal(profileId), {
    onSuccess: (data) => {
      if (!data) return;
      updateLikes(data, postId);
      updateClaps(data, postId);
      updateReposts(data, postId);
    }
  });
};

export const useGetFilters = (filterType: FilterType) =>
  useQuery([QUERY_KEYS.FILTERS, filterType], () => profileService.getFilters(filterType), {
    refetchOnMount: true
  });

export const useGetHomeModalStatus = () =>
  useQuery([QUERY_KEYS.HOME_MODAL_STATUS], () => profileService.getHomeModalStatus(), {
    refetchOnMount: true
  });

export const useUpdateHomeStatusPostCreated = () => {
  const queryClient = useQueryClient();

  return useMutation(() => profileService.homeStatusUpdatePostCreated(), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.HOME_MODAL_STATUS]);
    }
  });
};

export const useUpdateHomeStatusPrivacyConfirmed = () => {
  const queryClient = useQueryClient();

  return useMutation(() => profileService.homeStatusUpdatePrivacyConfirmed(), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.HOME_MODAL_STATUS]);
    }
  });
};
