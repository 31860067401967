import React from 'react';
import { Stack } from '@mui/material';
import ModeIcon from '@styles/icons/mode-new-icon.png';
import AnoneModeIcon from '@styles/icons/anon-toolbar-icon.png';
import UnActiveAnonModeIcon from '@styles/icons/un-active-anon-new-icon.png';
import UnActiveRealModeIcon from '@styles/icons/un-active-real-icon.png';
import * as Styled from '../../sidebar.styled';

interface AccountModeSwitcherProps {
  isAnon: boolean;
  handleRealSwitchClick: () => void;
  handleAnonSwitchClick: () => void;
}

export const AccountModeSwitcher = ({ isAnon, handleRealSwitchClick, handleAnonSwitchClick }: AccountModeSwitcherProps) => (
  <Stack gap="8px">
    {isAnon ? (
      <Styled.ButtonReal isAnon={!isAnon} onClick={() => handleRealSwitchClick()}>
        <Styled.ModeImg priority src={!isAnon ? ModeIcon : UnActiveRealModeIcon} alt="Mode Icon" />
        <span>Real account</span>
        {!isAnon && <Styled.SidebarDotBottom isAnon={isAnon}>•</Styled.SidebarDotBottom>}
      </Styled.ButtonReal>
    ) : (
      <Styled.ButtonAnon isAnon={isAnon} onClick={() => handleAnonSwitchClick()}>
        <Styled.ModeImg priority src={isAnon ? AnoneModeIcon : UnActiveAnonModeIcon} alt="Mode Icon" />
        <span>Anon account</span>
        {isAnon && <Styled.SidebarDotBottom isAnon={isAnon}>•</Styled.SidebarDotBottom>}
      </Styled.ButtonAnon>
    )}
    {!isAnon ? (
      <Styled.ButtonReal isAnon={!isAnon} onClick={() => handleRealSwitchClick()}>
        <Styled.ModeImg priority src={!isAnon ? ModeIcon : UnActiveRealModeIcon} alt="Mode Icon" />
        <span>Real account</span>
        {!isAnon && <Styled.SidebarDotBottom isAnon={isAnon}>•</Styled.SidebarDotBottom>}
      </Styled.ButtonReal>
    ) : (
      <Styled.ButtonAnon isAnon={isAnon} onClick={() => handleAnonSwitchClick()}>
        <Styled.ModeImg priority src={isAnon ? AnoneModeIcon : UnActiveAnonModeIcon} alt="Mode Icon" />
        <span>Anon account</span>
        {isAnon && <Styled.SidebarDotBottom isAnon={isAnon}>•</Styled.SidebarDotBottom>}
      </Styled.ButtonAnon>
    )}
  </Stack>
);
