import styled from 'styled-components';
import Image from 'next/image';
import { MentionInputComponent } from '../mention-input';

export const ReplyRepostWrapper = styled.div<{ isIdeaModal?: boolean; isIdeaPage?: boolean }>`
  padding-left: 107px;
  padding-right: 33px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ isIdeaModal }) => isIdeaModal && 'padding-left:40px'}
  ${({ isIdeaPage }) => isIdeaPage && 'width: 570px'}
`;

export const ReplyRepostContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputArea = styled(MentionInputComponent)``;

export const ButtonsContainer = styled.div`
  display: flex;
  height: 16px;
  gap: 5px;
  justify-content: space-between;
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const ActionButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const ActionButtonImage = styled(Image)<{ isReply?: boolean; isClose?: boolean }>`
  width: 16px;
  ${({ isReply }) => isReply && 'width: 18px;'}
  height: 16px;
  ${({ isClose }) => isClose && 'width: 10px;height: 10px;'}
  object-fit:cover;
`;
