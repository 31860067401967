import Image from 'next/image';
import styled from 'styled-components';
import { COLORS, FONTS } from '@/theme';

interface IWrapperProps {
  isInModal?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  padding-left: ${(props) => (props.isInModal ? '0px' : '40px')};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* padding-left: 40px; */
`;

export const Icon = styled(Image)`
  width: 18px;
  height: 16px;
`;

export const FulNameAndDateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
  height: fit-content;
`;

export const FullNameText = styled.div`
  color: ${COLORS.fullNameInRepostTileColor};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.semiBold};
  line-height: ${FONTS.SIZES.s};
  cursor: pointer;
`;
