import { motion } from 'framer-motion';
import styled from 'styled-components';
import Image from 'next/image';
import { FAMILIES } from '@/theme/fonts.const';

export const SidebarSearchBox = styled(motion.div)<{ open?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  background: rgba(244, 244, 244, 0.75);
  backdrop-filter: blur(3px);
`;

export const SidebarSearchWrapper = styled.div`
  position: absolute;
  min-width: 513px;
  height: 48px;
  border-radius: 12px;
  padding: 15px 13px;
  border: 1px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(199, 199, 199, 0.25);
`;

export const SidebarSearchInput = styled.input`
  font-family: ${FAMILIES.robotoFlex};
  width: 350px;
  border: none;
  outline: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  &::placeholder {
    color: #bfbfbf;
  }
`;

export const SidebarSearchImage = styled(Image)`
  margin-left: 3px;
  width: 14px;
  height: 14px;
`;

export const SidebarSearchCloseText = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

export const SidebarSearchCloseImage = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const SidebarSearchEnterTextText = styled.p`
  color: rgba(34, 34, 34, 0.7);
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  white-space: nowrap;
`;

export const SidebarSearchEnterImage = styled(Image)`
  position: absolute;
  width: 14px;
  height: 14px;
  right: -20px;
  bottom: 1px;
`;
