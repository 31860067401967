import styled from 'styled-components';

export const ContainerFilters = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  & > div:last-child {
    border: none;
  }
`;
