import styled, { css } from 'styled-components';
import Image from 'next/image';
import { FONTS } from '@/theme';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const Wrapper = styled.div``;

export const SectionWrapper = styled(BoxAroundSection)`
  width: 426px;
  background: transparent;
  color: #000;
  padding: 18px 20px;
`;

const SubsectionWrapperStyle = css`
  width: 100%;
`;

export const SecurityCoverageWrapper = styled.div`
  ${SubsectionWrapperStyle}
`;

export const KeyDebatesWrapper = styled.div`
  ${SubsectionWrapperStyle}
`;

export const SubsectionHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SubsectionHeaderLeft = styled.div`
  width: 100%;
`;

export const SubsectionTitleWrapper = styled.div`
  display: flex;
  height: 30px;
  gap: 10px;
  align-items: center;
  img {
    object-fit: cover;
  }
`;

export const SubsectionTitle = styled.div<{ isAnon: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  letter-spacing: 0.32px;
  ${({ isAnon }) => isAnon && 'color: #7400CF;'}
`;

export const SubsectionDescription = styled.div<{ isAnon?: boolean }>`
  margin-top: 8px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.extra_light};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: #515151;
  ${({ isAnon }) => isAnon && 'color: #C6C6C6;'}
  margin-left: 26px;
`;

export const AddButton = styled.button<{ isAnon?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  color: #0018f1;
  background: #e9eaf5;
  ${({ isAnon }) => isAnon && 'background-color: #393939;color: #D6D6D6;'}
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-left: 26px;
`;

export const AddButtonIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

export const ListsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.m};
  width: 350px
  padding-left: 26px;
`;

export const ListCaption = styled.div<{ isAnon?: boolean }>`
  padding: 4px 0;
  font-weight: ${FONTS.WEIGHTS.medium};
  letter-spacing: 0.7px;
  color: #2b2b2b;
  ${({ isAnon }) => isAnon && 'color: #CECECE;'}
`;

export const ListCaptionLight = styled.span<{ isAnon?: boolean }>`
  color: #7d7d7d;
  ${({ isAnon }) => isAnon && 'color: #9A9A9A;'}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 350px;
  align-items: flex-start;
`;

export const ListDivider = styled.div`
  width: 100%;
  height: 0px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 350px;
  cursor: pointer;
`;

const TextStyle = css`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  overflow: hidden;
`;

export const MemoText = styled.div<{ isLong: boolean; isAnon: boolean }>`
  width: 265px;
  ${TextStyle}
  line-height: ${FONTS.SIZES.m};
  white-space: nowrap;
  overflow: visible;
  ${({ isLong, isAnon }) => (!isLong && !isAnon ? 'color: #4D0EB4;' : !isLong && isAnon ? 'color: #C598FF;' : '')};
  ${({ isLong, isAnon }) => (isLong && !isAnon ? 'color: #009289;' : isLong && isAnon ? 'color: #00BEB2;' : '')};
`;

export const ReplyContainer = styled.div<{ isActive?: boolean; isAnon: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 53px;
  width: 53px;
  & > span {
    line-height: 20px;
  }
  ${TextStyle}
  ${({ isActive, isAnon }) => (isAnon ? (isActive ? 'color: #B8B8B8;' : 'color: #989898;') : isActive ? 'color: #000;' : 'color: #AEAEAE;')}
`;

export const ReplyIcon = styled(Image)`
  width: 18px;
  height: 16px;
`;

export const ArrowImage = styled(Image)`
  width: 12px;
  height: 12px;
  margin-left: -10px;
`;
