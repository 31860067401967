import React from 'react';
import LogoIcon from '@styles/icons/sidebar-logo-new.webp';
import * as Styled from './sidebar-logo.styled';

interface IProps {
  isAnon?: boolean;
}

export const SidebarLogoComponent: React.FC<IProps> = ({ isAnon }) => (
  <Styled.LogoWrapper>
    <Styled.LogoImage priority src={LogoIcon} alt="Sidebar real logo" style={{ visibility: !isAnon ? 'visible' : 'hidden' }} />
    <Styled.LogoText isAnon={isAnon}>Villan</Styled.LogoText>
  </Styled.LogoWrapper>
);
