import Image from 'next/image';
import React, { useEffect } from 'react';
import * as Styled from './post-form-action.styled';
import Checkmark from '@/styles/icons/ok-checkmark.png';
import PressEnterArrow from '@/styles/icons/press-enter-arrow-disabled.webp';
import { useDetectOS } from '@/common/hooks';
import { Loader } from '@/common/components/loader';

interface IPostFormActions {
  isActive: boolean;
  isLoading?: boolean;
  activeCallback: () => void;
  title?: string;
  mt?: number;
  isDisabled?: boolean;
  minW?: number;
  maxPostButtonWidth?: number;
  buttonsBorderRadius?: number;
  buttonsHeight?: number;
}

export const PostFormActions = ({
  isActive,
  maxPostButtonWidth,
  minW,
  buttonsBorderRadius,
  isLoading,
  isDisabled,
  title = 'post',
  activeCallback,
  mt,
  buttonsHeight
}: IPostFormActions) => {
  const os = useDetectOS();
  const isWindows = os === 'Windows';
  const key = isWindows ? 'ctrl' : 'cmd';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isActive && event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !isDisabled && !isLoading) {
        event.preventDefault();
        activeCallback();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive, activeCallback, isDisabled, isLoading]);

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' && !isDisabled && !isLoading) {
      event.preventDefault();
      activeCallback();
    }
  };

  return (
    <Styled.PostFormConfirmBtn
      buttonsBorderRadius={buttonsBorderRadius}
      buttonsHeight={buttonsHeight}
      minW={minW}
      maxPostButtonWidth={maxPostButtonWidth}
      disabled={isDisabled || isLoading}
      mt={mt}
      isActive={isActive}
      onClick={activeCallback}
      onKeyDown={handleButtonKeyDown}
    >
      <Styled.PostPostBtnContent>
        {isLoading ? (
          <Loader width="20" height="20" />
        ) : (
          <>
            {title}
            <Styled.CheckIcon src={Checkmark} width={12} height={12} alt="checkmark" />
          </>
        )}
      </Styled.PostPostBtnContent>
      <Styled.PostPressEnter>
        press {key} + enter
        <Styled.ArrowIcon>
          <Image width={14} height={14} src={PressEnterArrow} alt="arrow" />
        </Styled.ArrowIcon>
      </Styled.PostPressEnter>
    </Styled.PostFormConfirmBtn>
  );
};
