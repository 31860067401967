import { ConnectionStatus, IAssociationResponse } from '../types/connection.type';

export const getConnectionStatus = (
  ownConnection?: IAssociationResponse | null,
  userConnection?: IAssociationResponse | null
): ConnectionStatus => {
  if (ownConnection && ownConnection.connected && userConnection && userConnection.connected) {
    return ConnectionStatus.CONNECTED;
  }

  if (userConnection && userConnection.connectionRequestSent) {
    return ConnectionStatus.CONNECTION_REQUEST_PENDING;
  }

  if (userConnection && userConnection.followRequestSent) {
    return ConnectionStatus.FOLLOW_REQUEST_PENDING;
  }

  if (ownConnection && ownConnection.connectionRequestSent && ownConnection.followRequestSent) {
    return ConnectionStatus.CONNECTION_AND_FOLLOW_REQUESTS_SENT;
  }

  if (ownConnection && ownConnection.connectionRequestSent) {
    return ConnectionStatus.CONNECTION_REQUEST_SENT;
  }

  if (ownConnection && ownConnection.following && userConnection && userConnection.following) {
    return ConnectionStatus.FOLLOW_EACH_OTHER;
  }

  if (ownConnection && ownConnection.followRequestSent) {
    return ConnectionStatus.FOLLOW_REQUEST_SENT;
  }

  if (ownConnection && ownConnection.following) {
    return ConnectionStatus.OWN_FOLLOW;
  }

  if (userConnection && userConnection.following) {
    return ConnectionStatus.USER_FOLLOW;
  }

  return ConnectionStatus.UNASSOCIATED;
};
