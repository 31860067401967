import styled from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { MentionTextComponent } from '../mention-text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Item = styled.div<{ isCentered?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  ${({ isCentered }) => isCentered && 'align-items: center;'}
`;

export const Title = styled.span`
  color: #7c7c7c;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const Text = styled.div`
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const ReviewText = styled(MentionTextComponent)`
  color: #000;
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  font-style: normal;
  word-wrap: normal;
  overflow-wrap: break-word;
  & > span {
    word-wrap: normal;
    overflow-wrap: break-word;
    word-break: normal;
  }
  overflow: hidden;
`;

export const TitleText = styled(Text)<{ isAnon?: boolean }>`
  overflow: hidden;
  text-overflow: eclipsis;
  max-height: 20px;
  ${({ isAnon }) => isAnon && 'color: #fff;'}
`;

export const RecommendedIcon = styled(Image)`
  width: 15px;
  height: 13px;
`;

export const RecommendedCrossIcon = styled(Image)`
  width: 9.75px;
  height: 13px;
`;

export const LinkPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 5px 5px 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 80px;
  cursor: pointer;
  border-radius: 10px;
  background: #fbfbfb;
`;

export const LinkPreviewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  height: fit-content;
`;

export const LinkPreviewDescription = styled.span`
  height: 36px;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 405px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkPreviewImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 7px;
`;

export const EmptyImage = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background: ${COLORS.emptyImageInLinkPreviewBackground};
`;

export const LinkPreviewTitleWrapper = styled.div``;

export const LinkPreviewTitle = styled.div``;

export const LinkPreviewDomain = styled.span`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  letter-spacing: 0.36px;
  color: #5c5c5c;
  font-weight: 300;
`;

export const DomainImage = styled.img`
  width: 20px;
  height: 20px;
`;
