export enum Degree {
  BACHELOR = 'BACHELOR',
  MBA = 'MBA',
  MASTER = 'MASTER',
  DOCTORATE = 'DOCTORATE',
  COURSE = 'COURSE',
  MD = 'MD',
  JD = 'JD'
}

export const degreeName = {
  [Degree.BACHELOR]: 'Bachelor’s',
  [Degree.MASTER]: 'Masters',
  [Degree.MBA]: 'MBA',
  [Degree.DOCTORATE]: 'PhD',
  [Degree.JD]: 'Juris Doctor',
  [Degree.MD]: 'MD',
  [Degree.COURSE]: 'Course'
};

export interface IEducation {
  id: string;
  university: string;
  major: string;
  grade?: string | null;
  degree: Degree;
  startDate: Date | string;
  endDate?: Date | string | null;
  isPulled?: boolean;
  isDescriptionFilled?: boolean;
  description?: string;
  locationId?: string | null;
  locationRegionId?: string | null;
}

export interface IEducationProps {
  education: Partial<IEducation>;
  setEducation: (education: Partial<IEducation>) => void;
  deleteEducation?: (id: string) => void;
}
