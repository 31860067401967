import React, { useState } from 'react';
import AddButtonIcon from '@styles/icons/add-button-icon.webp';
import AddButtonIconAnon from '@styles/icons/add-button-icon-anon.webp';
import Image from 'next/image';
import ProfileKeyDebatesIcon from '@styles/icons/profile-key-debates.webp';
import ProfileKeyDebatesIconAnon from '@styles/icons/profile-key-debates-anon.webp';
import { AnimatePresence, motion } from 'framer-motion';
import * as Styled from './security-coverage-key-debates-section.styled';
import { JumpTo } from '@/common/types';
import { IQuestionProfileResponse, ISentiment } from '@/common/types/question.type';
import { SentimentType } from '@/common/components/question-result';
import { HidenSectionComponent } from '../hiden-section';
import { ReplyModalWrapper } from '../reply-modal';
import { QuestionItemComponent } from './question-item.component';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { HeaderTitleUnderlineWrapper } from '@/common/components/page-wrapper/components/box-around-section';
import { CreateModalComponent } from '@/common/components/create-modal/create-modal.component';
import { CreateModalType } from '@/common/components/create-modal/modal-type.const';
import { getIsAnonUser } from '@/common/hooks';

interface IProps {
  isAnonProfile?: boolean;
  isCurrent: boolean;
  questions?: IQuestionProfileResponse;
  isQuestionsLoading: boolean;
}

export const KeyDebatesSectionComponent: React.FC<IProps> = ({ isAnonProfile, isCurrent, questions, isQuestionsLoading }) => {
  const [questionsModal, setQuestionsModal] = useState(false);

  const isQuestionNotEmpty = !!questions?.aligned?.length || !!questions?.variantAgreed?.length || !!questions?.variantDisagreed?.length;

  const questionsPlusClick = () => {
    setQuestionsModal(true);
  };
  const { openReplyModal, componentToView } = ReplyModalWrapper({});

  const getQuestionsList = (list: ISentiment[], type: SentimentType) => (
    <Styled.ListContainer>
      {list.map((question) => (
        <QuestionItemComponent
          isAnon={!!isAnonProfile}
          type={type}
          question={question}
          openReplyModal={openReplyModal}
          key={`question-${type}-${question.id}`}
        />
      ))}
    </Styled.ListContainer>
  );

  const isAnonUser = getIsAnonUser();

  return (
    <>
      <CreateModalComponent open={questionsModal} closeHandler={() => setQuestionsModal(false)} modalType={CreateModalType.DEBATE} />
      {componentToView}
      <AnimatePresence>
        {!isQuestionsLoading && questions ? (
          ((isQuestionNotEmpty && !questions.isHidden) || (!questions.isQuestionDoesntExist && questions.isHidden)) && (
            <motion.div
              transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styled.SectionWrapper isAnon={isAnonProfile} id={JumpTo.KEY_DEBATES}>
                <Styled.KeyDebatesWrapper>
                  <Styled.SubsectionHeader>
                    <Styled.SubsectionHeaderLeft>
                      <HeaderTitleUnderlineWrapper isAnon={isAnonProfile}>
                        <Styled.SubsectionTitleWrapper>
                          <Image
                            src={isAnonUser ? ProfileKeyDebatesIconAnon : ProfileKeyDebatesIcon}
                            width={16}
                            height={16}
                            alt="profile-key-debates"
                          />
                          <Styled.SubsectionTitle isAnon={isAnonUser}>Key Debates</Styled.SubsectionTitle>
                        </Styled.SubsectionTitleWrapper>
                      </HeaderTitleUnderlineWrapper>
                      <Styled.SubsectionDescription isAnon={isAnonProfile}>
                        Personal opinions only which do not reflect the investments or <br /> views of the member’s employer(s) and is not
                        investment advice
                      </Styled.SubsectionDescription>
                    </Styled.SubsectionHeaderLeft>
                  </Styled.SubsectionHeader>
                  {isCurrent && (
                    <Styled.AddButton isAnon={isAnonProfile} onClick={questionsPlusClick}>
                      <Styled.AddButtonIcon src={isAnonProfile ? AddButtonIconAnon : AddButtonIcon} alt="add" /> new debate
                    </Styled.AddButton>
                  )}
                  <Styled.ListsWrapper isHidden={questions.isHidden}>
                    {questions.isHidden ? (
                      <HidenSectionComponent text={questions.outputTextForHidden ?? 'Visible to connections only'} />
                    ) : (
                      <>
                        {!!questions?.variantDisagreed && !!questions.variantDisagreed.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>more negative</Styled.ListCaptionDark> than consensus
                            </Styled.ListCaption>
                            {getQuestionsList(questions.variantDisagreed, SentimentType.DISAGREED_VARIANT)}
                            {(!!questions.aligned.length || !!questions.variantAgreed.length) && <Styled.ListDivider />}
                          </>
                        )}

                        {!!questions?.aligned && !!questions.aligned.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>aligned</Styled.ListCaptionDark> with consensus
                            </Styled.ListCaption>
                            {getQuestionsList(questions.aligned, SentimentType.ALIGNED)}
                            {!!questions.variantAgreed.length && <Styled.ListDivider />}
                          </>
                        )}

                        {!!questions?.variantAgreed && !!questions.variantAgreed.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>more positive</Styled.ListCaptionDark> than consensus
                            </Styled.ListCaption>
                            {getQuestionsList(questions.variantAgreed, SentimentType.AGREED_VARIANT)}
                          </>
                        )}
                      </>
                    )}
                  </Styled.ListsWrapper>
                </Styled.KeyDebatesWrapper>
              </Styled.SectionWrapper>
            </motion.div>
          )
        ) : (
          <Styled.SectionWrapper isAnon={isAnonProfile}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </Styled.SectionWrapper>
        )}
      </AnimatePresence>
    </>
  );
};
