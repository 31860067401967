import React from 'react';
import ReplyActiveIcon from '@styles/icons/reply-active.webp';
import ReplyNotActiveIcon from '@styles/icons/reply-not-active.webp';
import ReplyActiveIconAnon from '@styles/icons/reply-active-anon.webp';
import ReplyNotActiveIconAnon from '@styles/icons/reply-not-active-anon.webp';
import * as Styled from './security-coverage-key-debates-section.styled';
import { usePostStatistic } from '@/common/hooks';
import ArrowRight from '@/styles/icons/arrow-right-key-debates.webp';
import ArrowRightAnon from '@/styles/icons/arrow-right-new-anon.webp';
import { ISentiment } from '@/common/types/question.type';
import { formatNumber } from '@/common/utils/post-statistics';
import { SentimentType } from '@/common/components/question-result';
import { replaceIfMoreThanMax } from '@/common/utils/max-length-output';

interface IQuestionItemPorps {
  isAnon: boolean;
  question: ISentiment;
  type: SentimentType;
  openReplyModal: (str: string, searchId: string) => void;
}

export const QuestionItemComponent = ({ isAnon, question, type, openReplyModal }: IQuestionItemPorps) => {
  const { data: statistic } = usePostStatistic(question.postId);

  const onClickByQuestion = () => {
    if (type !== SentimentType.AWAITING_FOR_RESULT) {
      openReplyModal(question.postId, question.postId);
    }
  };

  return (
    <Styled.ListItemContainer isAwaitForResult={type === SentimentType.AWAITING_FOR_RESULT} key={question.id} onClick={onClickByQuestion}>
      <Styled.QuestionText isAnon={isAnon} type={type}>
        {replaceIfMoreThanMax(question.text, 265, 14)}
      </Styled.QuestionText>
      {type !== SentimentType.AWAITING_FOR_RESULT && (
        <>
          <Styled.ReplyContainer isAnon={isAnon} isActive={!!statistic?.replyCount}>
            {!statistic?.replyCount ? (
              <>
                <Styled.ReplyIcon src={isAnon ? ReplyNotActiveIconAnon : ReplyNotActiveIcon} alt="reply" />
                <span>reply</span>
              </>
            ) : (
              <>
                <Styled.ReplyIcon src={isAnon ? ReplyActiveIconAnon : ReplyActiveIcon} alt="reply" />
                <span>{formatNumber(statistic?.replyCount)}</span>
              </>
            )}
          </Styled.ReplyContainer>
          <Styled.ArrowImage src={isAnon ? ArrowRightAnon : ArrowRight} alt="arrow" />
        </>
      )}
    </Styled.ListItemContainer>
  );
};
