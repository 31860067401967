/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@/common/consts/app-keys.const';
import { coverageService } from '@/common/services';
import { useSubSectorsBySector } from '@/common/hooks';
import { IEmployer } from '@/common/types/current-employer.type';
import { OTHER_ROLE, RolesArrays, VerticalArrays } from './vertical-role-modal.const';
import * as Styled from './vertical-role-modal.styled';
import { ISector } from '@/common/types/coverage.type';

export const VerticalRoleModalComponent = ({
  employer,
  setEmployer,
  isCurrent,
  onboardStep,
  setIsCurrent,
  isAnon,
  setIsSectorSelected,
  setIsRoleSelected
}: {
  isCurrent: boolean;
  employer?: Partial<IEmployer>;
  setEmployer: (employer: Partial<IEmployer>) => void;
  setIsCurrent: React.Dispatch<React.SetStateAction<boolean>>;
  onboardStep: number;
  isAnon?: boolean;
  setIsSectorSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRoleSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const groupN = VerticalArrays.find((item) => employer?.vertical?.startsWith(item.name))?.name;
  const [group, setGroup] = useState<string | null>(groupN ?? null);
  const secutNonFinInput = useRef<null | HTMLInputElement>(null);
  const verticals = useMemo(() => VerticalArrays.find((item) => item.name === group)?.verticals, [group]);
  const [vertical, setVertical] = useState<string | null>(
    (verticals && [...verticals]?.sort((a, b) => b.length - a.length)?.find((item) => employer?.vertical?.endsWith(item))) ?? null
  );
  const [sector, setSector] = useState<string | null>(employer?.subSector ? employer.subSector.sectorId ?? null : null);
  const [sectorName, setSectorName] = useState<string>('');
  const [subSector, setSubSector] = useState<string | null>(employer?.subSectorId ? employer.subSectorId : null);
  const [role, setRole] = useState<string | null>(employer?.role ?? null);

  // useEffect(() => {
  //   if (vertical) {
  //     const r = RolesArrays.find((item) => item.relation.includes(`${group} ${vertical}`))?.roles;
  //     if (!r?.includes(employer?.role!) || !employer?.role) {
  //       setRole(OTHER_ROLE);
  //       setEmployer({ ...employer, vertical: `${group} ${vertical}`, role: OTHER_ROLE, subSectorId: undefined });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (employer?.role) {
      setIsRoleSelected!(true);
    }
    if (employer?.vertical?.split(' ')[1]) {
      setIsSectorSelected!(true);
    }
  }, []);

  const onClickByGroup = (groupName: string) => {
    const prevGroup = group;
    setGroup((prev) => (groupName === prev ? null : groupName));
    if (
      groupName !== prevGroup &&
      groupName === 'Non-Finance' &&
      !!employer?.isLocationSuccess &&
      !!employer?.isStartDateSuccess &&
      onboardStep === 2
    ) {
      setIsCurrent(true);
    }
    if (groupName !== prevGroup && groupName === 'Non-Finance') {
      setEmployer({ ...employer, vertical: 'Non-Finance', role: '', subSectorId: undefined, sectorId: '' });
    } else {
      setEmployer({ ...employer, vertical: '', role: '', subSectorId: undefined, sectorId: '' });
    }
    setVertical(null);
    setSector(null);
    setSubSector(null);
  };

  useEffect(() => {
    if (onboardStep > 2 && employer?.isDescriptionFilled && !employer?.isDescriptionSuccess) {
      setIsCurrent(false);
    }
  }, [onboardStep]);

  const onClickByVertical = (verticalName: string) => {
    setVertical((prev) => (verticalName === prev ? null : verticalName));
    setIsSectorSelected!(vertical !== null);

    const r = RolesArrays.find((item) => item.relation.includes(`${group} ${verticalName}`))?.roles;
    if (isAnon) {
      setIsSectorSelected!(true);
      setEmployer({ ...employer, vertical: `${group} ${verticalName}`, role: '', subSectorId: undefined });
    }
    if (r && role && !r.includes(role)) {
      setIsRoleSelected!(false);
      setRole(null);
    }
    if (!r) {
      setEmployer({ ...employer, vertical: `${group} ${verticalName}`, role: '', subSectorId: undefined });
      setIsRoleSelected!(false);
      setRole(null);
    }
    if (r && role && r.includes(role)) {
      setIsSectorSelected!(true);
      setEmployer({ ...employer, vertical: `${group} ${verticalName}`, role, subSectorId: undefined });
    }
  };

  useEffect(() => {
    if (isAnon) {
      if (vertical === null) {
        setIsSectorSelected!(false);
        setIsRoleSelected!(true);

        setEmployer({ ...employer, vertical: group, role: '', subSectorId: undefined });
      }
      return;
    }
    if (group && vertical === null && group !== 'Non-Finance') {
      setIsSectorSelected!(false);
      setIsRoleSelected!(false);
      setEmployer({ ...employer, vertical: `${group}`, role: '', subSectorId: undefined });
    }
  }, [vertical]);

  const onClickByRole = (roleName: string) => {
    setRole((prev) => (roleName === prev ? null : roleName));
    if (roleName !== role) {
      setEmployer({ ...employer, vertical: `${group} ${vertical}`, role: roleName, subSectorId: undefined });
      setIsRoleSelected!(true);
      setIsSectorSelected!(vertical !== null);
    } else {
      setIsRoleSelected!(false);
    }
  };

  const roles = useMemo(
    () => group && vertical && RolesArrays.find((item) => item.relation.includes(`${group} ${vertical}`))?.roles,
    [group, vertical]
  );

  const onClickBySector = ({ id: sectorId, name }: ISector) => {
    const prevSector = sector;
    setSector((prev) => (sectorId === prev ? null : sectorId));
    setSectorName(name);
    setSubSector(null);
    setRole(null);
    setEmployer({ ...employer, role: '', subSectorId: undefined, sectorId: prevSector === sectorId ? undefined : sectorId });
  };

  const onClickBySubSector = (subSectorId: string) => {
    const prevSub = subSector;
    setSubSector((prev) => (subSectorId === prev ? null : subSectorId));
    setRole(null);
    if (prevSub !== subSectorId) {
      setEmployer({ ...employer, role: '', subSectorId });
    }
  };

  const { data: sectors } = useQuery({
    queryKey: [QUERY_KEYS.SECTORS],
    queryFn: () => coverageService.getSectors()
  });

  const { data: subSectors, mutateAsync: mutateSubSectors } = useSubSectorsBySector();

  const sortedSubSectors = useMemo(() => {
    if (group === 'Non-Finance') {
      return subSectors?.sort((a, b) => a.name.localeCompare(b.name));
    }
    return subSectors;
  }, [subSectors, vertical]);

  useEffect(() => {
    if (sector) {
      mutateSubSectors(sector);
    }
  }, [sector]);

  useEffect(() => {
    if (group === 'Non-Finance' && subSector && subSectors) {
      const findSubSector = subSectors.find((it) => it.id === subSector);
      if (!findSubSector) return;
      const findSector = sectors?.find((it) => it.id === findSubSector?.sectorId);
      setEmployer({
        ...employer,
        vertical: 'Non-Finance',
        subSectorId: subSector,
        subSector: {
          ...findSubSector,
          sector: findSector
        },
        role: role ?? ''
      });
      if (role) {
        setIsRoleSelected!(true);
      } else {
        setIsRoleSelected!(false);
      }
    }
  }, [role]);

  const filteredSectors = useMemo(
    () => sectors?.filter((item) => item.jobVerticals.includes('Non-Finance')).sort((a, b) => a.name.localeCompare(b.name)),
    [sectors]
  );
  useEffect(() => {
    if (role?.trim().length && role && group !== 'Non-Finance' && employer?.vertical) setIsCurrent(false);
  }, [employer, role]);

  useEffect(() => {
    if (group === 'Non-Finance' && sector && subSector && secutNonFinInput) secutNonFinInput.current?.focus();
  }, [subSector]);
  return (
    <>
      <Styled.Row isVertical>
        {VerticalArrays.map((item, index) => (
          <Styled.Item
            key={`group-${index}`}
            isDisabled={!!group && group !== item.name}
            isActive={item.name === group}
            onClick={() => onClickByGroup(item.name)}
          >
            {item.shortName ?? item.name}
          </Styled.Item>
        ))}
      </Styled.Row>
      {verticals && group !== 'Non-Finance' && (
        <Styled.Row isSubVertical>
          {verticals.map((item, index) => (
            <Styled.Item
              key={`vertical-${index}`}
              isDisabled={!!vertical && vertical !== item}
              isActive={vertical === item}
              onClick={() => onClickByVertical(item)}
            >
              {item}
            </Styled.Item>
          ))}
        </Styled.Row>
      )}
      {roles && !isAnon && (
        <Styled.Row isRoles>
          {roles.map((item, index) => (
            <Styled.Item
              key={`role-${index}`}
              isDisabled={!!role && role !== item}
              isActive={role === item}
              onClick={() => onClickByRole(item)}
            >
              {item}
            </Styled.Item>
          ))}
        </Styled.Row>
      )} 
       {group === 'Non-Finance' && (
        <>
          <Styled.Row isNonFinenceSubVertical>
            {!!filteredSectors &&
              filteredSectors.map((item) => (
                <Styled.Item
                  key={`sector-in-verticl-${item.id}`}
                  isDisabled={!!sector && sector !== item.id}
                  isActive={sector === item.id}
                  onClick={() => onClickBySector(item)}
                >
                  {item.name}
                </Styled.Item>
              ))}
          </Styled.Row>
        
          {sector && (
            <Styled.Row isNonFinenceSubSector={sectorName}>
              {!!sortedSubSectors &&
                sortedSubSectors.map((item) => (
                  <Styled.Item
                    key={`sub-sector-in-vertical-${item.id}`}
                    isDisabled={!!subSector && subSector !== item.id}
                    isActive={subSector === item.id}
                    onClick={() => onClickBySubSector(item.id)}
                  >
                    {item.name}
                  </Styled.Item>
                ))}
            </Styled.Row>
          )}
          {subSector && (
            <Styled.Input
              ref={secutNonFinInput}
              value={role ?? ''}
              // onKeyDown={onEnter}
              onChange={(e) => setRole(e.target.value)}
              placeholder="Type in your job role here"
            />
          )}
        </>
      )}
    </>
  );
};
