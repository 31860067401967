import React, { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import * as Styled from './page-wrapper.styled';
import { Loader } from '../loader';
import { Sidebar } from '../sidebar';
import { useSetScrollX } from '@/common/hooks/use-scroll';
import { getIsAnonUser, getIsNonFinanceUser, useAuth, useAuthProfile, useGetModalOpen, useSidebarWidth } from '@/common/hooks';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { FooterComponent } from '../footer';

interface IPageWrapperComponentProps {
  children: ReactNode;
  isLoading: boolean;
  isSidebarHidden?: boolean;
  isFooterHidden?: boolean;
  isVerticalBarsHidden?: boolean;
  isNotificationPage?: boolean;
  sidebarActive: string;
  isSearch?: boolean;
  isIdeaHome?: boolean;
  routePage?: string;
  sidebarProfileHandler?: () => void;
  disableScroll?: boolean;
  contentLeftMargin?: string;
}

export const PageWrapperComponent = forwardRef<HTMLDivElement, IPageWrapperComponentProps>(
  (
    {
      children,
      isLoading,
      isIdeaHome,
      sidebarActive,
      isSidebarHidden,
      isNotificationPage,
      isVerticalBarsHidden,
      sidebarProfileHandler,
      disableScroll = true,
      isSearch,
      routePage,
      contentLeftMargin
    }: IPageWrapperComponentProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const sidebarWidth = useSidebarWidth();
    const [scrollX, setScrollX] = useState(0);
    const { mutate: mutateScrollX } = useSetScrollX();
    const { isUserLoading, user } = useAuth();
    const router = useRouter();
    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { target } = event;
      const scrollXPosition = (target as HTMLButtonElement).scrollLeft;
      setScrollX(scrollXPosition || 0);
    };
    const contentWrapperRef = useRef<HTMLDivElement | null>(null);
    const [contentMarginLeft, setContentMarginLeft] = useState(0);
    const { data: isModalOpen } = useGetModalOpen();
    useEffect(() => {
      if (contentWrapperRef.current) {
        const computedStyle = getComputedStyle(contentWrapperRef.current);
        const marginLeft = parseFloat(computedStyle.marginLeft || '0');
        setContentMarginLeft(marginLeft);
      }
    }, []);

    useEffect(() => {
      mutateScrollX(scrollX);
    }, [scrollX]);

    const { isLoading: isAuthProfileLoading } = useAuthProfile();

    const isAnonUser = getIsAnonUser();
    const isNonFinanceUser = getIsNonFinanceUser();
    const isHome = router.pathname.includes(ROUTER_KEYS.HOME);

    return (
      <Styled.PageWrapper
        ref={ref}
        onScroll={handleScroll}
        disableScroll={disableScroll}
        onDrop={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        isAnon={isAnonUser}
      >
        {!isVerticalBarsHidden && (
          <Styled.LeftVerticalBar isHome={isHome} contentMarginLeft={contentMarginLeft} data-id="sidebar">
            {/* <div>
              <ScrollAnimationComponent duration={duration} isAnon={isAnonUser} text="Verona" isLeft />
            </div> */}
            {!isSidebarHidden && (
              <Sidebar
                active={sidebarActive}
                isAnon={isAnonUser}
                isNonFinanceUser={isNonFinanceUser}
                profileHandler={sidebarProfileHandler}
              />
            )}
          </Styled.LeftVerticalBar>
        )}
        <Styled.ContentWrapper
          ref={contentWrapperRef}
          sidebarWidth={sidebarWidth!}
          contentLeftMargin={contentLeftMargin}
          routePage={routePage}
        >
          {isModalOpen && <Styled.BlurBlockRightMain />}
          <AnimatePresence>
            {isLoading || isAuthProfileLoading || isUserLoading ? (
              <Styled.LoaderWrapper isNotificationPage={isNotificationPage} isPageLoading>
                <Loader />
              </Styled.LoaderWrapper>
            ) : (
              <motion.div
                transition={{ ease: 'backInOut', delay: 0.3, duration: 1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Styled.ChildrenWrapper isSearch={!!isSearch} isIdeaHome={isIdeaHome} disableScroll={disableScroll}>
                  {children as any}
                </Styled.ChildrenWrapper>
              </motion.div>
            )}
          </AnimatePresence>
        </Styled.ContentWrapper>
        {/* {!isVerticalBarsHidden && ( */}
        {/* <Styled.RightVerticalBar>
            {isModalOpen && <Styled.BlurBlockRightBar />}
            {!isFooterHidden && !!user && <FooterComponent user={user} isAnon={isAnonUser} />}
            {/* <div>
              <ScrollAnimationComponent duration={0} isAnon={isAnonUser} text="Verified" isLeft={false} />
            </div> */}
        {/* </Styled.RightVerticalBar> */}
        {!!user && <FooterComponent user={user} isAnon={isAnonUser} />}
      </Styled.PageWrapper>
    );
  }
);
