import React, { Fragment, ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  CoverageType,
  IEducationHistoryResponse,
  IEmployerHistoryResponse,
  INameIdObject,
  UnfilledFields,
  WorkHistoryType
} from '@/common/types/profile.type';
import { degreeName } from '@/common/types/education.type';
import { findOutputVertical } from '@/common/utils/vertical-output';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { calculateTimeRange, getLocation, getUnfilledCoverages } from './work-history.utils';
import * as Styled from './work-history.styled';

interface IHistoryListItemComponentProps {
  item: IEducationHistoryResponse | IEmployerHistoryResponse;
  unfilledFields: UnfilledFields;
  isCurrent: boolean;
  isAnonProfile?: boolean;
  isHasLeftPadding?: boolean;
}

export const HistoryListItemComponent = ({ item, unfilledFields, isCurrent, isAnonProfile }: IHistoryListItemComponentProps) => {
  const router = useRouter();
  const timeRange = calculateTimeRange(item.startDate, item.endDate);

  const isEducation = item.type === WorkHistoryType.EDUCATION;

  const unfilledCoverages = useMemo(() => {
    if (isCurrent && item.type === WorkHistoryType.EMPLOYER) {
      return getUnfilledCoverages(item, !!(unfilledFields.vertical || unfilledFields.subVertical));
    }
    return {};
  }, [item, isCurrent, unfilledFields.vertical, unfilledFields.subVertical]);

  const formatDate = (dateString?: string, month?: boolean, isStart?: boolean, empty?: boolean): ReactNode => {
    const day = dateString?.split('T')[0].split('-')[2];
    if (!dateString) {
      return empty ? (
        'present'
      ) : unfilledFields.startDate || unfilledFields.endDate ? (
        <Styled.Warning lowercase isDate>
          [ {isStart ? 'start' : 'end'} date ]
        </Styled.Warning>
      ) : (
        ''
      );
    }

    const date = new Date(dateString);
    return month && day !== '03'
      ? `${date.getFullYear().toString()} ${date.toLocaleString('en-US', { month: 'short' })}`
      : date.getFullYear().toString();
  };

  const displayVertical = (vertical: string) => {
    if (unfilledFields.vertical) {
      return (
        <>
          <Styled.Warning>[ Job Vertical ] </Styled.Warning> <Styled.Warning>[ Job Sub-Vertical ]</Styled.Warning>
        </>
      );
    }
    if (!vertical) return '';
    if (unfilledFields.subVertical) {
      return (
        <>
          {vertical} <Styled.Warning>[ Job Sub-Vertical ]</Styled.Warning>
        </>
      );
    }
    return findOutputVertical(vertical);
  };

  const onClickByItem = (sectionType: CoverageType, id: string, value: string) => {
    const type =
      sectionType === CoverageType.SECTORS
        ? 'sectors'
        : sectionType === CoverageType.SUB_SECTORS
        ? 'sub-sectors'
        : sectionType === CoverageType.REGIONS
        ? 'regions'
        : sectionType === CoverageType.COMPANIES
        ? 'security'
        : 'privates';
    let urlString = `${ROUTER_KEYS.SEARCH_PAGE}?value=${value}&type=${type}&clickedId=${id}&clickedValue=${value}&clickedTicker=`;
    if (sectionType === CoverageType.COMPANIES) {
      urlString += id;
    }
    router.push(urlString);
  };

  const formatCoverageSection = (sectionType: CoverageType, isMissed: boolean, items?: INameIdObject[]) => {
    if (items && items.length > 0) {
      return items.map((it) => ({
        sectionType,
        isMissed,
        item: it
      }));
    }
    if (isMissed && isCurrent) {
      return [
        {
          sectionType,
          isMissed,
          item: null
        }
      ];
    }
    return [];
  };

  const combineCoverageItems = (data: any) => {
    const sectors = formatCoverageSection(CoverageType.SECTORS, !!unfilledCoverages.sectors, data.sectors);
    const subSectors = !unfilledCoverages.sectors
      ? formatCoverageSection(CoverageType.SUB_SECTORS, !!unfilledCoverages.subSectors, data.subSectors)
      : [];
    const regions = formatCoverageSection(CoverageType.REGIONS, !!unfilledCoverages.regions, data.regions);
    const companies = formatCoverageSection(CoverageType.COMPANIES, !!unfilledCoverages.companies, data.companies);
    const privates = formatCoverageSection(CoverageType.PRIVATES, false, data?.privateCompanies ?? []);
    return [sectors, subSectors, regions, companies, privates];
  };

  const renderCoverageItemsWithSeparators = (sections: any[]) =>
    sections.flatMap((section) =>
      section.map((data: any, itemIndex: number) => {
        const showBullet = itemIndex === 0;
        return (
          <Fragment key={data ? `${data?.item?.id}-${itemIndex}` : `${data.sectionType}-${itemIndex}`}>
            {showBullet && (
              <Styled.ListItemSeparator isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious}>
                •
              </Styled.ListItemSeparator>
            )}
            <Styled.ListItemCoveregeSectionItem
              isAnon={isAnonProfile}
              onClick={
                () =>
                  data.item
                    ? onClickByItem(data.sectionType, data.item.id, data.item.name)
                    : router.push({ pathname: ROUTER_KEYS.EDIT_PROFILE, hash: `m${item.id}` })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              {data.item ? data.item.name : `+ add ${data.sectionType}`}
            </Styled.ListItemCoveregeSectionItem>
          </Fragment>
        );
      })
    );

  return (
    <Styled.ListItemWrapper>
      <Styled.ListItemTitleWrapper>
        {((item.type === WorkHistoryType.EMPLOYER && !unfilledFields.vertical) ||
          (item.type === WorkHistoryType.EDUCATION && !unfilledFields.degree)) && (
          <Styled.ListItemTitle isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious} isRole>
            {item.type === WorkHistoryType.EMPLOYER ? (
              <>
                {!item.vertical?.startsWith('Non-Finance') ? (
                  displayVertical(item.vertical)
                ) : unfilledFields.subSector ? (
                  <>
                    {item.vertical} {isCurrent && <Styled.Warning>[ Sub-Sector ]</Styled.Warning>}
                  </>
                ) : item.subSector?.name ? (
                  `${item.subSector.name}${
                    (unfilledFields.role &&
                      !item.vertical?.startsWith('Non-Finance') &&
                      !isAnonProfile &&
                      item.vertical !== 'Publishing Sole Publisher' &&
                      !item.role &&
                      isCurrent) ||
                    !!item.role
                      ? ':'
                      : ''
                  }`
                ) : (
                  ''
                )}{' '}
                {unfilledFields.role &&
                !item.vertical?.startsWith('Non-Finance') &&
                !isAnonProfile &&
                item.vertical !== 'Publishing Sole Publisher' &&
                !item.role &&
                isCurrent ? (
                  <Styled.Warning>[ Job Role ]</Styled.Warning>
                ) : (
                  item.role || ''
                )}
              </>
            ) : (
              <>
                {unfilledFields.degree ? (
                  <Styled.Warning>[ Degree ]</Styled.Warning>
                ) : (
                  degreeName[item.degree] + (degreeName[item.degree] && item.major ? ',' : '') || ''
                )}{' '}
                {item.major ? ' ' : ''}
                {item.major || ''}
              </>
            )}
          </Styled.ListItemTitle>
        )}
        {item.type === WorkHistoryType.EMPLOYER ? (
          !!(item as IEmployerHistoryResponse).employer?.length &&
          (item as IEmployerHistoryResponse).employer !== 'employed' && (
            <Styled.ListItemTitle isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious} isRole={false}>
              {item.employer}
            </Styled.ListItemTitle>
          )
        ) : (
          <Styled.ListItemTitle isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious} isRole={false}>
            {item.university}
          </Styled.ListItemTitle>
        )}
      </Styled.ListItemTitleWrapper>
      {(!!item.startDate || (unfilledFields.startDate && isCurrent) || item.location || item.locationRegion) && (
        <Styled.LocationAndDateContainer>
          {(item.startDate || (unfilledFields.startDate && isCurrent)) && (
            <Styled.ListItemDateAndLocationContainer isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious}>
              <span>{formatDate(item.startDate, true, true)}</span>
              {(item.startDate || (!item.endDate && unfilledFields.endDate && isCurrent)) && (
                <>
                  <span>—</span>
                  <span>{formatDate(item.endDate, true, false, !item.endDate && !unfilledFields.endDate && !isEducation)}</span>
                </>
              )}

              {timeRange && (
                <>
                  <Styled.ListItemSeparator isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious} isInDate>
                    •
                  </Styled.ListItemSeparator>
                  <Styled.ListItemRangeContainer>
                    <span>{timeRange}</span>
                    <span>years</span>
                  </Styled.ListItemRangeContainer>
                </>
              )}
            </Styled.ListItemDateAndLocationContainer>
          )}
          {(item.location || item.locationRegion) && (
            <Styled.ListItemDateAndLocationContainer
              isAnon={isAnonProfile}
              isEducation={isEducation}
              isCurrent={!item.isPrevious}
              isLocatin
            >
              {getLocation(item.location, item.locationRegion)}

              {!item.location && !item.locationRegion && !isAnonProfile && isCurrent && (
                <Styled.AddMissingField
                  isAnon={isAnonProfile}
                  isLocation
                  onClick={() => router.push({ pathname: ROUTER_KEYS.EDIT_PROFILE, hash: `m${item.id}` })}
                >
                  + add location
                </Styled.AddMissingField>
              )}
            </Styled.ListItemDateAndLocationContainer>
          )}
        </Styled.LocationAndDateContainer>
      )}
      {item.description ? (
        <Styled.ListItemDescriptionContainer isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious}>
          {item.description}
        </Styled.ListItemDescriptionContainer>
      ) : (
        isCurrent && (
          <Styled.ListItemDescriptionContainer isAnon={isAnonProfile} isEducation={isEducation} isCurrent={!item.isPrevious} isWarning>
            <Styled.AddMissingField
              isAnon={isAnonProfile}
              onClick={() => router.push({ pathname: ROUTER_KEYS.EDIT_PROFILE, hash: `m${item.id}` })}
            >
              + add role description
            </Styled.AddMissingField>
          </Styled.ListItemDescriptionContainer>
        )
      )}
      {item.type === WorkHistoryType.EMPLOYER &&
        ((item.sectors?.length ?? 0) > 0 ||
          (item.regions?.length ?? 0) > 0 ||
          (item.subSectors?.length ?? 0) > 0 ||
          (item.companies?.length ?? 0) > 0 ||
          (item.privateCompanies?.length ?? 0) > 0 ||
          Object.keys(unfilledCoverages).length > 0) && (
          <Styled.ListItemCoverageContainer isAnon={isAnonProfile} isCurrent={!item.isPrevious}>
            <Styled.ListCoverageSectionTitleWrapper>
              <Styled.ListCoverageSectionWrapper>
                <Styled.CoveragePadding isAnon={isAnonProfile}>Coverage</Styled.CoveragePadding>
                {renderCoverageItemsWithSeparators(
                  combineCoverageItems({ ...item, privateCompanies: item.privateCompanies.map((p) => ({ id: p, name: p })) })
                )}
              </Styled.ListCoverageSectionWrapper>
            </Styled.ListCoverageSectionTitleWrapper>
          </Styled.ListItemCoverageContainer>
        )}
    </Styled.ListItemWrapper>
  );
};
