import React from 'react';
import ProfileLockIcon from '@styles/icons/profile-lock.webp';
import Image from 'next/image';
import * as Styled from './hiden-section.styled';

interface IProps {
  text: string;
}
export const HidenSectionComponent = ({ text }: IProps) => (
  <Styled.Wrapper>
    <Image width={14} height={14} src={ProfileLockIcon} alt="profile-lock" /> <span>{text}</span>
  </Styled.Wrapper>
);
