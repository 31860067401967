import React, { useMemo } from 'react';

import { ContainerFilters } from './newsfeed-filter.styled';

import { INewsfeedFilter, NewsfeedCategory } from '@/common/types/newsfeed.type';
import { NewsfeedFilterCategory } from '../newsfeed-filter-category';

export const NewsfeedFilter: React.FC<INewsfeedFilter> = ({ updateFilters, currentFilter, tagList, allowedFilters, isAnon }) => {
  const defaultFilter = useMemo(() => allowedFilters?.filter((it) => it.fullName), [allowedFilters]);
  const timeFilter = useMemo(() => allowedFilters?.filter((it) => !it.fullName), [allowedFilters]);

  return (
    <ContainerFilters>
      asdf
      {/* {tagList && (
        <>
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.SOURCE}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.SOURCE]}
            tagList={tagList[NewsfeedCategory.SOURCE]}
            allowedFilters={defaultFilter}
          />
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.TIME}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.TIME]}
            tagList={[]}
            allowedFilters={timeFilter}
          />
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.TOPICS}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.TOPICS]}
            tagList={tagList[NewsfeedCategory.TOPICS].filter((it) => it.fullName !== 'US Election 2024')}
            allowedFilters={defaultFilter}
          />
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.SECTORS}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.SECTORS]}
            tagList={tagList[NewsfeedCategory.SECTORS]}
            allowedFilters={defaultFilter}
          />
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.JOB_VERTICALS}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.JOB_VERTICALS]}
            tagList={tagList[NewsfeedCategory.JOB_VERTICALS]}
            allowedFilters={defaultFilter}
          />
          <NewsfeedFilterCategory
            isAnon={isAnon}
            tagName={NewsfeedCategory.REGIONS}
            updateFilters={updateFilters}
            currentFilter={currentFilter[NewsfeedCategory.REGIONS]}
            tagList={tagList[NewsfeedCategory.REGIONS]}
            allowedFilters={defaultFilter}
          />
        </>
      )} */}
    </ContainerFilters>
  );
};
