import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';

import { FAMILIES } from '@/theme/fonts.const';
import { COLORS, FONTS } from '@/theme';
import { PageWrapperComponent } from '@/common/components/page-wrapper';
import { RightBoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const NewsWrapper = styled(PageWrapperComponent as AnyStyledComponent)``;

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ScrollContainer = styled.div`
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  padding: 88px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const EmptyDiv = styled.div`
  width: 13px;
  height: 13px;
`;

export const FilterButton = styled.button`
  border: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 7px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  width: 79px;
  height: 30px;
  background: rgba(232, 232, 232, 0.5);
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

export const FilterIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;
export const NavAbsoluteIcon = styled(Image)<{ isShow?: boolean }>`
  position: absolute;
  height: 13px;
  z-index: -100;
  ${({ isShow }) => isShow && 'z-index: 1;'}
`;

interface ILoaderWrapperProps {
  isPageLoading?: boolean;
  heightForMinus?: number;
  padding?: number;
  staticWidth?: number;
  heihgt?: number;
}

export const LoaderWrapper = styled.div<ILoaderWrapperProps>`
  width: ${({ isPageLoading, staticWidth }) => {
    if (staticWidth) {
      return `${staticWidth}px`;
    }
    return isPageLoading ? '1200px' : '100%';
  }};
  height: ${({ isPageLoading, heightForMinus }) => {
    if (isPageLoading) {
      return '100vh';
    }
    return heightForMinus ? `calc(100vh - ${heightForMinus}px)` : '100%';
  }};
  ${({ heihgt }) => heihgt && `height:${heihgt}px;`}
  ${({ padding }) => `padding:${padding}px 0`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const LeftContainer = styled.div`
  position: relative;
  background-color: transparent;
  min-width: 500px;
  max-width: 500px;
`;

export const RightContainer = styled(ScrollContainer)`
  position: relative;
  padding: 15px 0;
  min-width: 403px;
  max-width: 403px;
  background-color: transparent;
`;

export const BoxAroundSectionNewsFeed = styled(RightBoxAroundSection)`
  background-color: transparent;
`;

export const RightHeaderContainer = styled.div`
  margin-top: -3px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  background: rgba(253, 253, 253, 0.01);
  backdrop-filter: blur(2px);
  z-index: 1;
  flex-shrink: 0;
`;

export const LeftHeaderContainer = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateNewButtonContainer = styled.div`
  padding-left: 25px;
`;

export const CreateNewFeedButton = styled.button`
  display: inline-flex;
  padding: 9px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  background: #f2f2f2;
  cursor: pointer;
`;

export const Images = styled(Image as AnyStyledComponent)`
  width: 16px;
  height: 16px;
`;

export const ButtonText = styled.span`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
`;

export const FiltersText = styled.span<{ isAnon: boolean }>`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
  ${({ isAnon }) => isAnon && 'color: #fff;'}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1px;
  height: 30px;
  & > p {
    font-family: ${FAMILIES.robotoFlex};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: ${COLORS.black};
    margin-bottom: -1px;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  height: 36px;
`;

export const NavigationItem = styled.div<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: flex-start;
  padding-bottom: 3px;
  & > p {
    font-family: ${FAMILIES.robotoFlex};
    color: ${({ active }) => (active ? '#0018F1' : 'rgba(0, 24, 241, 0.3)')};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const TitleIcon = styled(Image as AnyStyledComponent)<{ isActive?: boolean }>`
  width: 20px;
  height: 20px;
`;

export const NavIcon = styled(Image as AnyStyledComponent)<{ isActive?: boolean }>`
  /* width: 13px; */
  height: 13px;
`;
