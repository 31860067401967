/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import NewPost from '@styles/icons/add-new-post-icon.png';
import Cmd from '@styles/icons/cmd-gradient.png';
import { useRouter } from 'next/router';
import CmdIcon from '@/styles/icons/cmd-gray-new-icon.png';
import { ISidebarProps } from '@/common/types/sidebar.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { useNotifications } from '@/common/hooks/use-notification';
import { useDialogs, useDirectMessages } from '@/common/hooks/use-direst-messages';
import {
  triedToActivate as triedToActivateAPI,
  useActivateUser,
  useBrowserDetection,
  useDetectOS,
  useSwitchToReacAcount,
  useSwitchToSimplifiedAnonAcount
} from '@/common/hooks';
import { sidebarList } from './sidebar.const';
import * as Styled from './sidebar.styled';
// import { SearchModal } from '../search-page/components/search-modal';
// import { SlideTextButtonComponent } from './slide-text-button.component';
import { ActivateAnonModal } from '../anon/components/modals/activate-anon-modal/activate-anon-modal.component';
import { ActivateRealModal } from '../anon/components/modals/activate-real-modal/activate-real-modal.component';
import { SwitchToAnonModalComponent } from '../switch-to-anon-modal';
import { ConnectRealToAnonModal } from '../anon/components/modals/connect-real-to-anon-modal/connect-real-to-anon-modal.component';
import { SidebarLogoComponent } from './components/sidebar-logo/sidebar-logo.component';
import { LoginRealFromAnon } from '../anon/components/modals/login-real-from-anon-modal/login-real-from-anon-modal.component';
import { CreateModalComponent } from '../create-modal/create-modal.component';
import { CreateModalType } from '../create-modal/modal-type.const';
import { IGetNotifications, NotificationClientType } from '@/common/types/notification.type';
import { SidebarSearchComponent } from './components/sidebar-search';
import { FlexComponent } from '../flex';
import { AccountModeSwitcher } from './components/account-switch-button/account-switch-button.component';

export const Sidebar = ({ active, profileHandler, isAnon, isNonFinanceUser }: ISidebarProps) => {
  const [openNewMusingModal, setOpenNewMusingModal] = useState<boolean>(false);
  const os = useDetectOS();
  const isWindows = os === 'Windows';
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
  const [activateAnonModal, setActivateAnonModal] = useState<boolean>(false);
  const [openSwitchToAnon, setOpenSwitchToAnon] = useState<boolean>(false);
  const [activateRealModal, setActivateRealModal] = useState<boolean>(false);
  const [connectRealToAnonModal, setConnectRealToAnonModal] = useState<boolean>(false);
  const [loginToRealModal, setLoginToRealModal] = useState<boolean>(false);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const { notifications } = useNotifications();
  const { data: messages } = useDirectMessages();
  const { user, updateUser } = useActivateUser();
  const [triedToActivate, setTriedToActivate] = useState(!!user?.triedToActivate);
  const browser = useBrowserDetection();
  useEffect(() => setTriedToActivate(!!user?.triedToActivate), [user?.triedToActivate]);
  const router = useRouter();

  const { push } = useRouter();
  const { state } = useDialogs();

  const handleClick = async (link: string) => {
    if (active === 'Profile' && link === ROUTER_KEYS.PROFILE && profileHandler) {
      profileHandler();
    }
    push(link);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyC') {
      setOpenNewMusingModal(true);
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleFlashingClick = async () => {
    setTriedToActivate(true);
    updateUser({ triedToActivate: true });
    await triedToActivateAPI();
  };

  const { mutateAsync: switchToRealAcount, isLoading } = useSwitchToReacAcount();
  const { mutateAsync: switchToSimplifiedAnonAcount, isLoading: isSwitchingToSimplifiedAnon } = useSwitchToSimplifiedAnonAcount();

  const onClickBySwitchButton = () => {
    if (isLoading) return;
    switchToRealAcount();
  };

  const onClickBySwitchSimplifiedButton = () => {
    if (isSwitchingToSimplifiedAnon) return;
    switchToSimplifiedAnonAcount();
  };

  const getNotificationSummary = (notificationsInput: IGetNotifications) => {
    let total = 0;

    notificationsInput.forResponse.forEach((notification) => {
      switch (notification.type) {
        case NotificationClientType.REPLY:
          total += 1;
          break;
        case NotificationClientType.REPOST:
        case NotificationClientType.CONNECTION_REQUEST:
        case NotificationClientType.FOLLOW_REQUEST:
        case NotificationClientType.MENTION:
        case NotificationClientType.NEW_FOLLOWER:
          total += notification.count || 0;
          break;
        case NotificationClientType.POST_REACTS:
          total += (notification.claps || 0) + (notification.likes || 0);
          break;
        case NotificationClientType.QUOTE_POST:
          total += 1;
          break;
        default:
          break;
      }
    });

    return total;
  };

  const checkNewNotification = (): boolean => {
    if (notifications) {
      const count = getNotificationSummary(notifications);
      setNotificationsCount(count);
      const forResponse = notifications.forResponse?.length || 0;
      const adoration = notifications.adoration?.length || 0;
      return !!forResponse || !!adoration;
    }
    return false;
  };

  useEffect(() => {
    setIsNewNotification(checkNewNotification());
  }, [notifications]);

  const onSearchClick = () => {
    if (!router.pathname.includes(ROUTER_KEYS.SEARCH_PAGE)) {
      setOpenSearchModal((prev) => !prev);
    }
  };

  const handleRealSwithClick = () => {
    const isDontHaveAnonAccount =
      user && ((user.id.includes('-') && !user.isSimplifiedAnon) || (user.email.includes('@') && user.isSimplifiedAnon));
    if (isDontHaveAnonAccount) {
      setActivateRealModal(true);
      return;
    }
    if (user && !user.id.includes('-') && !user.isSimplifiedAnon && !user.refreshTokenReal) {
      setLoginToRealModal(true);
      return;
    }
    onClickBySwitchButton();
  };

  const handleAnonSwitchClick = () => {
    if (user && user.isAnon) {
      onClickBySwitchButton();
      return;
    }
    if (user && !user.isUserHaveAnon) {
      setActivateAnonModal(true);
      return;
    }
    if (user && !user.isAnonLinked) {
      setConnectRealToAnonModal(true);
      return;
    }
    if (user && user.isUserHaveSimplifiedAnon) {
      onClickBySwitchSimplifiedButton();
      return;
    }
    setOpenSwitchToAnon(true);
  };

  const channelsToDisplay = React.useMemo(
    () =>
      state.channels
        ?.filter((ch) => !(ch.lastMessage === null) || ch.url === state.currentlyJoinedChannel?.url)
        .sort((a, b) => {
          if (a.lastMessage === null) return -1;
          if (b.lastMessage === null) return 1;
          return 0;
        }) || [],
    [state.channels, state.currentlyJoinedChannel]
  );

  return (
    <Styled.SidebarContainerWrapper>
      <Styled.SidebarContainer>
        <Styled.RoutesContainer>
          {sidebarList.map((item, index) => {
            if (isNonFinanceUser && item.name === 'Memo') return true;
            return (
              <React.Fragment key={`toolbar-button-${index}-${item.name}`}>
                <Styled.RouteButton
                  id={item.name}
                  onClick={() => (item.name === 'Search' ? onSearchClick() : handleClick(item.link))}
                  isActive={active === item.name}
                  marginBottom={item.marginBottom}
                >
                  <>
                    <Styled.Hidden priority src={item.valueActive} alt={item.name} />
                    <Styled.Hidden priority src={item.value} alt={item.name} />
                    <Styled.Icon
                      isAnon={isAnon}
                      priority
                      src={active === item.name ? item.valueActive : item.value}
                      alt={item.name}
                      isActive={active === item.name}
                      topPx={item.topPx}
                    />
                  </>
                  <Styled.NotificAndMessagesCountContainer>
                    <Styled.RouteText isActive={active === item.name} isAnon={isAnon}>
                      {item.name}
                    </Styled.RouteText>
                    {((item.link === ROUTER_KEYS.NOTIFICATIONS && isNewNotification && active !== item.name) ||
                      (item.link === ROUTER_KEYS.MESSAGES && messages?.hasUnreadedMessages && active !== item.name)) && (
                      <Styled.NotificAndMessagesCount>
                        {item.link === ROUTER_KEYS.NOTIFICATIONS && notificationsCount && notificationsCount > 0
                          ? notificationsCount && notificationsCount > 99
                            ? '>99'
                            : notificationsCount
                          : null}
                        {item.link === ROUTER_KEYS.MESSAGES &&
                          channelsToDisplay.length > 0 &&
                          (() => {
                            const unreadMessagesCount = channelsToDisplay.reduce(
                              (acc, dialog) => acc + (dialog.unreadMessageCount > 0 ? dialog.unreadMessageCount : 0),
                              0
                            );
                            return unreadMessagesCount > 0 ? (unreadMessagesCount > 99 ? '>99' : unreadMessagesCount) : null;
                          })()}
                      </Styled.NotificAndMessagesCount>
                    )}
                  </Styled.NotificAndMessagesCountContainer>

                  {active === item.name ? (
                    <Styled.SidebarDot>•</Styled.SidebarDot>
                  ) : (
                    item.name === 'Search' && (
                      <FlexComponent alignItems="baseline" mt="5px" gap="2px" ml="auto">
                        {isWindows ? <Styled.KeyText>CTRL</Styled.KeyText> : <Styled.CmdIcon src={CmdIcon} alt="Cmd" />}
                        <Styled.KeyText>K</Styled.KeyText>
                      </FlexComponent>
                    )
                  )}
                </Styled.RouteButton>
                {item.divider && <Styled.SidebarDivider />}
              </React.Fragment>
            );
          })}
        </Styled.RoutesContainer>
        <Styled.NewPostRouteButton onClick={() => setOpenNewMusingModal(true)}>
          <Styled.NewPostIcon priority src={NewPost} alt="New" />
          <Styled.NewPostText>New post</Styled.NewPostText>
          <FlexComponent alignItems="baseline" marginLeft="auto" mb={browser === 'Safari' ? '0px' : '1px'} gap="2px">
            {isWindows ? <Styled.KeyTextGradient>CTRL</Styled.KeyTextGradient> : <Styled.CmdIconGradient src={Cmd} alt="Cmd" />}
            <Styled.KeyTextGradient>C</Styled.KeyTextGradient>
          </FlexComponent>
        </Styled.NewPostRouteButton>
        <SwitchToAnonModalComponent open={openSwitchToAnon} closeHandler={() => setOpenSwitchToAnon(false)} />
        <CreateModalComponent
          open={openNewMusingModal}
          closeHandler={() => setOpenNewMusingModal(false)}
          modalType={CreateModalType.POST}
        />
        <SidebarSearchComponent open={openSearchModal} openSearch={setOpenSearchModal} />
        {/* <SearchModal open={openSearchModal} closeHandler={() => setOpenSearchModal(false)} /> */}
        <LoginRealFromAnon open={loginToRealModal} closeHandler={() => setLoginToRealModal(false)} />
        <ActivateAnonModal open={activateAnonModal} closeHandler={() => setActivateAnonModal(false)} />
        <ActivateRealModal open={activateRealModal} closeHandler={() => setActivateRealModal(false)} />
        <ConnectRealToAnonModal open={connectRealToAnonModal} closeHandler={() => setConnectRealToAnonModal(false)} />
      </Styled.SidebarContainer>
      <Styled.SidebarBottomContainer>
        {/* <Styled.ModeWrapper isAnon={isAnon}>
          <Styled.UnActiveButton isAnon onClick={() => (isAnon ? handleRealSwithClick() : handleAnonSwitchClick())}>
            {((!isAnon && !!user && !user.isUserHaveAnon) ||
              (isAnon &&
                !!user &&
                ((user.id.includes('-') && !user.isSimplifiedAnon) || (user.email.includes('@') && user.isSimplifiedAnon)))) && (
                <Styled.ActivateLabelWrapper isFlashing={!triedToActivate} onClick={handleFlashingClick}>
                  <p>Activate</p>
                  <Image src={LineIcon} width={8} height={23} alt="line-icon" />
                </Styled.ActivateLabelWrapper>
            )}
            <Styled.ModeImgWrapper onClick={handleFlashingClick}>
              <Styled.ModeImg priority src={!isAnon ? UnActiveAnonModeIcon : UnActiveRealModeIcon} alt="Un Active Mode Icon" />
              <Styled.ModeLabel isAnon={isAnon}>{isAnon ? 'Real\nAccount' : 'Anon\nAccount'}</Styled.ModeLabel>
            </Styled.ModeImgWrapper>
          </Styled.UnActiveButton>
          <Styled.ModeImgWrapper>
            <Styled.ModeImg priority src={isAnon ? AnoneModeIcon : ModeIcon} alt="Mode Icon" />
            <Styled.ModeLabel isAnon={isAnon} isCurrent>
              {isAnon ? 'Anon\nAccount' : 'Real\nAccount'}
            </Styled.ModeLabel>
          </Styled.ModeImgWrapper>
        </Styled.ModeWrapper> */}
        <AccountModeSwitcher
          isAnon={isAnon}
          handleRealSwitchClick={handleRealSwithClick}
          handleAnonSwitchClick={handleAnonSwitchClick}
        />
        <SidebarLogoComponent />
      </Styled.SidebarBottomContainer>
    </Styled.SidebarContainerWrapper>
  );
};
