import styled from 'styled-components';
import { FONTS } from '@/theme';

export const SwingsTitle = styled.div<{ isQuote?: boolean }>`
  display: inline;
  align-items: start;
  color: #000;
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 1px;
  white-space: pre-wrap;
`;

export const SwingsGraphTitle = styled.div<{ isQuote?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
  color: #000;
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 1px;
  white-space: pre-wrap;
`;

export const HighlightedTitle = styled.p`
  display: inline;
  font-weight: ${FONTS.WEIGHTS.semiBold};
`;

export const SwingsSubtitle = styled.span<{ isQuote?: boolean }>`
  color: #0018f1;
  ${({ isQuote }) => isQuote && 'color: rgba(0, 24, 241, 0.70);'}
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  cursor: pointer;
`;

export const SwingsColumnText = styled.p<{ isQuote?: boolean }>`
  color: #000;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const SwingsQuarterText = styled.p<{ isQuote?: boolean }>`
  color: #000;
  margin-left: -4px;
  text-align: left;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
