/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import AttentionIcon from '@/styles/icons/attention-circle.webp';
import HomeHeadIcon from '@/styles/icons/home-head-icon.webp';
import HomeHeadIconAnon from '@/styles/icons/home-head-icon-anon.webp';
import EditFilterIcon from '@/styles/icons/edit-filter.webp';
import * as Styled from './posts.styled';
import { getIsAnonUser, useDebounce, useGetAllowedFiltersData, useGetTagListData, useGetTags, useSidebarWidth } from '@/common/hooks';
import { HeaderTitleUnderlineWrapper } from '@/common/components/page-wrapper/components/box-around-section';
import { FollowingPostsComponent } from './following-posts.component';
import { HomePostTabs } from '@/common/types/post.type';
import { NewsfeedPostsComponent } from './newsfeed';
import { IFilters, ITagFilter, NewsfeedCategory, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { LoaderWrapper } from './newsfeed/newsfeed-posts.styled';
import { Loader } from '@/common/components/loader';
import { FlexComponent } from '@/common/components/flex';

interface IProps {
  userProfileId: string;
  quotePostHandler: (postId: string) => void;
  openReplyModal: (newPostId: string, searchId: string) => void;
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  currentFilter: IFilters;
  debouncedFilter: IFilters;
}

export const PostsComponent = ({ currentFilter, debouncedFilter, userProfileId, quotePostHandler, openReplyModal, scrollRef }: IProps) => {
  const isFirstRender = useRef(true);
  const [isShowLoadingAll, setIsShowLoadingAll] = useState(false);
  const [isShowLoadingFollowing, setIsShowLoadingFollowing] = useState(false);
  const [isPresetLoading, setIsPresetLoading] = useState(true);
  const debouncedFilterRequest = useDebounce<IFilters>(debouncedFilter, 1, () => {
    if (JSON.stringify(currentFilter) === JSON.stringify(debouncedFilter)) {
      setIsPresetLoading(false);
    }
  });
  const [postTab, setPostTab] = useState(HomePostTabs.FOLLOWING);
  const [activeTab, setActiveTab] = React.useState<NewsfeedNavigation>(NewsfeedNavigation.UNREAD);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const sidebarWidth = useSidebarWidth();

  const { mutate: getTags } = useGetTags();
  const { data: tagList } = useGetTagListData();
  const { data: allowedFilters } = useGetAllowedFiltersData();
  const isAnon = getIsAnonUser();

  //   setCurrentFilter(updatedFilters as IFilters);
  //   // if (postTab === HomePostTabs.FOLLOWING) {
  //   //   getAllowedFilters({
  //   //     filter: updatedFilters as IFilters
  //   //   });
  //   // } else {
  //   //   getAllowedFilters({
  //   //     filter: updatedFilters as IFilters,
  //   //     activeTab: postTab === HomePostTabs.ALL ? NewsfeedNavigation.UNREAD : activeTab
  //   //   });
  //   // }
  // };

  const onFirstRender = async () => {
    // const isOnFollowing = postTab === HomePostTabs.FOLLOWING;
    // const rightActiveTab = postTab === HomePostTabs.ALL ? NewsfeedNavigation.UNREAD : activeTab;
    if (isFirstRender.current) {
      // await getAllowedFilters({ filter: currentFilter, ...(!isOnFollowing && { activeTab: rightActiveTab }) });
      // const filter = await getPreselectedFilters();
      // if (filter) {
      //   setCurrentFilter(filter);
      // }
      isFirstRender.current = false;
    } else {
      // await getAllowedFilters({ filter: currentFilter, ...(!isOnFollowing && { activeTab: rightActiveTab }) });
    }
  };

  useEffect(() => {
    getTags();
    onFirstRender();
  }, []);

  useEffect(() => {
    if (postTab === HomePostTabs.ALL) {
      setIsShowLoadingAll(true);
    }
    if (postTab === HomePostTabs.FOLLOWING) {
      setIsShowLoadingFollowing(true);
    }
  }, [currentFilter]);

  return (
    <Styled.PostsWrapper isAnon={isAnon}>
      <div>
        {/* <FilterModalComponent
        open={isFilterOpen}
        closeHandler={() => setIsFilterOpen(false)}
        isAnon={isAnon}
        tagList={tagList}
        updateFilters={updateFilters}
        onFirstRender={onFirstRender}
        currentFilter={currentFilter}
        allowedFilters={allowedFilters}
        postTab={postTab}
      /> */}
        <Styled.HeaderWithFilterWrapper isNotFixed sidebarWidth={sidebarWidth!}>
          <Styled.HeaderWrapper>
            {/* <Styled.HomeHeadIcon src={isAnon ? HomeHeadIconAnon : HomeHeadIcon} alt="Home feed" /> */}
            <Styled.Head isAnon={isAnon}>Your Feed</Styled.Head>
            {/* <Styled.HeadCoverage isAnon={isAnon}>based on your coverage</Styled.HeadCoverage> */}
            <FlexComponent alignItems="center" gap="6px" ml="auto" pr="2px">
              <Styled.EditFilter>edit feed with filters</Styled.EditFilter>
              {/* <Styled.EditFilterIcon src={EditFilterIcon} alt="Edit filter" /> */}
            </FlexComponent>
          </Styled.HeaderWrapper>
        </Styled.HeaderWithFilterWrapper>
        {postTab !== HomePostTabs.NEWS && (
          <Styled.DescriptionWrapper>
            <FlexComponent gap="10px" alignItems="center">
              <Styled.AttentionIconBox>
                <Styled.AttentionIcon src={AttentionIcon} alt="Attention" />
              </Styled.AttentionIconBox>
              <Styled.Description>
                Everything should be viewed as personal opinions only which do not reflect investments
                <br /> or views of the member’s employer(s); nothing should be viewed as investment advice
              </Styled.Description>
            </FlexComponent>
            {/* <Styled.BorderDescription /> */}
          </Styled.DescriptionWrapper>
        )}
        {!isFirstRender.current && !isPresetLoading ? (
          <>
            {postTab === HomePostTabs.ALL && (
              <FollowingPostsComponent
                isAll
                isShowLoader={isShowLoadingAll}
                setIsShowLoader={setIsShowLoadingAll}
                currentFilter={debouncedFilter}
                userProfileId={userProfileId}
                quotePostHandler={quotePostHandler}
                openReplyModal={openReplyModal}
              />
            )}
            {postTab === HomePostTabs.FOLLOWING && (
              <FollowingPostsComponent
                isShowLoader={isShowLoadingFollowing}
                setIsShowLoader={setIsShowLoadingFollowing}
                currentFilter={isFirstRender.current ? currentFilter : debouncedFilter}
                isAll={false}
                userProfileId={userProfileId}
                quotePostHandler={quotePostHandler}
                openReplyModal={openReplyModal}
              />
            )}
            {postTab === HomePostTabs.NEWS && (
              <NewsfeedPostsComponent
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                scrollRef={scrollRef}
                currentFilter={debouncedFilter}
              />
            )}
          </>
        ) : (
          <div />
        )}
      </div>
    </Styled.PostsWrapper>
  );
};
