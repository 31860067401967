import styled, { AnyStyledComponent, css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { MentionTextComponent } from './components/mention-text';
import { PostType } from '@/common/types/post.type';

export const QuotePostWrapper = styled.div<{
  isQuote?: boolean;
  isPublish?: boolean;
  isInModal?: boolean;
  isMemoPost?: boolean;
  isIdeaModal?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ isQuote }) => isQuote && 'margin-bottom: 18px;'};
  max-width: ${({ isPublish }) => (isPublish ? '650px' : '567px')};
  padding-left: ${({ isInModal, isMemoPost }) => (!isMemoPost && !isInModal ? '40px' : '0')};
  ${({ isPublish }) => isPublish && 'padding-left: 0'};
  ${({ isIdeaModal }) => isIdeaModal && 'padding-left: 0px'};
`;

export const QuotePostIcon = styled(Image)`
  margin-right: 5px;
  width: 15px;
  height: 15px;
`;

export const PostWrapper = styled.div<{
  isReply?: boolean;
  isQuote?: boolean;
  isOwnReply?: boolean;
  isNotDisaplay?: boolean;
  isClickable?: boolean;
  isPublish?: boolean;
  isInModal?: boolean;
  pl?: number;
  isIdeaModal?: boolean;
}>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'};
  display: flex;
  flex-direction: column;
  ${({ isReply }) => isReply && 'max-height: 74px;'};
  row-gap: 12px;
  overflow: visible;
  ${({ isOwnReply }) => isOwnReply && 'padding-left: 67px;position: relative;'};
  ${({ isOwnReply, isInModal }) => isOwnReply && isInModal && 'padding-left: 40px;'};
  ${({ isNotDisaplay }) => isNotDisaplay && 'display: none;'};
  ${({ isQuote }) => isQuote && 'padding-left: 10px;gap: 12px;'};
  width: 100%;
  min-width: 500px;
  ${({ isIdeaModal }) => isIdeaModal && 'padding-left: 40px'};
  ${({ isPublish }) => isPublish && 'min-width: 527px'};
`;

export const RepostBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PostBodyWrapper = styled.div<{ isQuote?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ isQuote }) => isQuote && 'gap: 5px;'}
`;

export const PostBodyWrapperWithoutDelete = styled.div<{
  ondelete?: boolean;
  isQuote?: boolean;
  isOwnReply?: boolean;
  isInsight?: boolean;
  isIdea?: boolean;
  isSearchClickedIdea?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  // ${({ isOwnReply }) => (isOwnReply ? 'gap: 8px;' : 'gap: 12px;')}
  ${({ isQuote }) => isQuote && 'gap: 12px;'}
  ${({ isInsight, isQuote }) => (isInsight && isQuote ? 'gap: 4px;' : 'gap: 4px;')}
  position: relative;
  ${({ ondelete }) => ondelete !== undefined && 'filter: blur(1.5px);'};
  ${({ isSearchClickedIdea }) => isSearchClickedIdea && 'gap: 6px;'};
`;

export const RepliedPostWrapper = styled.div``;

export const PostTextWrapper = styled.div<{ isQuote?: boolean; isHidden?: boolean; isOwnReply?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* margin-bottom: 12px;
  margin-top: 12px; */
  /* ${({ isOwnReply }) => (isOwnReply ? 'row-gap: 8px;' : 'row-gap: 12px;')} */
  cursor: pointer;
  ${({ isQuote }) => isQuote && 'row-gap: 5px;'}
  position: relative;
  ${({ isHidden }) =>
    isHidden &&
    ` 
    user-select: none !important;
    background: rgba(253, 253, 253, 0.8);
    filter: blur(2.5px);
  `}
`;

export const PostInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostText = styled(MentionTextComponent)<{ isQuote?: boolean; isOriginalPostFaded?: boolean }>`
  font-style: normal;
  word-wrap: normal;
  overflow-wrap: break-word;
  & > span {
    word-wrap: normal;
    overflow-wrap: break-word;
    word-break: normal;
  }
  overflow: hidden;
  line-height: 20px;
  color: ${({ isQuote }) => (isQuote ? COLORS.repliedPostText : COLORS.black)};
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  ${({ isOriginalPostFaded }) => isOriginalPostFaded && 'color: #858585;'}
`;

export const DeletedPostWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
`;

export const Trash = styled(Image)`
  width: 14px;
  height: 14px;
`;

export const TrashWrapper = styled.div`
  position: absolute;
  left: -40px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.trashWraperBackground};
`;

export const DeletedPostText = styled.div<{ isQuote?: boolean; isAnon?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  line-height: 13px;
  font-size: 13px;
  cursor: pointer;
  font-weight: ${FONTS.WEIGHTS.normal};
  color: ${({ isQuote }) => (isQuote ? COLORS.repliedPostText : COLORS.fullnamePostInfoColor)};
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  ${({ isQuote }) =>
    isQuote &&
    `font-size: ${FONTS.SIZES.m};font-weight: ${FONTS.WEIGHTS.light};line-height: 20px;padding-bottom: 0px; 
    cursor: text;`}
`;

export const DeleteBlock = styled.div<{ ondelete?: boolean; isInModal?: boolean; isAnon: boolean }>`
  background: ${({ ondelete }) => {
    if (ondelete) return 'rgba(255, 255, 255, 0.90)';
    return 'rgba(255, 255, 255, 0.80)';
  }};
  position: absolute;
  top: 0;
  left: 0;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  backdrop-filter: ${({ ondelete, isAnon }) => {
    if (!ondelete || isAnon) return 'blur(2.5px)';
    return 'blur(3.5px)';
  }};
  display: ${({ ondelete }) => (typeof ondelete === 'undefined' ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const HideTextBlock = styled.div`
  color: rgba(0, 0, 0, 0.2);
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 20px;
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

export const DeleteButton = styled(Image as AnyStyledComponent)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`;

export const PostImage = styled.img<{ postType?: PostType }>`
  width: ${({ postType }) => {
    switch (postType) {
      case PostType.Reply:
        return '350px';
      case PostType.Quote:
        return '300px';
      case PostType.Regular:
        return '400px';
      default:
        return '300px';
    }
  }};
  cursor: pointer;
  border-radius: 5px;
`;

export const VoteWrapper = styled.div`
  width: 385px;
`;
