import React, { useEffect, useState, ReactElement, useRef } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import Search from '@/styles/icons/search-gray-icon.png';
import Close from '@/styles/icons/close-search-input.png';
import Enter from '@/styles/icons/searc-input-enter-icon.png';
import * as Styled from './sidebar-search.styled';
import { useDetectOS, useElementPosition } from '@/common/hooks';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { FlexComponent } from '@/common/components/flex';

interface IProps {
  openSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

export const SidebarSearchComponent = ({ openSearch, open }: IProps): ReactElement | null => {
  const position = useElementPosition('Search');
  const { pathname, push } = useRouter();
  const [isVisible, setIsVisible] = useState<boolean | undefined>(open);
  const [inputValue, setInputValue] = useState<string>('');
  const os = useDetectOS();
  const isWindows = os === 'Windows';
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = (value?: string): void => {
    if (open) {
      push({
        pathname: ROUTER_KEYS.SEARCH_PAGE,
        query: {
          value
        }
      });
    }
  };

  useEffect(() => {
    if (open) {
      setIsVisible(true);
    }
  }, [open]);

  const handleAnimationComplete = (): void => {
    if (!open) {
      setIsVisible(false);
    } else {
      inputRef.current?.focus();
    }
  };

  const handleClose = (): void => {
    if (openSearch) {
      openSearch(false);
    }
    setInputValue('');
  };

  const handleKeyDown = (event: KeyboardEvent | globalThis.KeyboardEvent): void => {
    const cmdOrCtrlPressed = isWindows ? event.ctrlKey : event.metaKey;
    const isInput = event.target instanceof HTMLInputElement;

    if (cmdOrCtrlPressed && event.code === 'KeyK') {
      event.preventDefault();
      if (inputRef?.current?.value) {
        setInputValue('');
        return;
      }
      if (openSearch) {
        openSearch(!open);
      }
    } else if (event.code === 'Escape') {
      event.preventDefault();
      handleClose();
    } else if (event.code === 'Enter') {
      if (!isInput) {
        return;
      }
      event.preventDefault();
      handleSearch(inputRef?.current?.value);
    }
  };

  useEffect(() => {
    if (!pathname.includes(ROUTER_KEYS.SEARCH_PAGE)) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, isWindows]);

  if (typeof document === 'undefined' || !position || !isVisible) return null;

  return ReactDOM.createPortal(
    <Styled.SidebarSearchBox
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: open ? 1 : 0 }}
      transition={{ duration: 0.3 }}
      onAnimationComplete={handleAnimationComplete}
      onClick={() => {
        handleClose();
      }}
    >
      <Styled.SidebarSearchWrapper
        onClick={(e) => e.stopPropagation()}
        style={{
          top: position.top - 5,
          left: position.left - 1,
          width: position.width
        }}
      >
        <FlexComponent gap="13px" alignItems="center" position="relative">
          <Styled.SidebarSearchImage src={Search} alt="Search" />
          <Styled.SidebarSearchInput
            placeholder="Search across Villan .."
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <FlexComponent position="relative" gap="6px" ml="2px">
            <Styled.SidebarSearchEnterTextText>press enter</Styled.SidebarSearchEnterTextText>
            <Styled.SidebarSearchEnterImage src={Enter} alt="Search" />
          </FlexComponent>
          <FlexComponent
            tabIndex={0}
            style={{ cursor: 'pointer' }}
            alignItems="center"
            gap="4px"
            position="absolute"
            right="-91px"
            bottom="-2px"
            onClick={() => {
              handleClose();
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                handleClose();
              }
            }}
            role="button"
            aria-label="Close search modal"
          >
            <Styled.SidebarSearchCloseText>close</Styled.SidebarSearchCloseText>
            <Styled.SidebarSearchCloseImage src={Close} alt="Search" />
          </FlexComponent>
        </FlexComponent>
      </Styled.SidebarSearchWrapper>
    </Styled.SidebarSearchBox>,
    document.body
  );
};
