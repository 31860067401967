import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ClickAwayListener } from '@mui/material';
import Link from 'next/link';

import unreadNotActive from '@styles/icons/unreadNotActive.webp';
import unreadHoveredImg from '@styles/icons/unreadHovered.webp';
import skippedNotActive from '@styles/icons/skippedNotActive.webp';
import skipHoveredImg from '@styles/icons/skipHovered.webp';
import savedNotActive from '@styles/icons/savedNotActive.webp';
import saveHoveredImg from '@styles/icons/saveHovered.webp';
import { PostStatsComponent } from '@/common/components/post/components/post-stats';
import { PublishModalComponent } from '@/common/components/publish-memo/components/image-modal/images-modal.component';

import { IPost } from '@/common/types/post.type';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import { useAllHomePosts, useAllNewsfeeds, usePostBookmark, useSkipNewsfeed } from '@/common/hooks';
import * as Styled from './newsfeed-view.styled';

interface IPostStatus {
  isInOwnTread: boolean;
  isTreadOpen?: boolean;
  isPublish?: boolean;
  isInModal?: boolean;
  isReply?: boolean;
  isRepost?: boolean;
  isQuote?: boolean;
  isNotDisaplay?: boolean;
  isLast?: boolean;
  isInSearch?: boolean;
  isOwnReply?: boolean;
  isConversation?: boolean;
}

interface INewsfeedComponent {
  post: IPost;
  postStatus: IPostStatus;
  userProfileId: string;
  className?: string;
  onClickByPost?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickByRepliedPost?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  quotePostHandler?: (postId: string) => void;
  activeTab: NewsfeedNavigation;
  currentFilter?: IFilters;
  scrollAfterSkip?: () => void;
}

export const NewsfeedView = ({
  post,
  postStatus,
  userProfileId,
  className,
  onClickByPost,
  onClickByRepliedPost,
  openReply,
  quotePostHandler,
  activeTab,
  currentFilter,
  scrollAfterSkip
}: INewsfeedComponent) => {
  const { id: postId, deletedAt: notValidDeletedAt, createdAt, newsFeed } = post;
  const [saveHovered, setSaveHovered] = React.useState(false);
  const [skipHovered, setSkipHovered] = React.useState(false);
  const date = useMemo(() => (typeof createdAt === 'string' ? new Date(createdAt) : createdAt), [createdAt]);
  const { allPosts, deleteRepost, setUpdatePosts } = useAllNewsfeeds(userProfileId);

  const deletedAt = useMemo(
    () => (typeof notValidDeletedAt === 'string' ? new Date(notValidDeletedAt) : notValidDeletedAt),
    [notValidDeletedAt]
  );

  const { isPublish, isReply, isRepost, isQuote, isTreadOpen, isNotDisaplay, isInSearch } = postStatus;
  const { mutateAsync: actionBookmark } = usePostBookmark(activeTab, currentFilter);
  const { mutateAsync: actionSkip } = useSkipNewsfeed();
  const { deletePost: deletePostFollowing, deleteRepost: deleteRepostFollowing } = useAllHomePosts(false, userProfileId);

  const { deletePost: deletePostAll, deleteRepost: deleteRepostAll } = useAllHomePosts(true, userProfileId);

  const [modalImages, setModalImages] = useState<string[]>(newsFeed?.imgUrl ? [newsFeed.imgUrl] : []);
  const [selectedModalImage, setCurrentImage] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const [replyOpen, setReplyOpen] = useState(false);
  const [onDelete, setOnDelete] = useState<undefined | boolean>(undefined);
  const myRef = useRef<HTMLDivElement>(null);

  const { asPath } = useRouter();

  const hash = useMemo(() => asPath.split('#b').at(1), [asPath]);

  const bookmarkClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    if (postId) {
      actionBookmark({ postId });
    }
  };

  const skipClick = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    if (activeTab === NewsfeedNavigation.SAVED) {
      const data = await actionSkip({ postsId: [postId] });
      if (!data) return;
      data.posts.forEach((item) => {
        setUpdatePosts(item, userProfileId);
      });
    }
    if (activeTab === NewsfeedNavigation.UNREAD) {
      if (!allPosts) return;
      const idx = allPosts.findIndex((item) => item.id === postId);
      const skippedPosts = allPosts.slice(0, idx + 1);
      const postsId = skippedPosts.map((item) => item.id);
      const data = await actionSkip({ postsId: postsId ?? [postId] });
      if (!data) return;
      skippedPosts.forEach((item) => {
        deleteRepost({ id: item.id } as IPost, userProfileId);
        deleteRepostAll(item, userProfileId);
        deleteRepostFollowing(item, userProfileId);
        deletePostAll(item, userProfileId);
        deletePostFollowing(item, userProfileId);
      });
      if (scrollAfterSkip) scrollAfterSkip();
    }
    if (activeTab === NewsfeedNavigation.SKIPPED) {
      const data = await actionSkip({ postsId: [postId] });
      if (!data) return;
      deleteRepost({ id: postId } as IPost, userProfileId);
      data.posts.forEach((item) => {
        deleteRepostAll(item, userProfileId);
        deleteRepostFollowing(item, userProfileId);
        deletePostAll(item, userProfileId);
        deletePostFollowing(item, userProfileId);
      });
    }
  };

  function truncateText(text: string, maxLines: number, maxWidthPx: number) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;

    const font = 'Roboto Flex';
    const fontSize = 14;
    context.font = `${fontSize}px ${font}`;

    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    for (const word of words) {
      const currentLineWidth = context.measureText(currentLine + (currentLine === '' ? '' : ' ') + word).width;

      if (currentLineWidth <= maxWidthPx) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        lines.push(currentLine);
        if (lines.length > maxLines) break;
        currentLine = word;
      }
    }

    if (currentLine !== '') {
      lines.push(currentLine);
    }

    const truncatedText = lines.join(' ');

    if (lines.length > maxLines) {
      const newLines = lines.slice(0, maxLines);
      const checkText = newLines[1];
      let textWidth = context.measureText(checkText).width;
      const min = maxWidthPx - 20;

      let truncatedTextLast = checkText;
      let left = 0;
      let right = checkText.length - 1;

      while (left <= right) {
        const middle = Math.floor((left + right) / 2);
        truncatedTextLast = checkText.slice(0, middle);
        textWidth = context.measureText(truncatedTextLast).width;

        if (textWidth > min) {
          right = middle - 1;
        } else {
          left = middle + 1;
        }
      }

      truncatedTextLast = `${lines[0]} ${checkText.slice(0, right)}...`;
      return truncatedTextLast;
    }

    return truncatedText;
  }

  useEffect(() => {
    if (hash && hash === postId && !isRepost && !isQuote) {
      myRef.current?.scrollIntoView();
    }
  }, [hash]);

  const hashInTreads = useMemo(() => asPath.split('#u').at(1), [asPath]);

  useEffect(() => {
    if (hashInTreads && hashInTreads === postId) {
      myRef.current?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (replyOpen && !isTreadOpen) {
      setReplyOpen(false);
    }
  }, [isTreadOpen]);

  useEffect(() => {
    if (isTreadOpen) {
      setReplyOpen(true);
    }
  }, [isTreadOpen]);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (isReply || isQuote) && ((!deletedAt && onClickByPost && onClickByPost(e)) || (onClickByRepliedPost && onClickByRepliedPost(e)));

  const onClickByImage = (e: React.MouseEvent<HTMLImageElement, MouseEvent> | undefined) => {
    e?.stopPropagation();
    setModalImages(newsFeed?.imgUrl ? [newsFeed.imgUrl] : []);
    setCurrentImage(0);
    setOpen(true);
  };

  const onClickByPostText = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => !isReply && onClickByPost && onClickByPost(e);
  const filteredTags = newsFeed?.tags?.filter((it) => it.type !== 'source' && it.fullName !== 'US Election 2024') ?? [];
  return (
    <>
      <PublishModalComponent
        open={open}
        transitionExited={() => setModalImages([])}
        closeHandler={() => setOpen(false)}
        imgUrls={modalImages}
        current={selectedModalImage}
        setCurrentImage={setCurrentImage}
      />
      <Styled.QuotePostWrapper>
        <Styled.PostWrapper
          onClick={onClick}
          ref={myRef}
          isNotDisaplay={isNotDisaplay}
          className={className}
          isPublish={isPublish}
          isClickable={!!onClickByPost && (isReply || isQuote || isInSearch)}
          onDrop={(e) => {
            e.preventDefault();
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <ClickAwayListener onClickAway={() => typeof onDelete !== 'undefined' && setOnDelete(undefined)}>
            <Styled.PostBodyWrapper>
              <Styled.PostBodyWrapperWithoutDelete ondelete={onDelete}>
                <Styled.PostContent>
                  <Styled.NewsFeedContanWrapper>
                    <Styled.PostTextWrapper>
                      <Styled.PostHeader>
                        {newsFeed?.newsSource.imgUrl ? (
                          <Styled.SourceIcon src={newsFeed?.newsSource.imgUrl} alt="source icon" width="30" height="30" />
                        ) : (
                          <Styled.SourceIconWrapper />
                        )}
                        <Styled.HeaderTextWrapper>
                          <Styled.SourceName>{newsFeed?.newsSource.fullName}</Styled.SourceName>
                          <Styled.HeaderActionWrapper>
                            <Styled.RSSText>RSS</Styled.RSSText>
                            <Styled.RSSText>Feed</Styled.RSSText>
                          </Styled.HeaderActionWrapper>
                        </Styled.HeaderTextWrapper>
                      </Styled.PostHeader>
                      <Styled.MainInfoWrapper>
                        <Styled.PostTextWrapper>
                          <Link
                            href={newsFeed?.link ?? ''}
                            target="_blank"
                            rel="noopener"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Styled.PostTitle>{truncateText(newsFeed?.title ?? '', 2, 400)}</Styled.PostTitle>
                          </Link>
                          <Styled.PostDescription onClick={onClickByPostText}>
                            {!newsFeed?.description ? 'Article preview unavailable' : truncateText(newsFeed?.description ?? '', 2, 400)}
                          </Styled.PostDescription>
                        </Styled.PostTextWrapper>
                        {newsFeed?.imgUrl ? (
                          <Styled.PostImageWrapper>
                            <Styled.PostImage src={newsFeed?.imgUrl} onClick={onClickByImage} />
                          </Styled.PostImageWrapper>
                        ) : (
                          <Styled.PostImageScreensaver>
                            {newsFeed?.newsSource.imgUrl && (
                              <Styled.FeedIcon src={newsFeed?.newsSource.imgUrl} alt="source icon" width="34" height="34" />
                            )}
                          </Styled.PostImageScreensaver>
                        )}
                      </Styled.MainInfoWrapper>
                    </Styled.PostTextWrapper>
                    {!!filteredTags.length && (
                      <Styled.TagsWrapper>
                        {filteredTags.map((item) => (
                          <p key={item.id}>{item.fullName.toLowerCase()}</p>
                        ))}
                      </Styled.TagsWrapper>
                    )}
                  </Styled.NewsFeedContanWrapper>
                  <PostStatsComponent
                    isReply={Boolean(post.originalPostId)}
                    postId={postId}
                    date={date}
                    quotePostHandler={quotePostHandler}
                    profileId={userProfileId}
                    replyHandler={(e) => openReply && openReply(e)}
                    replyCountHandler={onClickByPostText}
                    activeTab={activeTab}
                    currentFilter={currentFilter}
                  />
                  <Styled.PostActionsWrapper>
                    <Styled.PostActionItem
                      onClick={bookmarkClick}
                      hoverColor="newsfeedActionRed"
                      onMouseOver={() => setSaveHovered(true)}
                      onMouseLeave={() => setSaveHovered(false)}
                    >
                      <Styled.EmptyDivSave />
                      <Styled.PostMarkActionIcon width={16} isShow={saveHovered} src={saveHoveredImg} alt="saved icon" />
                      <Styled.PostMarkActionIcon width={16} isShow={!saveHovered} src={savedNotActive} alt="saved icon" />
                      <Styled.PostActionText>save for later</Styled.PostActionText>
                    </Styled.PostActionItem>
                    <Styled.PostActionItem
                      onClick={skipClick}
                      hoverColor="newsfeedActionGreen"
                      onMouseOver={() => setSkipHovered(true)}
                      onMouseLeave={() => setSkipHovered(false)}
                    >
                      <Styled.EmptyDiv />
                      <Styled.PostMarkActionIcon
                        isShow={!!post && post?.isSkipped && skipHovered}
                        width={20}
                        src={unreadHoveredImg}
                        alt="unread icon"
                      />
                      <Styled.PostMarkActionIcon
                        isShow={!!post && post?.isSkipped && !skipHovered}
                        width={20}
                        src={unreadNotActive}
                        alt="unread icon"
                      />
                      <Styled.PostMarkActionIcon
                        isShow={!!post && !post?.isSkipped && skipHovered}
                        width={20}
                        src={skipHoveredImg}
                        alt="skipped icon"
                      />
                      <Styled.PostMarkActionIcon
                        isShow={!!post && !post?.isSkipped && !skipHovered}
                        width={20}
                        src={skippedNotActive}
                        alt="skipped icon"
                      />
                      <Styled.PostActionText>
                        {post?.isSkipped
                          ? 'mark this article as unread'
                          : activeTab !== NewsfeedNavigation.SAVED
                            ? 'skip this and all above articles'
                            : 'skip this article'}
                      </Styled.PostActionText>
                    </Styled.PostActionItem>
                  </Styled.PostActionsWrapper>
                </Styled.PostContent>
              </Styled.PostBodyWrapperWithoutDelete>
            </Styled.PostBodyWrapper>
          </ClickAwayListener>
        </Styled.PostWrapper>
      </Styled.QuotePostWrapper>
    </>
  );
};
