import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as Styled from './tag-list.styled';
import { Chip } from '@/common/components/chip';
import { OthersText } from '../../../post-insight/post-insight.styled';
import { ISector } from '@/common/types/coverage.type';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { AlgoliaIndexes } from '@/common/components/search-page/search-page.const';

interface IProps {
  listTitle?: string;
  feedId?: string;
  tagsList: ISector[];
  isOpen?: boolean;
  toggleShowAll?: (event: React.MouseEvent) => void;
}

export const TagListComponent = ({ listTitle, tagsList = [], feedId, isOpen, toggleShowAll }: IProps): ReactElement => {
  const listBlockRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<ISector[]>(tagsList);
  const [remainingWidth, setRemainingWidth] = useState<number>(0);
  const { push } = useRouter();

  // useEffect(() => {
  //   if (listBlockRef.current) {
  //     const containerWidth = listBlockRef.current.offsetWidth;
  //     let totalWidth = 0;
  //     const newVisibleTags: ISector[] = [];

  //     const chips = listBlockRef.current.children;
  //     for (let i = 0; i < chips.length; i += 1) {
  //       const chip = chips[i] as HTMLElement;
  //       const chipWidth = chip.offsetWidth + 5;

  //       if (totalWidth + chipWidth > containerWidth) {
  //         break;
  //       }

  //       totalWidth += chipWidth;
  //       newVisibleTags.push(tagsList[i]);
  //     }

  //     setVisibleTags(newVisibleTags);
  //     setRemainingWidth(containerWidth - totalWidth);
  //   }
  // }, [tagsList, isOpen]);

  useEffect(() => {
    if (listBlockRef.current) {
      const containerWidth = listBlockRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleTags: ISector[] = [];

      tagsList.forEach((chip, index) => {
        const chipElement = chip as unknown as HTMLElement;
        const chipWidth = chipElement.offsetWidth + parseFloat(getComputedStyle(chipElement).marginRight);
        if (totalWidth + chipWidth <= containerWidth) {
          newVisibleTags.push(tagsList[index]);
          totalWidth += chipWidth;
        } else {
          // eslint-disable-next-line no-useless-return
          return;
        }
      });

      setVisibleTags(newVisibleTags);
      setRemainingWidth(containerWidth - totalWidth);
    }
  }, [tagsList, isOpen]);

  const showOthersText = visibleTags?.length < tagsList?.length;
  const othersText = isOpen ? '— show fewer' : `+ ${tagsList.length - visibleTags.length} others`;

  const onClickCompany = (event: React.MouseEvent, company: ISector): void => {
    event.stopPropagation();
    push({
      pathname: ROUTER_KEYS.SEARCH_PAGE,
      query: {
        type: AlgoliaIndexes.SECURITY,
        value: company.name,
        clickedId: company.id,
        clickedValue: company.name,
        isClickOnMention: true
      }
    });
  };

  return (
    <Styled.Container>
      {listTitle && <Styled.Title>{listTitle}</Styled.Title>}
      {isOpen ? (
        <>
          {tagsList.map((company) => (
            <Chip onClick={(event) => onClickCompany(event, company)} label={company.id} color="primary" key={`${feedId}-${company.id}`} />
          ))}
          {showOthersText && (
            <OthersText onClick={toggleShowAll} style={{ cursor: 'pointer' }}>
              {othersText}
            </OthersText>
          )}
        </>
      ) : (
        <>
          <Styled.ListBlock>
            {visibleTags.map((company) => (
              <Chip
                onClick={(event) => onClickCompany(event, company)}
                label={company.id}
                color="primary"
                key={`${feedId}-${company.id}`}
              />
            ))}
          </Styled.ListBlock>
          {showOthersText && (
            <OthersText onClick={toggleShowAll} style={{ cursor: 'pointer', marginLeft: remainingWidth }}>
              {othersText}
            </OthersText>
          )}
        </>
      )}
    </Styled.Container>
  );
};
