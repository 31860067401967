import React, { useMemo } from 'react';

import { NewsfeedView } from '../newsfeed-view';

import { IStatistic, IPost } from '@/common/types/post.type';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';

interface IProps {
  post: IPost;
  userProfileId: string;
  onClickByPost: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  openReply?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  statistic?: IStatistic;
  isPublish?: boolean;
  isTreadOpen?: boolean;
  isInModal?: boolean;
  quotePostHandler: (postId: string) => void;
  activeTab: NewsfeedNavigation;
  currentFilter?: IFilters;
  scrollAfterSkip?: () => void;
}

export const BasicNewsfeed = ({
  post,
  userProfileId,
  onClickByPost,
  openReply,
  statistic,
  isTreadOpen,
  isInModal,
  quotePostHandler,
  isPublish,
  activeTab,
  scrollAfterSkip,
  currentFilter
}: IProps) => {
  const isLast = useMemo(() => (statistic?.replyCount || post.replyCount || 0) === 0, [statistic, post.replyCount]);
  return (
    <NewsfeedView
      postStatus={{ isInOwnTread: true, isLast, isTreadOpen, isPublish, isInModal }}
      post={post}
      key={post.id}
      openReply={openReply}
      onClickByPost={onClickByPost}
      quotePostHandler={quotePostHandler}
      userProfileId={userProfileId}
      activeTab={activeTab}
      currentFilter={currentFilter}
      scrollAfterSkip={scrollAfterSkip}
    />
  );
};
