import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { WEIGHTS } from '@/theme/fonts.const';

interface IVoteBarProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  allowChange?: boolean;
  isQuoted?: boolean;
  isOnboarding?: boolean;
  isPost?: boolean;
  isPopular?: boolean;
}

export const QuestionVoteWrapper = styled.div<{
  isOnboarding?: boolean;
  isLatest?: boolean;
  isFirst?: boolean;
  isDisabled?: boolean;
  isOnPost?: boolean;
  isRepost?: boolean;
}>`
  ${({ isFirst }) => isFirst && 'border-radius: 10px 10px 0px 0px;'}
  ${({ isLatest }) => isLatest && 'border-radius: 0px 0px 10px 10px;'}
  ${({ isFirst, isLatest }) => isFirst && isLatest && 'border-radius: 10px 10px 10px 10px;'}
  ${({ isOnboarding }) => isOnboarding && 'display: flex; gap: 15px;'}

  ${({ isDisabled, isOnboarding }) => isDisabled && isOnboarding && ' box-shadow: none;'}
  display:flex;
  flex-direction: column;
  align-items: ${({ isOnPost }) => (!isOnPost ? 'center' : 'flex-start')};
  ${({ isOnboarding, isOnPost }) => (!isOnboarding || isOnPost) && 'align-items: flex-start;'};
  ${({ isRepost }) => isRepost && 'margin-top: 5px;'};
  cursor: default;
`;

export const DebateOnAnotherUserProfileTitle = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  & > p {
    color: #000;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.light};
    line-height: ${FONTS.SIZES.xxl};
  }
`;

export const DebateAlmostUpWrapper = styled.div`
  color: #bc7100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  margin-bottom: 16px;
  & > span {
    color: #ddd;
    line-height: ${FONTS.SIZES.xxl};
    font-size: ${FONTS.SIZES.m};
    margin: 0 7px;
  }
  color: #bc7100;
  line-height: 22px;
`;

export const NewDebateWrapper = styled.div<{ isClicable?: boolean; isQuote?: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  margin-bottom: 6px;
  ${({ isQuote }) => isQuote && 'margin-bottom: 8px;'}
  & > p {
    color: #2e2e2e;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 13px;
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.semiBold};
    line-height: 13px;
    ${({ isQuote }) => isQuote && `font-weight: ${FONTS.WEIGHTS.light};color: #7C7C7C;`}
  }

  & > button {
    border: none;
    background: transparent;
    color: #b9b9b9;
    text-align: right;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 12px;
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.semiBold};
    line-height: 13px;

    max-width: 430px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ isClicable }) =>
      isClicable &&
      css`
        cursor: pointer;
        &:hover {
          color: #0016d8;
        }
      `}
  }
`;

export const QuestionTitleContainer = styled.div<{ isOnHome?: boolean; isOnboarding?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${(props) => (props.isOnboarding ? '0px' : '8px')};
  ${({ isOnboarding }) => isOnboarding && 'align-items: center;'}
  ${({ isOnHome }) => isOnHome && 'margin-bottom: 5px;'}
  color: #000;
`;

export const QuestionTextContainer = styled.div<{ isOnboarding?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${({ isOnboarding }) => (isOnboarding ? FONTS.WEIGHTS.thin : FONTS.WEIGHTS.normal)};
  display: flex;
  gap: 5px;
`;

export const QuestionText = styled.div<{
  isQuote?: boolean;
  isOnboarding?: boolean;
  isOnNotification?: boolean;
  isClickable: boolean;
  isTwoRows?: boolean;
  isOnPost?: boolean;
  isCurrent?: boolean;
  isAnon: boolean;
}>`
  display: inline;
  padding: 0px;
  white-space: nowrap;
  color: #000;
  ${({ isAnon }) => isAnon && 'color: #fff;'}
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  ${({ isCurrent, isOnboarding }) =>
    isOnboarding &&
    `color:${isCurrent ? '#000' : 'rgba(132, 132, 132, 1)'};
    width: 250px; 
    font-family: "Roboto Flex";
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px; 
    display:flex; 
    justify-content:center;`}

  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  ${({ isOnNotification }) => isOnNotification && 'color: #858585;'}
  ${({ isOnPost }) => isOnPost && 'line-height: 20px;'}
`;

export const QuestionCaption = styled.div<{ isQuote?: boolean; isOnNotification?: boolean; isClickable: boolean; isOnPost?: boolean }>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  color: #7c7c7c;
  ${({ isQuote }) => isQuote && 'color: #4D4D4D;'}
  ${({ isOnNotification }) => isOnNotification && 'color: #858585;'}
  ${({ isOnPost }) => isOnPost && 'line-height: 20px;'}
`;

export const OwnerText = styled.div<{ isOnboarding?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({ isOnboarding }) => (isOnboarding ? FONTS.WEIGHTS.thin : FONTS.WEIGHTS.light)};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  margin-top: 7px;
  margin-bottom: 13px;
  color: #636363;
`;

export const VoteWithSkipContainer = styled.div<{ isOnboarding?: boolean; voteWidth?: number }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: ${(props) => (props.isOnboarding ? '585px' : '100%')};
  ${({ isOnboarding }) => (isOnboarding ? 'padding: 2px 0px; align-items: center;' : 'margin-bottom: 0px;')}
  ${({ voteWidth }) => voteWidth && `width: ${voteWidth}px;`}
`;

export const KeyDebatesDescription = styled.p`
  padding-bottom: 4px;
  color: #868686;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-top: 14px;
`;

export const KeyDebateDescriptionOnAnotherUserProfile = styled.div`
  padding-top: 9px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
  & > button {
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: #0016d8;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  & > span {
    color: #ccc;
  }
  & > p {
    color: #3c3c3c;
  }
`;

export const KeyDebateDescriptionOnAnotherUserProfileAlreadyVoted = styled.p`
  color: #3c3c3c;
  padding-top: 5px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const KeyDebateDescriptionNotReveald = styled.p`
  color: #3c3c3c;
  padding-top: 9px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const QuestionSkip = styled.button<{
  voted: boolean;
  active: boolean;
  allowChange: boolean;
  isOnboarding?: boolean;
  isDisabled?: boolean;
  isLeft?: boolean;
}>`
  border: none;
  text-align: ${({ isLeft }) => (isLeft ? 'start' : 'end')};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 0.8125rem;
  line-height: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.thin} !important;
  color: #adadad;
  padding: 4px 5px;
  min-width: 65px;
  border-radius: 3px;
  background: rgba(236, 236, 236, 0.3);
  cursor: ${({ voted, allowChange }) => (voted && !allowChange ? 'auto' : 'pointer')};
  ${({ active }) => (active ? 'background: #D4D4D4' : '')};
  ${({ active }) => (active ? 'border-radius: 10px' : '')};
  ${({ isOnboarding }) => isOnboarding && 'border-radius: 3px; height: 36px;'}
  ${({ isOnboarding, active }) => isOnboarding && active && 'color: #414141; font-weight: 200; background: #E6E6E6;'}
  ${({ isOnboarding, isDisabled }) => isOnboarding && isDisabled && 'color: #E3E3E3; background: rgba(248, 248, 248, 0.30);'}
  ${({ isOnboarding, isDisabled, active }) =>
    isOnboarding && !isDisabled && !active && 'color: #ADADAD; font-weight: 100; background: rgba(236, 236, 236, 0.30);'}
`;

export const VoteContainer = styled.div<{ isOnboarding?: boolean; isPost?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  ${({ isPost }) => isPost && 'gap: 7px;'}
  width: 100%;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 13px;
  // font-weight: ${FONTS.WEIGHTS.light};
  line-height: 14px;
  cursor: pointer;
  background: transparent;
  ${({ isOnboarding }) => isOnboarding && 'align-items: center;'}
`;

export const VoteBarStyle = css<IVoteBarProps>`
  width: ${({ isOnboarding }) => (isOnboarding ? '65px' : '20%')};
  padding: 4px 5px;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  white-space: pre-wrap;
  line-height: 14px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  ${({ isPost }) => isPost && 'display: flex;justify-content: center;align-items: center;'}
  & > p {
    width: 100%;
    ${({ isPost }) => isPost && 'width: fit-content'};
  }
  ${({ isDisabled }) => (isDisabled ? 'background: #F8F8F8; color: #E3E3E3; font-weight:100;' : 'font-weight:200;')}
  ${({ isDisabled, allowChange }) => (isDisabled && !allowChange ? 'cursor: auto;' : '')}
`;

export const LeftVoteBar = styled.button<IVoteBarProps>`
  ${VoteBarStyle}
  text-align: left;
  & > p {
    text-align: left;
  }
  ${({ isPopular }) => isPopular && 'cursor: pointer;'}
  ${({ isOnboarding }) => isOnboarding && 'border-radius: 3px; background: rgba(90, 20, 205, 0.03); height: 36px;cursor: pointer;'}
  ${({ isDisabled }) => !isDisabled && 'background: rgba(90, 20, 205, 0.03); color: #732BEA;'}
  ${({ isPost }) => isPost && 'border-radius: 5px;border: 1px solid #FAFAFA;background: #FDFDFD;color: #4E0EB6;font-weight: 300;'}
  ${({ isPost, allowChange }) => isPost && allowChange && 'cursor: pointer;'}

  ${({ isSelected, isDisabled, isPost }) =>
    isSelected &&
    !isDisabled &&
    !isPost &&
    'background: linear-gradient(110deg, rgba(147, 82, 255, 0.25) 0%, rgba(138, 79, 235, 0.03) 100%);'}
  ${({ isSelected, isDisabled, isPost }) => isSelected && !isDisabled && isPost && 'background: rgba(78, 14, 182, 0.10);'}
  ${({ isSelected, isQuoted }) => isQuoted && isSelected && 'background: rgba(179, 179, 179, 0.15);color: #515151;'}
  ${({ isSelected, isQuoted }) => isQuoted && !isSelected && 'background: rgba(179, 179, 179, 0.03);color: #CACACA;'}
  ${({ isOnboarding, isDisabled }) => isOnboarding && isDisabled && 'background: rgba(248, 248, 248, 0.30);'}
`;
export const VoteTextContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
`;

export const RightVoteBar = styled.button<IVoteBarProps>`
  ${VoteBarStyle}
  text-align: right;
  & > p {
    text-align: right;
  }
  ${({ isPopular }) => isPopular && 'cursor: pointer;'}
  ${({ isOnboarding }) => isOnboarding && 'border-radius: 3px; background: rgba(18, 132, 125, 0.03); height: 36px;cursor: pointer;'}
  ${({ isDisabled }) => (!isDisabled ? 'background: rgba(18, 132, 125, 0.03); color: #12847D;' : '')}
  ${({ isPost }) => isPost && 'border-radius: 5px;border: 1px solid #FAFAFA;background: #FDFDFD;color: #0E5B57;font-weight: 300;'}
  ${({ isPost, allowChange }) => isPost && allowChange && 'cursor: pointer;'}
  ${({ isSelected, isDisabled, isPost }) =>
    isSelected && !isDisabled && !isPost
      ? 'background: linear-gradient(70deg, rgba(18, 132, 125, 0.05) 0%, rgba(0, 145, 136, 0.02) 0.01%, rgba(0, 198, 185, 0.25) 100%);'
      : ''}
  ${({ isSelected, isDisabled, isPost }) => (isSelected && !isDisabled && isPost ? 'background: rgba(14, 91, 87, 0.10);' : '')}
  ${({ isSelected, isQuoted }) => isQuoted && isSelected && 'background: rgba(179, 179, 179, 0.15);color: #515151;'}
  ${({ isSelected, isQuoted }) => isQuoted && !isSelected && 'background: rgba(179, 179, 179, 0.03);color: #CACACA;'}
  ${({ isOnboarding, isDisabled }) => isOnboarding && isDisabled && 'background: rgba(248, 248, 248, 0.30);'}
`;
export const RightVoteBarConfirm = styled(RightVoteBar)`
  width: 100%;
`;

export const LeftVoteBarConfirm = styled(LeftVoteBar)`
  width: 100%;
`;

export const SwingsTitle = styled.div`
  display: inline;
  align-items: start;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 27px;
  white-space: pre-wrap;
`;

export const PollIcon = styled.div<{ isTimeAlmostUp?: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: #04a300;
  backdrop-filter: blur(2.5px);
  justify-content: center;
  border-radius: 30px;
  position: absolute;
  left: -40px;
  top: ${({ isTimeAlmostUp }) => (isTimeAlmostUp ? '38px' : '0px')};
`;

export const RightConfirmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const LeftConfirmTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ConfirmTitle = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-weight: ${WEIGHTS.light};
  line-height: 18px;
  color: #000;
`;

export const ConfirmText = styled.div`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-weight: ${WEIGHTS.light};
  line-height: 18px;
  color: #4f4f4f;
`;

export const ConfirmVoteContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 7px;
  margin-top: 11px;
`;

export const ConfirmVoteLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HighlightedTitle = styled.p`
  display: inline;
  font-weight: ${WEIGHTS.semiBold};
`;

export const SwingsSubtitle = styled.span`
  color: #0018f1;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: nowrap;
`;

export const RulesTitle = styled.p`
  color: #0016d9;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  white-space: nowrap;
`;

export const RulesTitleImage = styled(Image)`
  width: 6px;
  height: 6px;
`;

export const RulesText = styled.p`
  color: #6e6e6e;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  margin-top: 8px;
  line-height: 18px;
  cursor: default;
`;
