import styled, { css } from 'styled-components';
import Image from 'next/image';
import { COLORS, FONTS } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { BoxAroundSection } from '@/common/components/page-wrapper/components/box-around-section';

export const PostsWrapper = styled(BoxAroundSection)`
  padding: 0;
  color: ${COLORS.black};
  font-family: ${FAMILIES.robotoFlex};
  width: 100%;
  position: relative;
`;

export const HeaderWithFilterWrapper = styled.div<{ sidebarWidth: number; isNotFixed?: boolean; pb?: number; w?: number }>`
  position: ${({ isNotFixed }) => (isNotFixed ? 'static' : 'fixed')};
  z-index: 10;
  top: 0;
  /* left: ${({ sidebarWidth }) => `${sidebarWidth}px + 16px`}; */
  padding-top: 11px;
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '12px')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: ${({ w }) => (w ? `${w}px` : '601')};

  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: ${COLORS.NEW_BORDER_COLOR};
    border: ${COLORS.NEW_BORDER};
  }
`;

export const RightDivider = styled.div<{ sidebarWidth: number; isHome?: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ sidebarWidth }) => `${sidebarWidth + 599}px`};
  width: 0.5px;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 2;

  ${({ isHome }) =>
    isHome &&
    css`
      width: 0px;
      border-right: 0.5px solid #f9f9f9;
    `}
`;

export const NoPostsText = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-left: 27px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 540px;
  margin-left: -5px;

  /* padding: 0 69px 8px 64px; */
  align-items: baseline;
`;

export const FilterButton = styled.button`
  border: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 7px 0 7px 10px;
  max-width: 77px;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  width: 79px;
  height: 30px;
  background: transparent;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.42px;
`;

export const FilterIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

export const HeaderDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${COLORS.black};
  border-radius: 50%;
  margin: 0 2px;
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const HeaderButton = styled.button<{ isActive: boolean }>`
  color: #6892ff;
  background-color: transparent;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  line-height: ${FONTS.SIZES.l};
  border: none;
  cursor: pointer;
  ${({ isActive }) => isActive && 'color: #0018F1;'}
  display: flex;
  & > p {
    margin-left: 5px;
  }
`;

export const Head = styled.h2<{ isAnon?: boolean }>`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const HeadCoverage = styled.p<{ isAnon: boolean }>`
  color: #0084bd;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
  letter-spacing: -0.36px;
  ${({ isAnon }) => isAnon && 'color: #7400CF;'}
`;

export const EditFilter = styled.p`
  color: #808080;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.28px;
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  gap: 10px;
  width: 100%;
  margin-top: 5px;
  padding: 0 33px 9px 27px;
  margin-bottom: 14px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: ${COLORS.NEW_BORDER_COLOR};
    border: ${COLORS.NEW_BORDER};
  }
`;

export const Description = styled.div`
  color: #9e9e9e;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.34px;
`;

export const BorderDescription = styled.div`
  width: 540px;
  height: 1px;
  background: #f5f5f5;
  margin-top: 20px;
`;

export const AddNewButton = styled.button<{ isHome?: boolean }>`
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(231, 231, 231, 0) 0%, #f5f5f5 100%);
  font-weight: ${WEIGHTS.light};
  font-size: ${({ isHome }) => (isHome ? SIZES.s : SIZES.m)};
  line-height: ${SIZES.m};
  cursor: pointer;
`;

export const SubHead = styled.div`
  position: sticky;
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: ${SIZES.l};
  line-height: ${SIZES.l};
  top: -${SIZES.xxl};
  padding: 2.5rem 0;
  margin: ${SIZES.xxl} 0;
  z-index: 100;
  background: linear-gradient(180deg, #fafafa 34.9%, rgba(250, 250, 250, 0.2) 100%);
`;

export const TrendingPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
`;

export const TrendingPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${SIZES.xxl};
`;

export const TrendingHeadWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  gap: 10px;
  font-family: ${FONTS.FAMILIES.ibmPlexSansHebrew};
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.s};
  letter-spacing: 0.6px;
`;

export const TrendingHeadTitle = styled.div`
  color: ${COLORS.trendingPostTitle};
`;

export const TrendingHeadSubtitle = styled.div`
  color: ${COLORS.trendingPostSubtitle};
`;

export const FeedTitle = styled.div`
  padding: 8px 0;
  margin: 50px 0;
  font-family: ${FONTS.FAMILIES.ibmPlexSansHebrew};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
  letter-spacing: 0.6px;
  color: ${COLORS.sectionHomeTitle};
  text-shadow: 2px 2px 1px #e5e5e5;
`;

export const Posts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  width: 100%;
`;

export const PaginationIntersection = styled.div`
  height: 1px;
  opacity: 0;
  width: 100%;
`;

export const AttentionIcon = styled(Image)`
  width: 18px;
  height: 18px;
`;

export const HomeHeadIcon = styled(Image)`
  width: 20px;
  height: 20px;
`;

export const AttentionIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #fbfbfb;
  backdrop-filter: blur(2.5px);
`;

export const EditFilterIcon = styled(Image)`
  width: 7px;
  height: 12px;
`;
