import { ISector } from './coverage.type';

export type CreateFeedDTO = {
  link: string;
  isAnon: boolean;
  title?: string;
  isFile: boolean;
};

export interface ISource {
  id: string;
  fullName: string;
  shortName?: string;
  sourceUrl: string;
  active: boolean;
  subscriptionId: number;
  imgUrl?: string;
  createdAt: string;
}

export enum sourceType {
  NEWSLETTER = 'NEWSLETTER',
  PODCAST = 'PODCAST'
}
export interface IInsightSource extends ISource {
  sourceType: sourceType;
}

export interface IChip {
  id: string;
  name: string;
  onDelete?: () => void;
}

export interface IInsightFeed {
  id: string;
  title: string;
  description?: string;
  link?: string;
  isFile: boolean;
  createDate: string;
  isRssInsight: boolean;
  topics: string[];
  sectors: string[];
  securities: ISector[];
  isAnon: boolean;
  sourceId: string;
  day?: number;
  month?: number;
  year?: number;
  source?: IInsightSource;
  isNewPodcastType: boolean;
}
