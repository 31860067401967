import NotificationNotActive from '@styles/icons/notification-not-active..png';
import NotificationActive from '@styles/icons/notification.webp';
import NotificationActiveAnon from '@styles/icons/notification-anon-active.webp';
import PollsNotActive from '@styles/icons/polls-sidebar-icon.png';
import PollsActiveAnon from '@styles/icons/polls-active-anon.webp';
import HomeNotActive from '@styles/icons/sidebar-feed.png';
import Home from '@styles/icons/sidebar-feed-active.png';
import HomeAnon from '@styles/icons/home-anon.webp';
import UserNotActive from '@styles/icons/profile-not-active.png';
import User from '@styles/icons/user.webp';
import UserAnon from '@styles/icons/user-anon.webp';
import DirectMessagesNotActive from '@styles/icons/inbox-not-active.webp';
import DirectMessagesActive from '@styles/icons/direct-message-active.webp';
import DirectMessagesActiveAnon from '@styles/icons/direct-message-active-anon.webp';
import SearchNotActive from '@styles/icons/search-not-active.webp';
import SearchActive from '@styles/icons/search-active.webp';
import SearchActiveAnon from '@styles/icons/search-active-anon.webp';
import Feather from '@styles/icons/feather-not-active.webp';
import FeatherActive from '@styles/icons/featherActive.webp';
import FeatherActiveAnon from '@styles/icons/featherActiveAnon.webp';
import FundLettersActive from '@styles/icons/sidebar-fund-letters-active.png';
import FundLetters from '@styles/icons/sidebar-fund-letters.png';
import PodcastsActive from '@styles/icons/sidebar-podcast-insights-active.png';
import Podcasts from '@styles/icons/sidebar-podcast-insights.png';
import PollsActive from '@styles/icons/polls-sidebar-active-icon.webp';
import { StaticImageData } from 'next/image';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';

export interface ISidebarListItem {
  value: StaticImageData;
  valueActive: StaticImageData;
  valueAnon: StaticImageData;
  valueActiveAnon: StaticImageData;
  name: string;
  link: string;
  marginBottom?: string;
  divider?: boolean;
  topPx?: number;
}

export const sidebarList: ISidebarListItem[] = [
  {
    value: Feather,
    valueActive: FeatherActive,
    valueAnon: Feather,
    valueActiveAnon: FeatherActive,
    name: 'Ideas',
    link: `${ROUTER_KEYS.MEMO}`,
    topPx: 1
  },
  {
    value: FundLetters,
    valueActive: FundLettersActive,
    valueAnon: FundLetters,
    valueActiveAnon: FundLetters,
    name: 'Fund Letters',
    link: `${ROUTER_KEYS.FUND_LETTERS}`
  },
  {
    value: Podcasts,
    valueActive: PodcastsActive,
    valueAnon: Podcasts,
    valueActiveAnon: Podcasts,
    name: 'Podcast Insights',
    link: `${ROUTER_KEYS.PODCAST_INSIGHTS}`
  },
  {
    value: HomeNotActive,
    valueActive: Home,
    valueAnon: HomeNotActive,
    valueActiveAnon: Home,
    name: 'Feed',
    link: `${ROUTER_KEYS.HOME}`,
    marginBottom: '12px',
    divider: true
  },
  // {
  //   value: PollsNotActive,
  //   valueActive: PollsActive,
  //   valueAnon: PollsNotActive,
  //   valueActiveAnon: PollsActive,
  //   name: 'Polls',
  //   link: `${ROUTER_KEYS.POLLS}`,
  //   marginBottom: '12px',
  //   divider: true
  // },
  {
    value: SearchNotActive,
    valueActive: SearchActive,
    valueAnon: SearchNotActive,
    valueActiveAnon: SearchActive,
    name: 'Search',
    link: '',
    topPx: 1
  },
  {
    value: DirectMessagesNotActive,
    valueActive: DirectMessagesActive,
    valueAnon: DirectMessagesNotActive,
    valueActiveAnon: DirectMessagesActive,
    name: 'Inbox (soon)',
    link: `${ROUTER_KEYS.MESSAGES}`,
    topPx: 1
  },
  {
    value: NotificationNotActive,
    valueActive: NotificationActive,
    valueAnon: NotificationNotActive,
    valueActiveAnon: NotificationActive,
    name: 'Notifications',
    link: `${ROUTER_KEYS.NOTIFICATIONS}`,
    topPx: 1
  },
  {
    value: UserNotActive,
    valueActive: User,
    valueAnon: UserNotActive,
    valueActiveAnon: User,
    name: 'Profile',
    link: `${ROUTER_KEYS.PROFILE}`
  }
];
