import styled, { AnyStyledComponent } from 'styled-components';
import Image from 'next/image';

import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';
import { COLORS } from '@/theme';

export const CategoryContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  background-color: transparent;
  padding: 15px 0px;
  width: 100%;
  border-bottom: 1px solid ${COLORS.notifcationDivider};
`;

export const FilterTitle = styled.p<{ isAnon: boolean }>`
  height: 28px;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  width: fit-content;
  justify-content: center;
  border: 1px solid ${COLORS.colorNotActive};
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0%;
  color: ${COLORS.colorInFilterButtonValidAndNotActive};
  font-family: ${FAMILIES.robotoFlex};
`;

/*
${({ isAnon }) =>
    isAnon &&
    `
    border: 1px solid rgba(57, 147, 231, 0.50);
    color: #3993E7;
  `}
*/

export const FilterButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
}>`
  border-radius: 15px;
  border: ${({ active }) => (active ? `1px solid ${COLORS.newsfeedFilterActiveBorder}` : `1px solid ${COLORS.borderFilter}`)};
  border: ${({ disabled }) => disabled && `1px solid ${COLORS.backgroundColorInFilterButtonNotValid}`};
  background: ${({ active }) => (active ? 'rgba(231, 212, 255, 0.40)' : `${COLORS.backgroundColorSeeAllButton}`)};
  background: ${({ disabled }) => disabled && COLORS.backgroundColorInFilterButtonNotValid};
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 11px;
  color: ${({ active }) => (active ? COLORS.newsfeedFilterActiveCategory : COLORS.colorInFilterButtonValidAndNotActive)};
  color: ${({ disabled }) => disabled && COLORS.colorInFilterButtonNotValid};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SIZES.m};
  font-weight: ${WEIGHTS.light};
  line-height: 14px;
  height: 28px;
  cursor: pointer;
`;

export const IconPlus = styled(Image as AnyStyledComponent)<{ isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? '8px' : '10px')};
  height: ${({ isActive }) => (isActive ? '8px' : '10px')};
  margin-left: 7px;
  margin-right: ${({ isActive }) => isActive && '2px'};
`;

export const ButtonItem = styled.button<{ isInclude?: boolean; isExclude?: boolean; isDisabled?: boolean; isAnon: boolean }>`
  display: flex;
  padding: 6px 11px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border-radius: 8px;
  border: 1px solid rgba(228, 228, 228, 0.75);
  background: rgba(228, 228, 228, 0.4);
  color: #717171;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
  ${(props) => props.isDisabled && 'background: rgba(228, 228, 228, 0.20);color: #C5C5C5; border: none;padding: 7px 12px; cursor: auto;'}
  ${(props) =>
    props.isInclude && 'border: 1px solid rgba(0, 168, 158, 0.50);padding: 6px 11px;background: rgba(139, 255, 248, 0.20); color: #00A89E;'}
  ${(props) =>
    props.isExclude && 'border: 1px solid rgba(74, 0, 168, 0.50)padding: 6px 11px;background: rgba(231, 212, 255, 0.40);color: #4A00A8;'}
`;

/*
${(props) =>
    props.isDisabled &&
    !props.isAnon &&
    'background: rgba(228, 228, 228, 0.20);color: #C5C5C5; border: none;padding: 7px 12px; cursor: auto;'}
  ${(props) =>
    props.isInclude &&
    !props.isAnon &&
    'border: 1px solid rgba(0, 168, 158, 0.50);padding: 6px 11px;background: rgba(139, 255, 248, 0.20); color: #00A89E;'}
  ${(props) =>
    props.isExclude &&
    !props.isAnon &&
    'border: 1px solid rgba(74, 0, 168, 0.50)padding: 6px 11px;background: rgba(231, 212, 255, 0.40);color: #4A00A8;'}
`;
${(props) =>
    !props.isInclude &&
    !props.isExclude &&
    !props.isDisabled &&
    props.isAnon &&
    'background: rgba(130, 130, 130, 0.40);color: #DBDBDB; border: none;padding: 7px 12px;'}
  ${(props) =>
    props.isDisabled &&
    props.isAnon &&
    'background: rgba(228, 228, 228, 0.20);color: #818181; border: none;padding: 7px 12px; cursor: auto;'}
    ${(props) => props.isInclude && props.isAnon && 'background: rgba(139, 255, 248, 0.40);color: #42FFF4; border: none;padding: 7px 12px;'}
    ${(props) =>
    props.isExclude &&
    props.isAnon &&
    `
    background: rgba(125, 22, 255, 0.60);
    color: #F1E0FF;
    border: none;
    padding: 7px 12px;
    `}
*/

export const ButtonIcon = styled(Image as AnyStyledComponent)<{ isAnon: boolean; isExclude: boolean }>`
  width: 10px;
  height: 10px;
  ${({ isAnon, isExclude }) => isExclude && 'width: 8px;height: 8px;margin:1px;'}
`;

export const DisabledIcon = styled(Image as AnyStyledComponent)`
  width: 10px;
  height: 10px;
  opacity: 0.5;
`;
