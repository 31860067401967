import { EnhancedWithAuthHttpService } from '@/common/services/http-auth.service';
import { axiosInstance } from '@/common/services/axios';
import { HttpService } from '@/common/services/http.service';
import { FollowedQuery, IQuestion, IQuestionProfileResponse, ISentiment } from '@/common/types/question.type';
import { IPost } from '../types/post.type';
import { IAddPollAnswerPayload, IPollFilterPayload, IPollPayload, IPollsList } from '../types/poll.types';
import { ALL_PERIODS } from '../components/poll/poll-page.component';

class PollService extends EnhancedWithAuthHttpService {
  constructor() {
    super(new HttpService(axiosInstance));
  }

  async getSectorQuestions() {
    return this.get<IQuestion[]>('poll/sector');
  }

  async getCompaniesQuestions() {
    return this.get<IQuestion[]>('poll/company');
  }

  async getQueriesQuestions() {
    return this.get<IQuestion[]>('poll/query');
  }

  async getSentimentProfileQuestions(profileId: string) {
    return this.get<IQuestionProfileResponse>(`poll/sentiment-profile/${profileId}`);
  }

  async getSentimentHomeQuestions() {
    return this.get<ISentiment[]>('poll/sentiment-home');
  }

  async getSentimentSwings({ page, limit, search, periods, regions, sectors, categories, signal, ...rest }: IPollFilterPayload) {
    const optionsUrl = new URLSearchParams();
    optionsUrl.append('page', page ? page.toString() : '1');
    optionsUrl.append('limit', limit ? limit.toString() : '20');
    optionsUrl.append('search', search || '');
    if (periods?.length) {
      periods.forEach((value) => {
        optionsUrl.append('periods', value.toString());
      });
    }
    if (rest.excludePeriods?.length) {
      rest.excludePeriods.forEach((value) => {
        optionsUrl.append('excludePeriods', value.toString());
      });
    }
    if (regions?.length) {
      regions.forEach((value) => {
        optionsUrl.append('regions', value);
      });
    }
    if (rest.excludeRegions?.length) {
      rest.excludeRegions.forEach((value) => {
        optionsUrl.append('excludeRegions', value);
      });
    }

    if (sectors?.length) {
      sectors.forEach((value) => {
        optionsUrl.append('sectors', value);
      });
    }
    if (rest.excludeSectors?.length) {
      rest.excludeSectors.forEach((value) => {
        optionsUrl.append('excludeSectors', value);
      });
    }
    if (categories?.length) {
      categories.forEach((value) => {
        optionsUrl.append('categories', value);
      });
    }
    if (rest.excludeCategories?.length) {
      rest.excludeCategories.forEach((value) => {
        optionsUrl.append('excludeCategories', value);
      });
    }
    return this.get<IPollsList>(`poll/poll-sentiment-swings?${optionsUrl}`, { signal });
  }

  async getAvailableFilters({ search, periods, regions, sectors, categories, ...rest }: Omit<IPollFilterPayload, 'limit' | 'page'>) {
    const optionsUrl = new URLSearchParams();
    optionsUrl.append('search', search || '');
    if (periods?.length) {
      periods.forEach((value) => {
        optionsUrl.append('periods', value.toString());
      });
    }
    if (rest.excludePeriods?.length) {
      rest.excludePeriods.forEach((value) => {
        optionsUrl.append('excludePeriods', value.toString());
      });
    }
    if (regions?.length) {
      regions.forEach((value) => {
        optionsUrl.append('regions', value);
      });
    }
    if (rest.excludeRegions?.length) {
      rest.excludeRegions.forEach((value) => {
        optionsUrl.append('excludeRegions', value);
      });
    }

    if (sectors?.length) {
      sectors.forEach((value) => {
        optionsUrl.append('sectors', value);
      });
    }
    if (rest.excludeSectors?.length) {
      rest.excludeSectors.forEach((value) => {
        optionsUrl.append('excludeSectors', value);
      });
    }
    if (categories?.length) {
      categories.forEach((value) => {
        optionsUrl.append('categories', value);
      });
    }
    if (rest.excludeCategories?.length) {
      rest.excludeCategories.forEach((value) => {
        optionsUrl.append('excludeCategories', value);
      });
    }
    return this.get<{ periods: number[]; regions: string[]; sectors: string[]; categories: string[] }>(
      `poll/avaiable-filters?${optionsUrl}`
    );
  }

  async getAllFilters() {
    const data = await this.get<{ periods: number[]; regions: string[] }>('poll/all-filters');
    // @ts-expect-error ///
    data.periods = ALL_PERIODS;
    return data;
  }

  async getPreselectedFilters() {
    return this.get<{ sectors: string[]; regions: string[]; categories: string[] }>('poll/preselected-filters');
  }

  async createQuestion(poll: IPollPayload) {
    return this.post<IPost, IPollPayload>('poll', poll);
  }

  async addQueryAnswer(answer: IAddPollAnswerPayload) {
    return this.post<{ post: IPost; pollId: string }, IAddPollAnswerPayload>('poll/answer', answer);
  }

  async getFollowedQueries() {
    return this.get<FollowedQuery[]>('poll/followed-queries');
  }

  async getQueryAnswer(pollId: string) {
    return this.get<number>(`poll/answer/${pollId}`);
  }
}

export const pollService = new PollService();
