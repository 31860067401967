import React, { FC } from 'react';
import ReplyActiveIcon from '@styles/icons/reply-active.webp';
import ReplyNotActiveIcon from '@styles/icons/reply-not-active.webp';
import ReplyActiveIconAnon from '@styles/icons/reply-active-anon.webp';
import ReplyNotActiveIconAnon from '@styles/icons/reply-not-active-anon.webp';
import * as Styled from './memo-section.styled';
import ArrowRight from '@/styles/icons/memo-arrow-right.webp';
import ArrowRightAnon from '@/styles/icons/arrow-right-new-anon.webp';
import { formatNumber } from '@/common/utils/post-statistics';
import { replaceIfMoreThanMax } from '@/common/utils/max-length-output';
import { IMemoOnProfile } from '@/common/types/memo.type';

interface IProps {
  isAnon: boolean;
  item: IMemoOnProfile;
  isLong: boolean;
  onClick?: () => void;
}

export const MemoItemInListComponent: FC<IProps> = ({ isAnon, item, isLong, onClick }) => (
  <Styled.ListItemContainer onClick={onClick}>
    <Styled.MemoText isAnon={isAnon} isLong={isLong}>
      {replaceIfMoreThanMax(item.security, 265, 14)}
    </Styled.MemoText>

    <Styled.ReplyContainer isAnon={isAnon} isActive={!!item.replyCount}>
      {!item?.replyCount ? (
        <>
          <Styled.ReplyIcon src={isAnon ? ReplyNotActiveIconAnon : ReplyNotActiveIcon} alt="reply" />
          <span>reply</span>
        </>
      ) : (
        <>
          <Styled.ReplyIcon src={isAnon ? ReplyActiveIconAnon : ReplyActiveIcon} alt="reply" />
          <span>{formatNumber(item.replyCount)}</span>
        </>
      )}
    </Styled.ReplyContainer>
    <Styled.ArrowImage src={isAnon ? ArrowRightAnon : ArrowRight} alt="arrow" />
  </Styled.ListItemContainer>
);
