import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import AddButtonIcon from '@styles/icons/add-button-icon.webp';
import AddButtonIconAnon from '@styles/icons/add-button-icon-anon.webp';
import Feather from '@styles/icons/memo-section-icon.webp';
import FeatherAnon from '@styles/icons/memo-section-icon-anon.webp';
import { useRouter } from 'next/router';
import * as Styled from './memo-section.styled';
import { HeaderTitleUnderlineWrapper } from '@/common/components/page-wrapper/components/box-around-section';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { IMemoOnProfile, IMemosOnProfile } from '@/common/types/memo.type';
import { MemoItemInListComponent } from './memo-item.component';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { JumpTo } from '@/common/types';
import { getIsAnonUser } from '@/common/hooks';

interface IProps {
  memosOnProfile?: IMemosOnProfile;
  isLoading: boolean;
  isAnonProfile?: boolean;
  isCurrent: boolean;
  isActive: boolean;
  isNonFinanceUser: boolean;
}

export const MemoSectionComponent: FC<IProps> = ({ memosOnProfile, isLoading, isAnonProfile, isCurrent, isActive, isNonFinanceUser }) => {
  const { push } = useRouter();
  // hooks
  const { shortMemos, longMemos } = memosOnProfile ?? { shortMemos: [], longMemos: [] };

  const onClickByPublishMemo = (id: string) => {
    push(`${ROUTER_KEYS.MEMO_PUBLISH}/${id}`);
  };

  const getMemoList = (list: IMemoOnProfile[], isLong: boolean) => (
    <Styled.ListContainer>
      {list.map((memo) => (
        <MemoItemInListComponent
          isAnon={!!isAnonProfile}
          key={`memo-on-profile-${memo.id}`}
          onClick={() => onClickByPublishMemo(memo.id)}
          isLong={isLong}
          item={memo}
        />
      ))}
    </Styled.ListContainer>
  );

  const onClickByNewMemo = () => {
    push({
      pathname: ROUTER_KEYS.MEMO,
      query: { modal: true }
    });
  };

  const isAnonUser = getIsAnonUser();

  return (
    <AnimatePresence>
      {!isLoading ? (
        (!!shortMemos.length || !!longMemos.length) && (
          <motion.div
            transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Styled.SectionWrapper isAnon={isAnonProfile} id={JumpTo.MEMOS} style={{ display: isActive ? 'flex' : 'none' }}>
              <Styled.SecurityCoverageWrapper>
                <Styled.SubsectionHeader>
                  <Styled.SubsectionHeaderLeft>
                    <HeaderTitleUnderlineWrapper isAnon={isAnonProfile}>
                      <Styled.SubsectionTitleWrapper>
                        <Image src={isAnonUser ? FeatherAnon : Feather} width={16} height={16} alt="profile-security-coverage" />
                        <Styled.SubsectionTitle isAnon={isAnonUser}>Ideas</Styled.SubsectionTitle>
                      </Styled.SubsectionTitleWrapper>
                    </HeaderTitleUnderlineWrapper>
                    <Styled.SubsectionDescription isAnon={isAnonProfile}>
                      Personal opinions only which do not reflect the investments or
                      <br /> views of the member’s employer(s) and is not investment advice
                    </Styled.SubsectionDescription>
                  </Styled.SubsectionHeaderLeft>
                </Styled.SubsectionHeader>
                {isCurrent && !isNonFinanceUser && (
                  <Styled.AddButton isAnon={isAnonProfile} onClick={onClickByNewMemo}>
                    <Styled.AddButtonIcon src={isAnonProfile ? AddButtonIconAnon : AddButtonIcon} alt="add" /> submit old or new idea
                  </Styled.AddButton>
                )}
                <Styled.ListsWrapper>
                  <>
                    {!!shortMemos.length && (
                      <>
                        <Styled.ListCaption isAnon={isAnonProfile}>
                          short <Styled.ListCaptionLight isAnon={isAnonProfile}>biased</Styled.ListCaptionLight>
                        </Styled.ListCaption>
                        {getMemoList(shortMemos, false)}
                        {!!longMemos.length && <Styled.ListDivider />}
                      </>
                    )}

                    {!!longMemos.length && (
                      <>
                        <Styled.ListCaption isAnon={isAnonProfile}>
                          long <Styled.ListCaptionLight isAnon={isAnonProfile}>biased</Styled.ListCaptionLight>
                        </Styled.ListCaption>
                        {getMemoList(longMemos, true)}
                      </>
                    )}
                  </>
                </Styled.ListsWrapper>
              </Styled.SecurityCoverageWrapper>
            </Styled.SectionWrapper>
          </motion.div>
        )
      ) : (
        <Styled.SectionWrapper isAnon={isAnonProfile} style={{ display: isActive ? 'flex' : 'none' }}>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </Styled.SectionWrapper>
      )}
    </AnimatePresence>
  );
};
