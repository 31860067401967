import styled from 'styled-components';
import Image from 'next/image';
import { ModalComponent } from '@/common/components/modal';
import { COLORS, FONTS } from '@/theme';
import { ButtonsStyles } from '../reply-modal/reply-modal.styled';

export const Wrapper = styled(ModalComponent)`
  display: flex;
  flex-direction: column;
  gap: 11.5px;
  min-width: 600px;
  max-width: 600px;
  min-height: calc(100vh - 30px);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
`;

export const CloseImage = styled(Image)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const NewQuotePostWrapper = styled.div`
  padding: 35px 30px 18px 30px;
  z-index: 999;
  position: relative;
`;

export const PreviousButton = styled.button`
  ${ButtonsStyles}
  position: absolute;
  top: 30px;
  right: 40px;
  display: flex;
  gap: 5px;
  border-radius: 10px;
`;

export const PrevieusButtonImage = styled(Image)`
  width: 12px;
  height: 12px;
`;
export const ModalTitle = styled.div<{ isOtherQuotePostsTitle?: boolean; isEmpty?: boolean }>`
  color: ${COLORS.black};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.l};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  line-height: ${FONTS.SIZES.l};
  margin-bottom: 30px;
  ${({ isOtherQuotePostsTitle }) => isOtherQuotePostsTitle && 'margin-bottom: 0px;'}
  ${({ isEmpty }) => isEmpty && 'margin-bottom: 0;'}
`;

export const PostComponentWrapper = styled.div`
  margin-left: 40px;
`;

export const NewQuotePostHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuotePostsWrapper = styled.div`
  padding: 25px 18px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ViewMoreButton = styled.button`
  display: flex;
  gap: 13px;
  padding: 10px 8px;
  width: fit-content;
  color: ${COLORS.postStatCountActiveNew};
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.m};
  font-weight: ${FONTS.WEIGHTS.normal};
  line-height: ${FONTS.SIZES.m};
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: -15px;
`;

export const BlueArrowDown = styled(Image)`
  width: 14px;
  height: 14px;
`;
