import { useState, useEffect } from 'react';

interface Position {
  top: number;
  left: number;
  width: number;
}

export const useElementPosition = (elementId: string): Position | null => {
  const [position, setPosition] = useState<Position | null>(null);

  useEffect(() => {
    const updatePosition = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const { top, left, width } = element.getBoundingClientRect();
        setPosition({ top, left, width });
      }
    };

    updatePosition();

    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, true);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [elementId]);

  return position;
};
