export const replaceIfMoreThanMax = (
  text: string,
  maxLength: number,
  textSize = 16,
  fontWeight = 400,
  textFont = 'Roboto Flex'
): string => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return '';
  ctx.font = `${textSize}px ${textFont}`;
  let textWidth = ctx.measureText(text).width;

  if (textWidth > maxLength) {
    let truncatedText = text;
    let left = 0;
    let right = text.length - 1;
    while (left <= right) {
      const middle = Math.floor((left + right) / 2);
      truncatedText = text.slice(0, middle);
      textWidth = ctx.measureText(truncatedText).width;
      if (textWidth > maxLength - 15) {
        right = middle - 1;
      } else {
        left = middle + 1;
      }
    }
    truncatedText = `${text.slice(0, right)} ..`;
    return truncatedText;
  }
  return text;
};
