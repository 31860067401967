import styled from 'styled-components';
import { FONTS } from '@/theme';

export const PaginationIntersection = styled.div`
  bottom: 25px;
  height: 1px;
  opacity: 0;
  width: 260px;
`;

export const ListWrapper = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  column-gap: 30px;
  row-gap: 14px;
  flex-wrap: wrap;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: black;
`;

export const EmptyListText = styled.p`
  color: #000;
  margin-left: 23px;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
`;
