import { escapeRegExp } from 'lodash';
import React, { Fragment } from 'react';

interface HighlightOptions {
  text: string;
  highlight: string;
  exactMatch?: boolean;
}

export const highlightText = ({ text, highlight, exactMatch = true }: HighlightOptions): React.ReactNode => {
  if (!highlight) return text;

  const sanitizedSearchText = escapeRegExp(highlight);
  const regex = exactMatch ? new RegExp(`\\b${sanitizedSearchText}\\b`, 'gi') : new RegExp(`(${sanitizedSearchText})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <Fragment key={index}>
            <span key={index}>{part}</span>
          </Fragment>
        ) : (
          part
        )
      )}
    </>
  );
};
