import styled from 'styled-components';
import { FAMILIES } from '@/theme/fonts.const';

export const ContainerFilters = styled.div`
  width: 100%;
  max-width: 250px;
  display: inline-flex;
  margin-left: 30px;
  margin-top: 28px;
  /* padding: 10px 25px 15px 25px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const FiltersTitle = styled.p`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  white-space: nowrap;
`;

export const FiltersText = styled.p`
  color: #515151;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
`;
