import styled from 'styled-components';
import { FONTS } from '@/theme';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;

  font-family: ${FONTS.FAMILIES.robotoFlex};
  text-align: center;
  font-size: ${FONTS.SIZES.m};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: ${FONTS.SIZES.xxl};
`;

export const TextWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #f1f4fe;
  max-width: 535px;

  display: flex;
  gap: 6px;

  color: #0016d8;
  display: flex;
  align-items: center;

  & .highlighted {
    color: #0018f1;
    font-weight: ${FONTS.WEIGHTS.medium};
  }
  span {
    max-width: 535px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TickerWrapper = styled.div`
  font-size: ${FONTS.SIZES.s};
  color: #0016d8;
  display: flex;
  align-items: center;
  margin-bottom: -2px;
`;
