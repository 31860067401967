import React, { useEffect, useMemo, useRef, useState } from 'react';
import ThreeDots from '@styles/icons/tree-dots-in-post-basic.svg';
import RePost from '@styles/icons/re-post-not-active.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as Styled from './post-basic-info.styled';
import { month } from '@/common/components/onboard/components/employer/employer.const';
import { ROUTER_KEYS } from '@/common/consts/app-keys.const';
import { findOutputVertical } from '@/common/utils/vertical-output';

interface IProp {
  imageUrl?: string;
  profileId?: string;
  fullName: string;
  date?: Date;
  role: string;
  vertical: string;
  sectors: string[];
  isRepost?: boolean;
  isReply?: boolean;
  isQuote?: boolean;
  isImageHidden?: boolean;
  isReplyHidden?: boolean;
  onThreeDotsClick?: (e?: React.MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => void;
  hideThreeDots?: boolean;
  isNewDesign?: boolean;
  isSearch?: boolean;
  nameMaxWidth?: number;
}

export const PostBasicInfoComponent = ({
  imageUrl,
  profileId,
  fullName,
  date,
  role,
  vertical,
  sectors,
  isRepost,
  isReply,
  isQuote,
  isImageHidden,
  isReplyHidden,
  onThreeDotsClick,
  hideThreeDots,
  isNewDesign,
  isSearch,
  nameMaxWidth
}: IProp) => {
  const ref = useRef<HTMLDivElement>(null);
  const { push } = useRouter();

  const timeString = useMemo(() => {
    if (!date) return;
    const currentDate = new Date().getTime();
    const diffInMinutes = Math.floor((currentDate - date.getTime()) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    let result = '';

    if (diffInDays <= 14) {
      result = `${diffInDays}d`;
    }
    if (diffInHours <= 24) {
      result = `${diffInHours}h`;
    }
    if (diffInMinutes <= 60) {
      result = `${diffInMinutes}m`;
    }
    const save = (
      <>
        <div>
          {date.getDate()} {month[date.getMonth()]} {date.getFullYear()}
        </div>
        <div>•</div>
        <div>
          {date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()}:
          {date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()}
        </div>
      </>
    );

    if (result.length > 0) {
      return (
        <>
          <div>{result}</div>
          {diffInHours > 24 && (
            <>
              <div>•</div>
              {save}
            </>
          )}
        </>
      );
    }

    return save;
  }, [date]);

  const [filteredSectors, setFilteredSectors] = useState<null | string>(null);

  const roleData = `${findOutputVertical(vertical)} ${role}`;

  useEffect(() => {
    if (ref && ref.current && ref.current.clientWidth && sectors.length > 0) {
      const screenWidth = ref.current.clientWidth;
      const ellipsisWidth = '...'.length * 5 + (roleData?.length ?? 0) * 5;

      let totalWidth = 0;
      let result = '';

      for (let i = 0; i < sectors?.length; i += 1) {
        const stringWidth = sectors[i].length * 7;
        if (totalWidth + stringWidth + ellipsisWidth <= screenWidth) {
          if (i < sectors.length - 1) {
            result += `${sectors[i]}, `;
            totalWidth += stringWidth + 5;
          } else {
            result += sectors[i];
            totalWidth += stringWidth;
          }
        } else {
          result += '...';
          break;
        }
      }
      setFilteredSectors(result);
    }
  }, [ref.current?.clientWidth, sectors]);

  const onClickBy = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (profileId && !isReplyHidden) {
      event.stopPropagation();
      push(`${ROUTER_KEYS.PROFILE}?id=${profileId}`);
    }
  };

  return (
    <Styled.Wrapper isSearch={isSearch}>
      {isRepost && <Image src={RePost} alt="Repost" />}
      <Styled.ImageWrapper
        isReply={isReply || isQuote}
        imageUrl={imageUrl}
        profileId={profileId}
        borderRadius="30px"
        isImageHidden={isImageHidden}
        isReplyHidden={isReplyHidden}
      />
      <Styled.InfoWrapper isReply={isReply || isQuote}>
        <Styled.InfoRow>
          <Styled.NameWrapper nameMaxWidth={nameMaxWidth} onClick={onClickBy}>
            {isReplyHidden ? '' : fullName}
          </Styled.NameWrapper>
          <Styled.DateWrapper>{timeString}</Styled.DateWrapper>
        </Styled.InfoRow>
        <Styled.InfoRow isMinMargin ref={ref}>
          <Styled.RoleAndSectorsWrapper isSearch={isSearch} isNewDesign={isNewDesign}>
            {!isReplyHidden && (
              <>
                <div>{roleData}</div>
                {!!filteredSectors && filteredSectors.length > 0 && (
                  <Styled.RoleAndSectorDot isNewDesign={isNewDesign}>•</Styled.RoleAndSectorDot>
                )}
                <div>{filteredSectors}</div>
              </>
            )}
          </Styled.RoleAndSectorsWrapper>
          {!hideThreeDots && !isReply && (
            <Styled.DotsImage
              onClick={(e: React.MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => onThreeDotsClick && onThreeDotsClick(e)}
              src={ThreeDots}
              alt="dots"
            />
          )}
        </Styled.InfoRow>
      </Styled.InfoWrapper>
    </Styled.Wrapper>
  );
};
