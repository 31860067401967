import { useEffect, useState } from 'react';

type BrowserType = 'Chrome' | 'Firefox' | 'Safari' | 'Edge' | 'IE' | 'Opera' | 'Unknown';

const detectBrowser = (): BrowserType => {
  const { userAgent } = navigator;

  if (/Edg/i.test(userAgent)) return 'Edge';
  if (/Chrome/i.test(userAgent) && !/Edg/i.test(userAgent)) return 'Chrome';
  if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) return 'Safari';
  if (/Firefox/i.test(userAgent)) return 'Firefox';
  if (/OPR|Opera/i.test(userAgent)) return 'Opera';
  if (/MSIE|Trident/i.test(userAgent)) return 'IE';

  return 'Unknown';
};

export const useBrowserDetection = (): BrowserType => {
  const [browser, setBrowser] = useState<BrowserType>('Unknown');

  useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return browser;
};
