export const SHOW_ANSWERS_MIN_VOTES = Number(process.env.NEXT_PUBLIC_SHOW_ANSWERS_MIN_VOTES) || 10;

export const HOME_TOPICS_FILTERS_LIST = [
  {
    id: 'analysis',
    include: false
  },
  {
    id: 'alt data',
    include: false
  },
  {
    id: 'ai',
    include: false
  },
  {
    id: 'hf',
    include: false
  },
  {
    id: 'general',
    include: false
  },
  {
    id: 'vc',
    include: false
  },
  {
    id: 'macro',
    include: false
  },
  {
    id: 'start-ups',
    include: false
  },
  {
    id: 'pe',
    include: false
  },
  {
    id: 'credit',
    include: false
  },
  {
    id: 'real assets',
    include: false
  },
  {
    id: 'allocation',
    include: false
  },
  {
    id: 'advisory',
    include: false
  },
  {
    id: 'politics',
    include: false
  }
];

export const HOME_SOURCE_FILTERS_LIST = [
  // {
  //   id: 'insight_feed_id_newsletters',
  //   label: 'newsletters',

  //   include: false
  // },
  // {
  //   id: 'insight_feed_id_podcast',
  //   label: 'podcasts',

  //   include: false
  // },
  // {
  //   id: 'insight_feed_id',
  //   label: 'fund letters',

  //   include: false
  // },
  // {
  //   id: 'memo_id',
  //   label: 'ideas',

  //   include: false
  // },
  // {
  //   id: 'poll_id',
  //   label: 'polls',

  //   include: false
  // },
  {
    id: 'post_id',
    label: 'posts',
    include: false
  },
  {
    id: 'question_id',
    label: 'debates',

    include: false
  },
  {
    id: 'idea_id',
    label: 'coverage',
    include: false
  },
  {
    id: 'hf',
    label: 'video',

    include: false
  },
  {
    id: 'review_id',
    label: 'reviews',
    include: false
  }
];
