// @ts-nocheck ///
import React, { ReactElement } from 'react';
import * as Styled from './chip.styled';
import { CustomChipProps } from './chip.types';

export const Chip = (props: CustomChipProps): ReactElement => {
  const { color = 'default', onClick, ...restProps } = props;

  return (
    <Styled.Chip
      disableRipple
      color={color}
      onClick={onClick}
      {...restProps}
      sx={{
        '&:focus': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        }
      }}
    />
  );
};
