import Image from 'next/image';
import styled, { AnyStyledComponent } from 'styled-components';
import { COLORS, SPACES } from '@/theme';
import { FAMILIES, SIZES, WEIGHTS } from '@/theme/fonts.const';

export const BackToPostWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: ${SPACES.xs};
`;

export const BacktToPostText = styled.div`
  font-family: ${FAMILIES.robotoFlex};
  font-style: normal;
  font-weight: ${WEIGHTS.normal};
  font-size: ${SIZES.xs};
  line-height: ${SIZES.xs};
  margin-left: 3px;
  cursor: pointer;
  color: ${COLORS.backToPostButtonColor};
`;

export const RowImages = styled.div`
  margin-left: ${SPACES.xs};
  display: flex;
  max-width: 180px;
`;

export const ImageItem = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

export const NoImageItem = styled(Image as AnyStyledComponent)`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

export const ExpandButton = styled.button<{ isOwnReply: boolean }>`
  padding-left: 10px;
  font-size: ${SIZES.m};
  line-height: ${SIZES.m};
  font-family: ${FAMILIES.robotoFlex};
  color: ${COLORS.black};
  border: none;
  background-color: ${COLORS.opacityZero};
  cursor: pointer;
  width: fit-content;
  border-left: solid
    ${({ isOwnReply }) => (isOwnReply ? `1px ${COLORS.borderColorForOwnReplyTile}` : `2px ${COLORS.borderColorForReplyTile}`)};
  display: flex;
  column-gap: 10px;
  height: 40px;
  align-items: center;
`;

export const ExpandButtonTextInConversationTread = styled.div`
  color: ${COLORS.black};
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: ${SIZES.s};
  font-style: normal;
  font-weight: ${WEIGHTS.medium};
  line-height: ${SIZES.s};
  letter-spacing: 0.6px;
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TwoTreadsWrapper = styled.div<{ isNotDisplay?: boolean }>`
  display: ${({ isNotDisplay }) => (isNotDisplay ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
`;

export const PostDivider = styled.div<{ isDefaultWidth?: boolean }>`
  width: calc(100% + 60px);
  height: 1px;
  margin-left: -30px;
  ${({ isDefaultWidth }) => isDefaultWidth && 'width: 100%;margin-left: 0;margin-right: 0;'}
  margin-top: 25px;
  background: #e7e7e7;
`;
