import React from 'react';
import * as Styled from '../basic-status.styled';

interface IFollowModalButtonProps extends React.HTMLProps<HTMLButtonElement> {
  active?: boolean;
  disabled?: boolean;
}

export default function FollowModalButton({ children, active, disabled, onClick }: IFollowModalButtonProps) {
  return (
    <Styled.FollowModalUserButton
      $disabled={disabled}
      $active={active ?? false}
      disabled={!active || disabled}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
    </Styled.FollowModalUserButton>
  );
}
