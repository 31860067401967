import styled from 'styled-components';
import { COLORS, SPACES } from '@/theme';
import { FAMILIES, WEIGHTS } from '@/theme/fonts.const';
import { subSectorsWidths } from './vertical-role-modal.const';

export const Wrapper = styled.div`
  width: 770px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  font-weight: ${WEIGHTS.light};
  border-radius: 10px 10px 0px 10px;
`;

export const HeadRow = styled.div`
  margin: 10px 0;
  font-family: ${FAMILIES.ibmPlexSansHebrew};
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS.headTextColorInModal};
`;

interface IRowProps {
  isVertical?: boolean;
  isSubVertical?: boolean;
  isRoles?: boolean;
  isNonFinenceSubVertical?: boolean;
  isNonFinenceSubSector?: string;
  isOnEditPage?: boolean;
}

const getSubSectorWidth = (sector: string): string => subSectorsWidths[`${sector}`];

export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  color: ${COLORS.secondLightGray};
  max-width: ${({ isVertical, isSubVertical, isRoles, isNonFinenceSubVertical, isNonFinenceSubSector }) => {
    switch (true) {
      case isNonFinenceSubSector !== undefined:
        return getSubSectorWidth(isNonFinenceSubSector!);
      case isNonFinenceSubVertical:
        return '500px';
      case isVertical:
        return '486px';
      case isSubVertical:
        return '703px';
      case isRoles:
        return '525px';
    }
  }};
  ${({ isOnEditPage }) =>
    isOnEditPage &&
    `
      align-items: flex-start;
      justify-content: flex-start;
      max-width:920px;
      gap:3px;`}
`;

export const Item = styled.button<{ isActive: boolean; isDisabled: boolean; isOnEditPage?: boolean }>`
  cursor: pointer;
  background: ${COLORS.opacityZero};
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 20px;
  border: 0;
  background: #fff;
  ${({ isDisabled }) => isDisabled && 'color: rgba(0, 0, 0, 0.25);'}
  ${({ isDisabled }) => isDisabled && 'background: rgba(255, 255, 255, 0.50);'}
  border-radius: 5px;
  padding: 5px 12px;
  display: flex;
  ${({ isOnEditPage, isDisabled, isActive }) =>
    isOnEditPage &&
    `
  font-weight: 300;
    padding:3px 10px;
    border:1px solid ${isDisabled ? 'rgba(239, 239, 239, 1)' : isActive ? 'rgba(80, 80, 80, 1)' : 'rgba(239, 239, 239, 1)'};
  `}
`;

export const Input = styled.input<{ isOnEditPage?: boolean }>`
  ${({ isOnEditPage }) =>
    isOnEditPage
      ? `
    :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  margin: ${SPACES.xs} 0;
  background-color: ${COLORS.backgroundOpacityZero};
  border: none;
  width: 100%;
  color: ${COLORS.black};
  letter-spacing: ${SPACES.mediumLetterSpacing};
  font-family: ${FAMILIES.robotoFlex};
  font-size: ${SPACES.m};
  line-height: ${SPACES.m};
  letter-spacing: 0rem;
  font-weight: ${WEIGHTS.normal};
  outline: none;

  ::placeholder {
    color: ${COLORS.secondLightGray};
  }
    margin: 0px;
  padding: 7px 5px 3px 5px;
  font-size: 14px;
  font-weight: ${WEIGHTS.light};
  color: #000;
  font-family: Roboto Flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  ::placeholder {
    color: #000;
    opacity: 1;
  }
  `
      : `min-width: 400px;

  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;

  text-align: center;

  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background: #fff;

  color: #000;

  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 20px;
  ::placeholder {
    color: #000;
    opacity: 1;
  }

  :focus-visible {
    outline: 0;
  }`}
`;
