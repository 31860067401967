import styled, { AnyStyledComponent, css } from 'styled-components';
import { COLORS } from '@/theme';
import { FAMILIES, SIZES } from '@/theme/fonts.const';
import { MentionTextComponent } from '../mention-text';

export const MemoWrapper = styled('div')<{ isQuote: boolean | undefined; gap?: number }>`
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  /* ${({ gap }) => gap && `${gap}px`} */
  /* gap: 5px; */
`;

export const MemoTitle = styled('span')<{ isNotificationPage?: boolean }>`
  color: #575757;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  ${({ isNotificationPage }) =>
    isNotificationPage &&
    css`
      color: #7c7c7c;
      font-style: normal;
      font-weight: 300;
      min-width: 46px;
      margin-right: 5px;
    `}
`;

export const SearchMemoTitle = styled(MemoTitle)`
  color: #7c7c7c;
  font-weight: 300;
`;

export const MemoPostText = styled('p')`
  color: #000;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-top: -5px;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const MemoPostTextMention = styled('p')`
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;

  & > span {
    font-weight: 600;
  }
`;

export const MemoTitleSection = styled(MemoTitle)``;

export const LinkOnMemo = styled('span')<{ isShort: boolean; isQuote: boolean | undefined }>`
  color: ${({ isShort }) => (isShort ? COLORS.blue : COLORS.esmerald)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  max-width: 450px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkOnMemoNotification = styled('span')<{ isShort: boolean; isQuote: boolean | undefined }>`
  color: ${({ isShort }) => (isShort ? COLORS.blue : COLORS.esmerald)};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  cursor: pointer;
  max-width: 450px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkOnMemoHome = styled('span')<{ isShort: boolean; isQuote: boolean | undefined }>`
  color: ${({ isShort }) => (isShort ? COLORS.blue : COLORS.esmerald)};
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  white-space: normal;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    color: #000;
    font-family: 'Roboto Flex';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const LinkOnMemoSearchDef = styled(LinkOnMemo)`
  font-weight: 300;

  & > span {
    font-weight: 600;
  }
`;

export const LinkOnMemoSearch = styled.p<{ isShort: boolean; isQuote: boolean | undefined }>`
  // color: ${({ isShort, isQuote }) => (isQuote ? '#4D4D4D' : isShort ? COLORS.blue : COLORS.esmerald)};
  color: ${({ isShort }) => (isShort ? COLORS.blue : COLORS.esmerald)};
  cursor: pointer;
  font-weight: 300;

  & > span {
    font-weight: 600;
  }
`;

export const MemoText = styled('span')<{ isQuote: boolean | undefined }>`
  color: ${({ isQuote }) => (isQuote ? '#4D4D4D' : COLORS.black)};
  white-space: pre-wrap;
  word-break: break-word;
  cursor: default;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const MemoTextNotification = styled('span')<{ isQuote: boolean | undefined }>`
  color: ${({ isQuote }) => (isQuote ? '#4D4D4D' : COLORS.black)};
  color: #000;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

export const MemoTextNew = styled('span')<{ isQuote: boolean | undefined }>`
  white-space: pre-wrap;
  word-break: break-word;
  cursor: default;
  color: #000;
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const PostText = styled(MentionTextComponent as AnyStyledComponent)<{ isReply?: boolean; isQuote?: boolean }>`
  margin-bottom: -0.313rem;
  overflow: hidden;
  max-width: 430px;
  line-height: ${SIZES.xxxxl};
  color: ${({ isReply, isQuote }) => (isReply || isQuote ? COLORS.repliedPostText : COLORS.black)};
  ${({ isReply }) => !isReply && 'overflow-wrap: break-word;'}
  ${({ isReply }) => isReply && 'white-space: nowrap;text-overflow: ellipsis;'}
`;

export const Link = styled.span`
  display: flex;
  gap: 5px;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  color: ${COLORS.darkerBlue};
  font-family: ${FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  white-space: nowrap;
`;

export const LinkHome = styled.a`
  margin-left: 4px;
  color: #0018f1;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Arrow = styled.img`
  width: 8px;
  height: 8px;
`;

export const ClickBlock = styled.div`
  position: absolute;
  top: 8px;
  left: -45px;
  width: 45px;
  height: 95%;
`;
