import React, { useMemo, useState } from 'react';
import AddButtonIcon from '@styles/icons/add-button-icon.webp';
import AddButtonIconAnon from '@styles/icons/add-button-icon-anon.webp';
import Image from 'next/image';
import ProfileSecurityCoverageIcon from '@styles/icons/profile-security-coverage.webp';
import ProfileSecurityCoverageIconAnon from '@styles/icons/profile-security-coverage-anon.webp';
import { AnimatePresence, motion } from 'framer-motion';
import * as Styled from './security-coverage-key-debates-section.styled';
import { IdeaDTO, JumpTo } from '@/common/types';
import { BEAR_ANSWER, BULL_ANSWER, NEUTRAL_ANSWER, SKIP_ANSWER } from '@/common/consts/idea.const';
import { IdeaItemInListComponent } from './idea-item-in-list/idea-item-in-list.component';
import { HidenSectionComponent } from '../hiden-section';
import { ReplyModalWrapper } from '../reply-modal';
import { LoaderWrapper } from '@/common/components/page-wrapper';
import { Loader } from '@/common/components/loader';
import { HeaderTitleUnderlineWrapper } from '@/common/components/page-wrapper/components/box-around-section';
import { CreateModalComponent } from '@/common/components/create-modal/create-modal.component';
import { CreateModalType } from '@/common/components/create-modal/modal-type.const';
import { getIsAnonUser } from '@/common/hooks';

interface IProps {
  isAnonProfile?: boolean;
  ideas: IdeaDTO[];
  isIdeasHidden: boolean;
  isIdeasDoesntExist: boolean;
  isIdeasLoading: boolean;
  ideasOutputTextForHidden?: string;
  isCurrent: boolean;
}

export const SecuritySectionComponent: React.FC<IProps> = ({
  isAnonProfile,
  ideas,
  isIdeasHidden,
  isIdeasDoesntExist,
  isIdeasLoading,
  ideasOutputTextForHidden,
  isCurrent
}) => {
  const [ideasModal, setIdeasModal] = useState(false);

  const bearIdeas = useMemo(() => ideas.filter((item) => item.priority === BEAR_ANSWER), [ideas]);
  const neutralIdeas = useMemo(
    () => ideas.filter((item) => item.priority === NEUTRAL_ANSWER || item.priority === SKIP_ANSWER || !item.priority),
    [ideas]
  );
  const bullIdeas = useMemo(() => ideas.filter((item) => item.priority === BULL_ANSWER), [ideas]);

  const ideasPlusClick = () => {
    setIdeasModal(true);
  };

  const { openReplyModal, componentToView } = ReplyModalWrapper({});

  const getIdeasList = (list: IdeaDTO[], answer: number) => (
    <Styled.ListContainer>
      {list.map((idea) => (
        <IdeaItemInListComponent
          isAnon={isAnonProfile}
          key={`idea-${idea.id}`}
          onClick={() => openReplyModal(idea.post, idea.post)}
          answer={answer}
          idea={idea}
        />
      ))}
    </Styled.ListContainer>
  );

  const isAnonUser = getIsAnonUser();

  return (
    <>
      <CreateModalComponent open={ideasModal} closeHandler={() => setIdeasModal(false)} modalType={CreateModalType.COVERAGE} />
      {componentToView}
      <AnimatePresence>
        {!isIdeasLoading ? (
          ((!!ideas.length && !isIdeasHidden) || (!isIdeasDoesntExist && isIdeasHidden)) && (
            <motion.div
              transition={{ ease: 'backInOut', delay: 0.5, duration: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Styled.SectionWrapper isAnon={isAnonProfile} id={JumpTo.SECURITY_COVERAGE}>
                <Styled.SecurityCoverageWrapper>
                  <Styled.SubsectionHeader>
                    <Styled.SubsectionHeaderLeft>
                      <HeaderTitleUnderlineWrapper isAnon={isAnonProfile}>
                        <Styled.SubsectionTitleWrapper>
                          <Image
                            src={isAnonUser ? ProfileSecurityCoverageIconAnon : ProfileSecurityCoverageIcon}
                            width={16}
                            height={16}
                            alt="profile-security-coverage"
                          />
                          <Styled.SubsectionTitle isAnon={isAnonUser}>Security Coverage</Styled.SubsectionTitle>
                        </Styled.SubsectionTitleWrapper>
                      </HeaderTitleUnderlineWrapper>
                      <Styled.SubsectionDescription isAnon={isAnonProfile}>
                        Personal opinions only which do not reflect the investments or <br /> views of the member’s employer(s) and is not
                        investment advice
                      </Styled.SubsectionDescription>
                    </Styled.SubsectionHeaderLeft>
                  </Styled.SubsectionHeader>
                  {isCurrent && (
                    <Styled.AddButton isAnon={isAnonProfile} onClick={ideasPlusClick}>
                      <Styled.AddButtonIcon src={isAnonProfile ? AddButtonIconAnon : AddButtonIcon} alt="add" /> add coverage
                    </Styled.AddButton>
                  )}
                  <Styled.ListsWrapper isHidden={isIdeasHidden}>
                    {isIdeasHidden ? (
                      <HidenSectionComponent text={ideasOutputTextForHidden ?? 'Visible to connections only'} />
                    ) : (
                      <>
                        {!!bearIdeas.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>bearish</Styled.ListCaptionDark> conclusions
                            </Styled.ListCaption>
                            {getIdeasList(bearIdeas, BEAR_ANSWER)}
                            {(!!neutralIdeas.length || !!bullIdeas.length) && <Styled.ListDivider />}
                          </>
                        )}

                        {!!neutralIdeas.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>neutral / undisclosed</Styled.ListCaptionDark> conclusions
                            </Styled.ListCaption>
                            {getIdeasList(neutralIdeas, NEUTRAL_ANSWER)}
                            {!!bullIdeas.length && <Styled.ListDivider />}
                          </>
                        )}

                        {!!bullIdeas.length && (
                          <>
                            <Styled.ListCaption isAnon={isAnonProfile}>
                              <Styled.ListCaptionDark isAnon={isAnonProfile}>bullish</Styled.ListCaptionDark> conclusions
                            </Styled.ListCaption>
                            {getIdeasList(bullIdeas, BULL_ANSWER)}
                          </>
                        )}
                      </>
                    )}
                  </Styled.ListsWrapper>
                </Styled.SecurityCoverageWrapper>
              </Styled.SectionWrapper>
            </motion.div>
          )
        ) : (
          <Styled.SectionWrapper isAnon={isAnonProfile}>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </Styled.SectionWrapper>
        )}
      </AnimatePresence>
    </>
  );
};
