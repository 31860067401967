import Image from 'next/image';
import styled from 'styled-components';

export const PostPostBtnContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PostFormConfirmBtn = styled.button<{
  buttonsHeight?: number;
  mt?: number;
  buttonsBorderRadius?: number;
  maxPostButtonWidth?: number;
  isActive: boolean;
  minW?: number;
}>`
  display: flex;
  width: ${({ maxPostButtonWidth }) => (maxPostButtonWidth ? `${maxPostButtonWidth}px` : '505px')};
  height: 40px;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: ${({ buttonsBorderRadius }) => (buttonsBorderRadius ? `${buttonsBorderRadius}px` : '15px')};
  background: ${({ isActive }) => (isActive ? '#00a880;' : 'rgba(0, 168, 128, 0.20);')};
  color: #fff;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  border: none;

  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};

  ${({ minW }) => minW && `min-width: ${minW}px`};
  ${({ buttonsHeight }) => buttonsHeight && `height: ${buttonsHeight}px`};
`;
export const PostPressEnter = styled.div`
  padding: 3px 0;
  color: #fff;
  font-family: 'Roboto Flex';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 2px;
  line-height: 14px;
  cursor: pointer;
`;

export const ArrowIcon = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled(Image)`
  width: 12px;
  height: 12px;
  margin: 3px;
`;
