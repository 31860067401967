import React, { useMemo } from 'react';
import ShowMoreIcon from '@styles/icons/show-more-icon.webp';
import ShowMoreIconAnon from '@styles/icons/show-more-icon-anon.webp';
import Image from 'next/image';
import * as Styled from './jump-to.styled';
import { JumpTo, SectionStatusType } from '@/common/types';

interface IProps {
  activeSection: JumpTo;
  isAnon?: boolean;
  jumpToStatus: SectionStatusType;
  scrollToSection: (sectionId: JumpTo) => void;
}

export const JumpToComponent: React.FC<IProps> = ({ activeSection, isAnon, jumpToStatus, scrollToSection }) => {
  const displayedSections = useMemo(
    () => Object.values(JumpTo).filter((it) => !jumpToStatus[it as JumpTo]?.isLoading && !jumpToStatus[it as JumpTo]?.isEmpty),
    [jumpToStatus]
  );

  const onClickByJumpTo = (sectionName: JumpTo) => {
    scrollToSection(sectionName);
  };

  return (
    <Styled.JumpTyWrapper isAnon={isAnon}>
      <Image src={isAnon ? ShowMoreIconAnon : ShowMoreIcon} height={11} width={11} alt="Show More Icon" />
      {displayedSections.map((sectionName) => (
        <Styled.JumpToButton
          isActive={activeSection === sectionName}
          isAnon={isAnon}
          key={`jump-to-section-button${sectionName}`}
          onClick={() => onClickByJumpTo(sectionName)}
        >
          {sectionName}
        </Styled.JumpToButton>
      ))}
    </Styled.JumpTyWrapper>
  );
};
