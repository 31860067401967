import Image from 'next/image';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { COLORS, FONTS } from '@/theme';

export const PostStatsWrapper = styled.div<{ isMemoPost?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: ${({ isMemoPost }) => (isMemoPost ? '650px' : '99.8%')};
  height: 12px;
`;

export const ButtonsContainerWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const LabelItemOnHover = styled.div<{ isAnon: boolean }>`
  position: absolute;
  top: 25px;
  border-radius: 5px;
  background: rgba(249, 249, 249, 0.8);
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;
  visibility: hidden;

  backdrop-filter: blur(10px);
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 7px 10px;
  color: #000;
  align-items: center;

  p {
    margin-left: 5px;
    font-family: ${FONTS.FAMILIES.robotoFlex};
    font-size: ${FONTS.SIZES.m};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.medium};
    line-height: ${FONTS.SIZES.xxl};
  }

  ${({ isAnon }) =>
    isAnon &&
    css`
      background: rgba(49, 49, 49, 0.8);
      color: #e8e8e8;
    `}
`;

export const StatContainer = styled.div<{ bookmark?: boolean; w?: number }>`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  gap: 5px;
  cursor: pointer;
  //min-width: 40px;
  min-width: ${({ bookmark, w }) => (bookmark ? '36px' : w ? `${w}px` : '40.5px')};
  max-width: ${({ bookmark, w }) => (bookmark ? '36px' : w ? `${w}px` : '40.5px')};
  //max-width: 40px;
  min-height: 13px;
  max-height: 13px;
  position: relative;
  ${LabelItemOnHover} {
    left: -10px;
  }
  &:hover {
    ${LabelItemOnHover} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;

export const InvisybleContainer = styled.div`
  position: absolute;
  z-index: -10;
`;

export const HiddenImage = styled(Image)`
  height: 16px;
  width: 18px;
  visibility: hidden;
`;

export const StatImage = styled(Image)<{ bookmark?: boolean; quote?: boolean; repost?: boolean }>`
  width: ${({ quote, repost, bookmark }) => (quote || repost ? 12 : bookmark ? 10 : 13)}px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StatCount = styled.div<{ isActive?: boolean; isReposLikeOrClap?: boolean; isAnon: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: ${FONTS.SIZES.s};
  color: ${({ isAnon, isActive, isReposLikeOrClap }) => {
    if (isAnon && isActive && isReposLikeOrClap) return '#FF4646';
    if (!isAnon && isActive && isReposLikeOrClap) return COLORS.newActiveColorForEngagement;
    if (isAnon && isActive) return '#808CFF';
    if (!isAnon && isActive) return COLORS.postStatCountActiveNew;
    if (isAnon) return '#8C8C8C';
    return '#BCBCBC';
  }};
  font-weight: ${FONTS.WEIGHTS.light};
  line-height: 9px;
  width: 22px;
  max-width: 30px;
`;

export const ZeroCountPlaceholder = styled.div`
  width: 22px;
`;

export const PostOptionIconWrapper = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 2px;
  position: relative;

  ${LabelItemOnHover} {
    right: -10px;
    & > p {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  &:hover {
    ${LabelItemOnHover} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;

export const PostOptionIcon = styled(Image)`
  width: 16px;
  height: 4px;
`;

export const PostOptionWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: flex-end;

  div > img {
    display: flex;

    @-moz-document url-prefix() {
      margin-top: 0.75px;
    }
  }
`;

export const DateOutputWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

export const DateOutputWrapperWithPadding = styled.div`
  display: flex;
  gap: 2px;
`;

export const MemoDateIcon = styled(Image as AnyStyledComponent)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const MemoPopover = styled.div`
  position: absolute;
  z-index: 1001;
  top: 25px;
  border-radius: 5px;
  background: rgba(249, 249, 249, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;
  visibility: hidden;

  backdrop-filter: blur(10px);
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 7px 10px;
  color: #000;
`;

export const DateTimeWrapperWithIcon = styled.div<{ isBackDate?: boolean; isInModal?: boolean; summaryTooltipPosition?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 2px;
  position: relative;

  ${MemoPopover} {
    left: ${({ isBackDate, isInModal, summaryTooltipPosition }) =>
      isInModal ? '-145px' : isBackDate || summaryTooltipPosition ? '-140px' : '-85px'};
  }

  &:hover {
    ${MemoPopover} {
      visibility: visible;
      opacity: 1;
      transition-delay: 500ms;
    }
  }
`;

export const MemoPopoverText = styled.p`
  display: flex;
  flex-direction: row;
  color: #000;
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const DateOutput = styled.div<{ isQuote?: boolean; isRepost?: boolean; fontSize: string }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.light};
  font-size: ${({ fontSize }) => fontSize};
  line-height: 7px;
  height: 7px;
  color: ${COLORS.lastDividerColorInVisibilitySettings};
  ${({ isRepost, isQuote }) => (isRepost || isQuote) && 'line-height: 10px; height: 10px;'}
`;

export const DotOutput = styled.div<{ isQuote?: boolean; isRepost?: boolean }>`
  font-family: ${FONTS.FAMILIES.robotoFlex};
  font-weight: ${FONTS.WEIGHTS.extra_light};
  font-size: 10px;
  line-height: 7px;
  height: 7px;
  color: ${COLORS.addAnotherShadowColor};
  ${({ isRepost, isQuote }) => (isRepost || isQuote) && 'line-height: 10px; height: 10px;'}
`;
