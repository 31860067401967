import React from 'react';
import unreadActive from '@styles/icons/unread-newsfeed-active-home.webp';
import unreadNotActive from '@styles/icons/unread-newsfeed-home.webp';
import savedActive from '@styles/icons/saved-neasfeed-active-home.webp';
import savedNotActive from '@styles/icons/saved-neasfeed-home.webp';
import skippedActive from '@styles/icons/skiped-newsfeed-active-home.webp';
import skippedNotActive from '@styles/icons/skiped-newsfeed-home.webp';
import { useAuthProfile } from '@/common/hooks';
import { IFilters, NewsfeedNavigation } from '@/common/types/newsfeed.type';
import * as Styled from './newsfeed-posts.styled';
import { quotePostModalWrapper } from '@/common/components/profile/components/new-quote-modal/new-quote-modal-wrapper.component';
import { NewsfeedList } from '@/common/components/newsfeed/components';
import { Loader } from '@/common/components/loader/loader.styled';

interface IProps {
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  currentFilter: IFilters;
  activeTab: NewsfeedNavigation;
  setActiveTab: React.Dispatch<React.SetStateAction<NewsfeedNavigation>>;
}

export const NewsfeedPostsComponent = ({ scrollRef, currentFilter, activeTab, setActiveTab }: IProps) => {
  const { data: userProfileId } = useAuthProfile();
  const { quotePostHandler, componentToView } = quotePostModalWrapper();

  const scrollAfterSkip = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0 });
    }
  };

  return (
    <Styled.LeftContainer>
      {componentToView}
      <Styled.LeftHeaderContainer>
        <Styled.NavigationContainer style={{ visibility: 'hidden' }}>
          <Styled.NavigationItem active={activeTab === NewsfeedNavigation.UNREAD} onClick={() => setActiveTab(NewsfeedNavigation.UNREAD)}>
            <Styled.EmptyDiv />
            <Styled.NavAbsoluteIcon width={13} isShow={activeTab === NewsfeedNavigation.UNREAD} src={unreadActive} alt="unread icon" />
            <Styled.NavAbsoluteIcon width={13} isShow={activeTab !== NewsfeedNavigation.UNREAD} src={unreadNotActive} alt="unread icon" />
            <p>Unread articles</p>
          </Styled.NavigationItem>
          <Styled.NavigationItem active={activeTab === NewsfeedNavigation.SAVED} onClick={() => setActiveTab(NewsfeedNavigation.SAVED)}>
            <Styled.EmptyDiv />
            <Styled.NavAbsoluteIcon width={10} isShow={activeTab === NewsfeedNavigation.SAVED} src={savedActive} alt="saved icon" />
            <Styled.NavAbsoluteIcon width={10} isShow={activeTab !== NewsfeedNavigation.SAVED} src={savedNotActive} alt="saved icon" />
            <p>Saved articles</p>
          </Styled.NavigationItem>
          <Styled.NavigationItem active={activeTab === NewsfeedNavigation.SKIPPED} onClick={() => setActiveTab(NewsfeedNavigation.SKIPPED)}>
            <Styled.EmptyDiv />
            <Styled.NavAbsoluteIcon width={13} isShow={activeTab === NewsfeedNavigation.SKIPPED} src={skippedActive} alt="skipped icon" />
            <Styled.NavAbsoluteIcon
              width={13}
              isShow={activeTab !== NewsfeedNavigation.SKIPPED}
              src={skippedNotActive}
              alt="skipped icon"
            />
            <p>Skipped articles</p>
          </Styled.NavigationItem>
        </Styled.NavigationContainer>
      </Styled.LeftHeaderContainer>
      {userProfileId ? (
        <NewsfeedList
          quotePostHandler={quotePostHandler}
          userProfileId={userProfileId}
          activeTab={activeTab}
          currentFilter={currentFilter}
          scrollAfterSkip={scrollAfterSkip}
        />
      ) : (
        <Styled.LoaderWrapper heightForMinus={238}>
          <Loader />
        </Styled.LoaderWrapper>
      )}
    </Styled.LeftContainer>
  );
};
