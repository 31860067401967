export const getLocationName = (name: string) => {
  switch (name) {
    case 'United States':
      return 'USA';
    case 'United Kingdom':
      return 'UK';
    case 'United Arab Emirates':
      return 'UAE';
    default:
      return name;
  }
};
