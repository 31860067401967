import React, { ReactElement } from 'react';
import { FlexComponent } from '@/common/components/flex';
import * as Styled from './poll-oracles.styled';
import { IOracle } from '@/common/types/poll.types';

interface IProps {
  items?: IOracle[];
  othersValue?: number;
}

const MAX_ORACLES_VIEW = 12;

export const PollOraclesComponent = ({ items, othersValue }: IProps): ReactElement | null => {
  if (!items?.length) return null;
  const isMoreThenMaxOracles = items && items?.length > MAX_ORACLES_VIEW;
  const countOthers = othersValue && othersValue - MAX_ORACLES_VIEW;

  return (
    <FlexComponent alignItems="center" mt="6px">
      <Styled.OraclesTitle>Oracles •</Styled.OraclesTitle>
      <Styled.UserImageComponentList>
        {items &&
          items
            .slice(0, MAX_ORACLES_VIEW)
            .map((it, index) => (
              <Styled.UserImageComponentWrapper
                isAlone={items.length === 1}
                index={items.length - index}
                key={`${it.id}-list-item`}
                borderRadius="50%"
                border={items.length > 1 ? '2px solid #fff' : undefined}
                imageUrl={it.imageUrl}
                isImageHidden={it.isImageHidden}
                profileId={it.id}
              />
            ))}
      </Styled.UserImageComponentList>
      {!!countOthers && isMoreThenMaxOracles && (
        <Styled.OthersText>
          + {countOthers} {countOthers > 1 ? 'others' : 'other'}
        </Styled.OthersText>
      )}
    </FlexComponent>
  );
};
